import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Badge, Button, CardHeader, Grid, Paper, Typography, Avatar, Container, Stack, Icon, } from "@mui/material";
import { generatePath, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMissionById } from "../../store/missionDetailsSlice";
import PhotoGridView from "app/services/PhotoGridView";
import FuseLoading from "@fuse/core/FuseLoading";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import AttachmentSvs from "app/services/AttachmentSvs";
import SeeMoreCollapsible from './component/SeeMoreCollapsible ';
import JoinButtonSvs from "app/services/JoinButtonSvs";
import SharerSvs from "app/services/SharerSvs";
import FuseScrollbars from "@fuse/core/FuseScrollbars";
import CarouselView from "./component/CarouselView";
import MissionDetailsSkeletonLoader from "./component/MissionDetailsSkeletonLoader";
const styles = {
    gradientBackground: {
        paddingBottom: "70px",
        paddingTop: "30px",
        display: 'flex',
        maxWidth: '639px',
        minHeight: "fit-content",
        flexDirection: "column",
        justifyContent: 'start',
        background: 'white',
        backgroundImage: 'url("assets/images/pi-images/global-map.svg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    imageContainer: {
        '& .image-container': {
            paddingBottom: '42%',
            borderRadius: "unset !important",
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_2_1.image-container': {
            paddingBottom: '84%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_3_1.image-container': {
            paddingBottom: '21%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },
        '& .img.img_4_1.image-container': {
            paddingBottom: '28%',
            '& .rounded-lg': {
                borderRadius: "unset !important",
            }
        },

    },
};

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    // width: 40,
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },
}));

function MissionDetailContent(params) {
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getMissionById({ id: params.mission_id })).then((action) => {
            const data = action.payload;
            if (!data || data.status == "0") {
                navigate(generatePath("/public/home"));
            }
            setData(data)
            console.log('paramgetMissionByIdsssssssssss', data);
            setLoading(false)
        })
    }, [dispatch]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                handleScrollToTop();
            }
        };

        console.log('params', params);

        const handleScrollToTop = () => {
            params.activeKingPin();

        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (loading) {
        return <MissionDetailsSkeletonLoader />;
    }
    class PinnedIcon extends React.Component {
        render() {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.8em', height: '1.8em' }} viewBox="0 0 8.84 12.243">
                    <g id="Group_1452" data-name="Group 1452" transform="translate(-1197.877 -2025.664)">
                        <path id="Subtraction_1" data-name="Subtraction 1" d="M3.744,7.489A3.744,3.744,0,1,1,7.489,3.744,3.749,3.749,0,0,1,3.744,7.489ZM3.693,1.5A2.245,2.245,0,1,0,5.937,3.744,2.248,2.248,0,0,0,3.693,1.5Z" transform="translate(1198.553 2026.501)" fill="#fbb633" />
                        <path id="Path_1201" data-name="Path 1201" d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z" transform="translate(1197.833 2025.664)" fill="#fbb633" />
                    </g>
                </svg>
            )
        }
    }
    return (
        < >
            <div id={data.id} className=" flex-grow basis-xs relative" style={styles.gradientBackground}>
                <Container>
                    <Typography className=" text-24 font-800">{data.title}</Typography>
                    <CardHeader
                        className="px-0 py-8"
                        sx={{
                            "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                            "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                        }}
                        avatar={
                            <>

                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    sx={{
                                        '& .MuiBadge-anchorOriginBottomRightCircular': {
                                            borderRadius: '50%',
                                            backgroundColor: 'white',
                                            padding: '0px',
                                            paddingTop: '3px',
                                            bottom: '10px',
                                            minWidth: '20px',
                                            height: '20px'
                                        }
                                    }}
                                    badgeContent={
                                        <span
                                        >
                                            <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                        </span>
                                    }
                                >
                                    <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${data.agent?.userId}`}>
                                        <AttachmentSvs module={"at-6"} path={data.agent?.profilePicPath} data={{ name: data.agent?.name }} />
                                    </Link>

                                </Badge>
                            </>
                        }
                        title={
                            <Typography className=" font-800 text-16">
                                <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${data.agent?.userId}`}>
                                    {data.agent.flName} <span className=" !font-500 text-16 text-pi-grey">created this mission</span>
                                </Link>
                            </Typography>
                        }
                        subheader={
                            <Typography className=" line-clamp-1 text-16 font-500  text-pi-grey">
                                <DateTimeDisplay dateTime={data.dateCreated} />
                            </Typography>
                        }
                    />
                    <SeeMoreCollapsible text={data.description} maxLength={225} />

                    <JoinButtonSvs agent_id={data.agent.userId} mission_id={params.mission_id} module={"at-3"} />
                </Container>
                <Stack className=" bg-pi-dark-blue bg-opacity-90 absolute bottom-0 w-full px-24 py-6" direction={'row'} justifyContent={"space-between"} alignItems={'center'} sx={{ '& .MuiButton-root': { color: 'white !important' } }}>
                    <Stack direction={'row'} gap={1.5} justifyContent={''} className=" !text-white ">
                        <Typography>{data.views} views</Typography>
                        <Typography>{data.commentsCount} comments</Typography>
                        <Typography>{data.pinned} pinned</Typography>
                    </Stack>
                    <SharerSvs link={`/public/mission-details/${params.mission_id}`} title={data.title} description={data.description} />

                </Stack>
            </div>
            <Container id={data.id} className="flex-grow basis-xs p-0 max-w-full " sx={{ ...styles.imageContainer }} >
                {/* <PhotoGridView fileId={data.filesId} /> */}
                <CarouselView fileId={data.filesId} />
            </Container >
        </>

    )
}

export default MissionDetailContent;