import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
    Avatar,
    Box,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    InputAdornment,
    Badge,
    Menu,
    MenuItem,
    Container,
    CardActions,
} from "@mui/material";
import AttachmentSvs from "app/services/AttachmentSvs";
import PhotoGridView from "app/services/PhotoGridView";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import Stack from "@mui/material/Stack";
import FriendsActivityJoinMissionModal from "../modal/FriendsActivityJoinMissionModal";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import TextLimit from "app/services/TextLimit";
import JoinButtonSvs from "app/services/JoinButtonSvs";
import { PiMapPinLineBold } from "react-icons/pi";
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";
import AgentFollowUnfollow from "app/services/AgentFollowUnfollow";
import SharerSvs from "app/services/SharerSvs";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { LikesIcon, PinIcon, PinnedIcon, SeenIcon } from "app/main/custom-icon/MissionCounterIcons";

const CreateContent = ({ data, isMain = false }) => {
    const token = AgentJwtService.getDecodedAccessToken();

    console.log("data", data)

    return (
        <>
            <CardHeader
                className="p-0"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                    "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                }}
                avatar={
                    <>

                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <span
                                >
                                    <PinnedIcon style={{ fill: '#FBB633' }} size={'medium'} />
                                </span>
                            }
                        >
                            <Link
                                style={{ textDecoration: 'none' }
                                }
                                to={`/agentprofile?agentId=${data.mission.agent.userId}`}
                            >
                                <AttachmentSvs module={"at-6"} path={data.mission?.agent?.profilePicPath} data={{ name: data.mission?.agent.name }} />
                            </Link>

                        </Badge>
                    </>
                }
                title={
                    <div className=" font-extrabold text-16 flex">
                        <Link className="line-clamp-1 "
                            style={{ textDecoration: 'none' }
                            }
                            to={`/agentprofile?agentId=${data.mission.agent.userId}`}
                        >
                            Agent {data.mission?.agent?.flName}
                        </Link>
                        {/* NOTE!! unhide lang to kapag hndi nya follow friend yung creator */}
                        <AgentFollowUnfollow item={{ id: data.mission.agent.id }} module='friends-activity' />
                    </div>
                }
                subheader={
                    <Typography className=" font-500 text-15 text-pi-grey">
                        {(token.id != data.mission.agent.userId) ? 'Created this mission' : 'You created this mission'} { } <DateTimeDisplay dateTime={data.mission?.dateCreated} />
                    </Typography>
                }
                action={
                    // reference for non static
                    // <FriendsActivityJoinMissionModal item={item} module="joinmission" />
                    <Container className="pb-0 pl-0 pr-10 pt-6">
                        {/* <FriendsActivityJoinMissionModal item={1} module="joinmission" /> */}
                        {data.mission.agentId != token.agentId && (
                            <JoinButtonSvs mission_id={data.mission.id} module={"at-2"} return_path={"/public/friends-activity"} />
                        )}
                    </Container>

                }
            />
            <CardContent className="p-0">
                <div className=" mb-3">
                    <Typography
                        component="div"
                        className=" leading-tight font-bold mt-8 text-18  text-pi-black line-clamp-2 mb-0"
                    >
                        <Link
                            style={{ textDecoration: 'none' }
                            }
                            to={`/public/mission-details/${data.mission.id}`}
                        >
                            {data.mission?.title}
                        </Link>
                    </Typography>
                    <div
                        className=" leading-tight text-16  transition duration-300  paragraph contents"
                    >
                        {isMain && (
                            <TextLimit text={data.mission?.description} limit={200} />
                        )}
                    </div>
                </div>
                {isMain && (
                    <PhotoGridView fileId={data.mission?.filesId} />
                )}
            </CardContent>
            <CardActions className="flex-col pt-2 pb-0  px-0 justify-start items-start mt-10">
                <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                    <Typography className=" gap-1 flex items-center  text-pi-grey text-16">
                        <SeenIcon />
                        <span className="mt-1"> {data.mission.views} Seen</span>
                    </Typography>
                    <Typography className=" gap-1 flex data.missions-center text-pi-grey text-16 items-center">
                        <LikesIcon />
                        <span className="mt-1">{data.markersMission.likers ? data.markersMission.likers.split(',').length : '0'} Likes</span>
                    </Typography>
                    <Typography className=" gap-1 flex data.missions-center text-pi-grey text-16 items-center">
                        <PinIcon />
                        <span className="mt-1">{data.mission.pinned} Pinned</span>
                    </Typography>
                    <Box className="flex-1 text-right text-pi-grey text-16">
                        <SharerSvs link={`/public/mission-details/${data.mission.missionId}`} title={data.mission.title} description={data.mission.description} />
                    </Box>
                </Stack>
            </CardActions>

        </>
    );
};

export default CreateContent;