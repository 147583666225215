import FuseSearch from '@fuse/core/FuseSearch';
import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import { memo, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import Typography from '@mui/material/Typography';
import Badge from "@mui/material/Badge";
import { styled, alpha } from "@mui/material/styles";
import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate, generatePath } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import MailIcon from "@mui/icons-material/Mail";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { logoutUser } from 'app/agent/store/agentUserSlice';
import { Button, Hidden, Icon, InputAdornment, TextField, Popover, useMediaQuery, Stack, ListSubheader, ListItemIcon, Divider, Chip } from "@mui/material";
import { FiSearch, FiBell } from "react-icons/fi";
import { MdPowerSettingsNew } from "react-icons/md";
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { Add } from "@mui/icons-material";
import SearchBar from "../../../../app/main/apps/public/search/SearchBar";
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import { useDispatch } from 'react-redux';
import { getNotification, updateNotifications } from 'app/fuse-layouts/shared-components/quickPanel/store/dataSlice';
import AttachmentSvs from 'app/services/AttachmentSvs';
import { LoadingButton } from '@mui/lab';
import ScrollToBottomTrigger from 'app/services/ScrollToBottomTrigger';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import { getPagesList } from '../../../../app/main/apps/pages/store/PageSlice.js';
import { getSettings } from '../../../../app/main/apps/settings/store/settingsSlice';
const BadgeStyled = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-anchorOriginTopRightRectangular': {
        right: '6px', "&::after": {
            color: "#FF4848",
            backgroundColor: "#FF4848",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
        "@keyframes ripple": {
            "0%": {
                transform: "scale(.8)",
                opacity: 1,
            },
            "100%": {
                transform: "scale(2.4)",
                opacity: 0,
            },
        },
    }
}));
const StyledBadgeBell = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        top: "11px",
        right: "16px",
        backgroundColor: '#FF4848',
        color: '#FF4848',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
const StyledBadgeFriends = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        top: "11px",
        right: "9px",
        backgroundColor: '#FF4848',
        color: '#FF4848',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

function PublicToolbarLayout1(props) {
    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [hasLoadMore, setHasLoadMore] = useState(true);
    const [loading, setLoading] = useState(true);
    const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
    const navbar = useSelector(({ fuse }) => fuse.navbar);
    const toolbarTheme = useSelector(selectToolbarTheme);
    const token = AgentJwtService.getDecodedAccessToken();
    const [time, setTime] = useState(new Date());
    const intervalRef = useRef();
    const isMobileSize = useMediaQuery("(max-width:420px)");
    const [status, setStatus] = useState(true);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [copyright, setCopyright] = useState('');

    const handleReachBottom = () => {
        if (hasLoadMore && !loading) {
            const start = items.length;
            const end = start + 15;
            loadItems(start, end);
        }
    };

    useEffect(() => {
        dispatch(getPagesList()).then((action) => {
            setData(action.payload);
        })
        dispatch(getSettings()).then((action) => {
            setCopyright(action.payload && action.payload.copyright ? action.payload.copyright : 'Copyright © 2023 Planet Impossible.');
        })
    }, [dispatch])

    useEffect(() => {
        const countUnread = items.filter((item) => item.status === "Unread");
        setStatus(countUnread?.length > 0);
    }, [items])

    function updateNotification(id, link, itemData) {

        const newData = [...items];

        const index = newData.findIndex(item => item.id === itemData.id);

        if (index !== -1) {

            const updatedData = { ...newData[index], status: 'Read' }
            newData[index] = updatedData;
            setItems(newData);

            dispatch(updateNotifications({ id: id, status: 'Read' })).then((action) => {
                if (action.payload.status == "success") {
                    navigate(link);
                }
            })
        }

    }

    function loadItems(start, end) {
        if (token.id) {
            dispatch(getNotification({ uid: token.id, start: start, end: end })).then((action) => {
                console.log(action, 'AIJSDN AISJDNAISJn     ASIDJNASIDnj     AIJSDNASIDJn  AIsidansd')
                if (action.payload.length > 0) {
                    setItems([...items, ...action.payload]);
                    setHasLoadMore(true);
                } else {
                    setHasLoadMore(false);
                }
            })
                .finally(() => {
                    setLoading(false);
                });
        }

    }

    useEffect(() => {
        setLoading(true);
        loadItems(0, 15);
    }, []);


    const notificationList = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 375, height: '100%' }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List component="nav" aria-label="Navigations Menu" sx={{ height: '100%', '& .Mui-selected': { color: "#FBB633 !important" }, '& .Mui-selected svg': { fill: "#FBB633 !important" }, '& .MuiListItemIcon-root svg': { fill: "#7F7F7F" } }} >
                <Typography className=' text-18 text-pi-default font-bold px-20 pb-16'>Notifications</Typography>
                {items && items.length > 0 ? (
                    items.map((item) => (
                        <>
                            <ListItemButton
                                className={item.status == "Unread" ? ' bg-pi-parallax hover:bg-pi-parallax' : ''}
                                onClick={(e) => { updateNotification(item.id, item.link, item) }}
                            >

                                <ListItemText
                                    primaryTypographyProps={{
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        letterSpacing: 0,
                                    }}
                                    primary={
                                        <Stack>
                                            <Stack direction={'row'} gap={1.5}>
                                                <AttachmentSvs module={"at-1"} path={item.upicPath} data={{ name: item.uname }} />
                                                <Stack className=' h-fit self-center'>
                                                    <Typography className=' text-16 font-bold line-clamp-1'>{item.uname}</Typography>
                                                    <Typography className=' text-14'>{item.message}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    } />


                            </ListItemButton>
                        </>
                    ))) :
                    (
                        <Typography className="text-center text-18 font-boldi h-full" sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            opacity: "80%"
                        }}>
                            No notification
                        </Typography>
                    )
                }
                <ScrollToBottomTrigger onReachBottom={handleReachBottom} />
            </List>
        </Box >
    );
    useEffect(() => {
        intervalRef.current = setInterval(update, 1000);
        return () => {
            clearInterval(intervalRef.current);
        };
    });

    function update() {
        setTime(new Date());
    }
    class ReactComments extends React.Component {
        render() {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                    <g id="Group_1409" data-name="Group 1409" transform="translate(17658 16761)">
                        <g id="Group_1407" data-name="Group 1407">
                            <path id="friend" d="M23.93,68.719a11.32,11.32,0,1,0-12.547,0,18.08,18.08,0,0,0-8.909,7.115A1.509,1.509,0,1,0,5,77.483a15.094,15.094,0,0,1,25.308,0,1.509,1.509,0,1,0,2.528-1.649,18.08,18.08,0,0,0-8.909-7.115ZM9.355,59.3a8.3,8.3,0,1,1,8.3,8.3,8.3,8.3,0,0,1-8.3-8.3ZM49,77.922a1.509,1.509,0,0,1-2.089-.44A15.061,15.061,0,0,0,34.26,70.621a1.509,1.509,0,1,1,0-3.019,8.3,8.3,0,1,0-3.083-16.012,1.509,1.509,0,1,1-1.121-2.8A11.32,11.32,0,0,1,40.533,68.719a18.08,18.08,0,0,1,8.909,7.115A1.509,1.509,0,0,1,49,77.922Z" transform="translate(-17658.729 -16799.477)" fill="#48525c" stroke="#48525c" strokeWidth="0" />
                            <rect id="Rectangle_391" data-name="Rectangle 391" width="50" height="50" transform="translate(-17658 -16761)" fill="none" />
                        </g>
                    </g>
                </svg>

            )
        }
    }
    class ReactNotifications extends React.Component {
        render() {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                    <g id="Group_1408" data-name="Group 1408" transform="translate(17778 16736)">
                        <g id="Group_1406" data-name="Group 1406" transform="translate(-39 25)">
                            <g id="Icon_feather-bell" data-name="Icon feather-bell" transform="translate(-17731.5 -16754)">
                                <path id="Path_529" data-name="Path 529" d="M27,12A9,9,0,0,0,9,12C9,22.5,4.5,25.5,4.5,25.5h27S27,22.5,27,12" fill="none" stroke="#48525c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                <path id="Path_530" data-name="Path 530" d="M20.595,31.5a3,3,0,0,1-5.19,0" fill="none" stroke="#48525c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                            </g>
                            <rect id="Rectangle_392" data-name="Rectangle 392" width="50" height="50" transform="translate(-17739 -16761)" fill="none" />
                        </g>
                    </g>
                </svg>


            )
        }
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [state, setState] = React.useState({
        left: false,
    });
    const sideBarList = (anchor) => (
        <Box
            sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
        </Box>
    );
    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar position="sticky" elevation={2} className=" h-76 bg-white shadow">
                <Toolbar className=" m-auto w-full"
                    sx={(theme) => ({
                        '& .MuiFormControl-root.MuiTextField-root.mb-24': {
                            marginBottom: "unset",
                            flexGrow: .5,
                        },
                        '&.MuiToolbar-root': {
                            gap: "1.5rem",
                            '& .flex': {
                                gap: "1.5rem",
                            },
                            [theme.breakpoints.down("sm")]: {
                                gap: "0rem",
                                '& .flex': {
                                    gap: isMobileSize ? ".4rem" : ".8rem",
                                },
                            },
                        }
                    })}>
                    {config.navbar.display && config.navbar.position === 'left' && (
                        <>
                            <Hidden lgDown>
                                {(config.navbar.style === 'style-3' ||
                                    config.navbar.style === 'style-3-dense') && (
                                        <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                                    )}

                                {config.navbar.style === 'style-1' && !navbar.open && (
                                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                                )}
                            </Hidden>

                            <Hidden lgUp>
                                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                            </Hidden>
                        </>
                    )}
                    <div className=" flex items-center w-full">
                        {config.navbar.display && config.navbar.position === 'left' && (
                            <>
                                {/* <Hidden lgUp>
                                    <Logo />
                                </Hidden> */}
                                {config.navbar.style === 'style-1' && !navbar.open && (
                                    <Hidden lgDown>
                                        <Logo />
                                    </Hidden>
                                )}
                            </>
                        )}

                        <SearchBar id={token.id} />

                        <Hidden mdDown>
                            <Button
                                component={Link}
                                to={token.firstName ? `/public/create-mission` : '/agentprofile/new-user?isNew=true'}
                                className="bg-pi-yellow-green rounded-full text-16 font-bold hover:bg-pi-hover-yellow text-pi-dark-blue-text min-w-fit truncate pr-16 mr-16"
                            >
                                {" "}
                                <Icon className="mr-5">add</Icon> Create A Mission
                            </Button>

                            <Stack direction={'row'} useflexgap="true" gap={2} flexWrap={'wrap'}>

                                {
                                    data && (

                                        data.map((n) => (
                                            (
                                                n.status == 1 && (
                                                    <Typography className="text-pi-dark-blue-text font-medium mt-8">
                                                        <Link to={generatePath("page/:slugs", { slugs: n.slugs })} tabIndex={0} component="button">
                                                            {n.title}
                                                        </Link>
                                                    </Typography>
                                                )
                                            )
                                        )))
                                }

                            </Stack>

                        </Hidden>

                        <Hidden mdUp>
                            <IconButton
                                edge={false}
                                component={Link}
                                to="/public/create-mission"
                                className=" bg-pi-yellow-green text-pi-black hover:bg-pi-yellow-green"
                                sx={{ padding: isMobileSize ? '10px' : "10px" }}
                            >
                                <Add />
                            </IconButton>
                        </Hidden>
                        <Hidden mdDown>
                            <Box sx={{ flexGrow: 3 }} />
                        </Hidden>

                    </div>
                    {Object.keys(token).length > 0 ?
                        <div className=" flex items-center ">
                            {["right"].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <IconButton
                                        className=" h-fit p-6"
                                        size="large"
                                        onClick={toggleDrawer(anchor, true)}
                                        sx={{ display: "flex", fontSize: '3.5rem' }}
                                        aria-label="General Notifications"
                                        color="inherit"
                                    >
                                        <StyledBadgeBell variant="dot" color="error" invisible={!status}>
                                            <ReactNotifications className=" text-pi-dark-blue" />
                                        </StyledBadgeBell>
                                    </IconButton>
                                    <Drawer
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                    >
                                        {notificationList(anchor)}
                                    </Drawer>
                                </React.Fragment>
                            ))}
                            {/* neeed for the future hide lang muna t o confirm */}
                            {/* <IconButton
                                className=" h-fit p-6"
                                size="large"
                                sx={{ display: "flex", fontSize: '3.5rem', padding: '10px' }}
                                aria-label="Friends Notifications"
                                color="inherit"
                            >
                                <StyledBadgeFriends variant="dot" color="error" invisible={true}>
                                    <ReactComments />
                                </StyledBadgeFriends>
                            </IconButton> */}

                        </div>
                        :
                        <Button component={Link} to="/agentlogin" variant="outlined" color="secondary" className=" self-center py-4 px-24 text-pi-blue-1 border-pi-text-grey hover:border-pi-text-grey text-18 rounded-full font-bold"> <Icon className="mr-6 text-pi-blue-1 fill-pi-blue-1">account_circle</Icon> Login</Button>
                    }
                </Toolbar>
            </AppBar >
        </ThemeProvider>
    );
}

export default memo(PublicToolbarLayout1);
