import * as React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider, styled } from '@mui/material/styles';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from "@mui/material/Typography";
import { showMessage } from "app/store/fuse/messageSlice";
import JwtService from "app/services/jwtService";
import FormControl from "@mui/material/FormControl";
import { useFormContext, Controller } from "react-hook-form";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { CSVLink, CSVDownload } from "react-csv";
import format from 'date-fns/format';
import { startOfMonth } from 'date-fns';

import { getAuditLogs } from "app/main/apps/settings/store/systemLogsSlice";

const Root = styled('div')(({ theme }) => ({
    "& label.Mui-focused": {
        display: "none"
    },
    "& legend": {
        display: "none"
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
        borderRadius: "5px",
        top: 0,

    },
    "& .muiltr-3sosnl-MuiInputBase-input-MuiOutlinedInput-input": {
        height: "0px",
        paddingTop: "21px",
        paddingBottom: "20px",
    },
    "& .MuiRadio-root": {
        padding: "0px"
    }
}));


function SystemLogsFilter(props) {
    const methods = useFormContext();
    const dispatch = useDispatch();
    const { control, formState, setValue, watch, getValues, reset } = methods;

    const { data, setData } = props;
    const { page, setPage } = props;
    const { loading, setLoading } = props;
    const [allData, setAllData] = useState([]);

    let mainData = watch();

    function handleChange(event, name) {
        var value = (name == 'keyword' ? event.target.value : event);
        setValue(name, value);
    }

    useEffect(() => {
        setLoading(true)
        dispatch(getAuditLogs(mainData)).then((action) => {
            if (action.payload) {
                var orders = action.payload;
                if (mainData.keyword.length !== 0) {
                    filterKeyword(orders);
                } else {
                    setData(orders);
                }
                setAllData(orders);
                setLoading(false)
            }
        });
    }, [mainData.dateFrom, mainData.dateTo])

    useEffect(() => {
        filterKeyword(allData);
    }, [mainData.keyword])

    function filterKeyword(fetchedData) {
        setData(
            _.filter(fetchedData, (order) =>
                order.module.toLowerCase().includes(mainData.keyword.toLowerCase()) || order.action.toLowerCase().includes(mainData.keyword.toLowerCase()) || order.username.toLowerCase().includes(mainData.keyword.toLowerCase()) || order.details.toLowerCase().includes(mainData.keyword.toLowerCase())
            )
        );
        setPage(0);
    }

    return (
        <div className="w-full overflow-x-auto">
            <div className="flex flex-1 w-full items-center justify-between min-w-max p-20">
                <div className="flex items-center w-fit justify-start ">
                    <TextField
                        className=" min-w-160 w-320"
                        name="keyword"
                        onChange={(ev) => handleChange(ev, 'keyword')}
                        sx={{
                            "& .MuiInputBase-root": {
                                height: 40,
                            },
                            "& .MuiOutlinedInput-root": {
                                px: 1, py: 0
                            }
                        }}
                        placeholder="Search"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon color="action">search</Icon>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <span className="ml-5 mr-5">From</span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={mainData.dateFrom}
                            name="dateFrom"
                            onChange={(ev) => handleChange(ev, 'dateFrom')}
                            maxDate={new Date()} // This sets the maximum date to today
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="dataFrom"
                                    className="m-0 p-0 py-0"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            pr: 2, py: 0
                                        }
                                    }}
                                />
                            )}
                        />

                    </LocalizationProvider>
                    <span className="ml-5 mr-5">To</span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            value={mainData.dateTo}
                            name="dateTo"
                            onChange={(ev) => handleChange(ev, 'dateTo')}
                            maxDate={new Date()} // This sets the maximum date to today
                            minDate={startOfMonth(new Date())} // This disables all dates before the start of the current month
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    id="dateTo"
                                    className="m-0 p-0 py-0"
                                    variant="outlined"
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            height: 40
                                        },
                                        "& .MuiOutlinedInput-root": {
                                            pr: 2, py: 0
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div className="flex flex-1 items-center justify-end px-6">

                </div>
            </div>
        </div>
    );
}

export default SystemLogsFilter;
