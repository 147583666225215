const CONFIG = {
    env: "dev",
    author: "MEDISOURCE",
    API: "https://qaapi.planetimpossible.com",
    WEB: "https://qa.planetimpossible.com",
    JWT: {
        secret: 'pi-api--tym1j05r$r!p#e#4z!x&++w7ag=!5pg1jsrs5u3p)raf(2bo(m--medisource'
    },
    MAPS: "AIzaSyDk53-rcysYR2NvNGBSRiYt4Q-Mf6hTszw"
};

export default CONFIG
