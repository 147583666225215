import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
    Avatar,
    Box,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    InputAdornment,
    Badge,
    Menu,
    MenuItem,
    Container,
} from "@mui/material";
import AttachmentSvs from "app/services/AttachmentSvs";
import PhotoGridView from "app/services/PhotoGridView";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import Stack from "@mui/material/Stack";
import FriendsActivityJoinMissionModal from "../modal/FriendsActivityJoinMissionModal";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import TextLimit from "app/services/TextLimit";
import JoinButtonSvs from "app/services/JoinButtonSvs";
import { PiMapPinLineBold } from "react-icons/pi";
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";
import AgentFollowUnfollow from "app/services/AgentFollowUnfollow";
import SharerSvs from "app/services/SharerSvs";
import AgentJwtService from 'app/services/agent/AgentJwtService';

const CreateContent = ({ data, isMain = false }) => {
    const token = AgentJwtService.getDecodedAccessToken();
    class PinnedIcon extends React.Component {
        render() {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.8em', height: '1.8em' }} viewBox="0 0 8.84 12.243">
                    <g id="Group_1452" data-name="Group 1452" transform="translate(-1197.877 -2025.664)">
                        <path id="Subtraction_1" data-name="Subtraction 1" d="M3.744,7.489A3.744,3.744,0,1,1,7.489,3.744,3.749,3.749,0,0,1,3.744,7.489ZM3.693,1.5A2.245,2.245,0,1,0,5.937,3.744,2.248,2.248,0,0,0,3.693,1.5Z" transform="translate(1198.553 2026.501)" fill="#fbb633" />
                        <path id="Path_1201" data-name="Path 1201" d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z" transform="translate(1197.833 2025.664)" fill="#fbb633" />
                    </g>
                </svg>
            )
        }
    }

    return (
        <>
            <CardHeader
                className="p-0"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                    "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                }}
                avatar={
                    <>

                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            sx={{
                                '& .MuiBadge-anchorOriginBottomRightCircular': {
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    padding: '0px',
                                    paddingTop: '3px',
                                    bottom: '10px',
                                    minWidth: '20px',
                                    height: '20px'
                                }
                            }}
                            badgeContent={
                                <span
                                >
                                    <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                </span>
                            }
                        >
                            <Link
                                style={{ textDecoration: 'none' }
                                }
                                to={`/agentprofile?agentId=${data.mission.agent.userId}`}
                            >
                                <AttachmentSvs module={"at-6"} path={data.mission?.agent?.profilePicPath} data={{ name: data.mission?.agent.name }} />
                            </Link>

                        </Badge>
                    </>
                }
                title={
                    <div className=" font-extrabold text-16 flex">
                        <Link
                            style={{ textDecoration: 'none' }
                            }
                            to={`/agentprofile?agentId=${data.mission.agent.userId}`}
                        >
                            Agent {data.mission?.agent?.flName}
                        </Link>
                        {/* NOTE!! unhide lang to kapag hndi nya follow friend yung creator */}
                        <AgentFollowUnfollow item={{ id: data.mission.agent.id }} module='friends-activity' />
                    </div>
                }
                subheader={
                    <Typography className=" line-clamp-1 font-500 text-15 text-pi-grey">
                        {(token.id != data.mission.agent.userId) ? 'Created this mission' : 'You created this mission'} { } <DateTimeDisplay dateTime={data.mission?.dateCreated} />
                    </Typography>
                }
                action={
                    // reference for non static
                    // <FriendsActivityJoinMissionModal item={item} module="joinmission" />
                    <Container className="pb-0 pl-0 pr-10 pt-6">
                        {/* <FriendsActivityJoinMissionModal item={1} module="joinmission" /> */}
                        {data.mission.agentId != token.agentId && (
                            <JoinButtonSvs mission_id={data.mission.id} module={"at-2"} return_path={"/public/friends-activity"} />
                        )}
                    </Container>

                }
            />
            <CardContent className="p-0">
                <div className=" mb-3">
                    <Typography
                        component="div"
                        className=" leading-tight font-bold mt-8 text-18  text-pi-black line-clamp-2 mb-0"
                    >
                        <Link
                            style={{ textDecoration: 'none' }
                            }
                            to={`/public/mission-details/${data.mission.id}`}
                        >
                            {data.mission?.title}
                        </Link>
                    </Typography>
                    <div
                        className=" leading-tight text-16  transition duration-300  paragraph contents"
                    >
                        {isMain && (
                            <TextLimit text={data.mission?.description} limit={200} />
                        )}
                    </div>
                </div>
                {isMain && (
                    <PhotoGridView fileId={data.mission?.filesId} />
                )}
            </CardContent>
            <Stack
                direction="row"
                className="justify-between w-full items-center"
            >
                <Stack direction="row" gap={3} className="w-full">
                    <div className="w-fit flex items-center gap-3 text-pi-grey-back font-medium">
                        <Icon size="small" className=" text-pi-grey text-19">
                            visibility
                        </Icon>
                        {data.mission.views} <span className="sm:flex hidden">Views</span>
                    </div>

                    <div className="w-fit flex items-center gap-3 text-pi-grey-back font-medium">
                        <Icon size="small" className=" text-pi-grey text-19">
                            mode_comment
                        </Icon>
                        {data.mission.commentsCount} <span className="sm:flex hidden">
                            Comments
                            {/* <FriendsActivityCommentModal item={1} module="comments" /> */}
                            {/* // reference for non static */}
                            {/* <FriendsActivityCommentModal item={item} module="comments" /> */}
                        </span>
                    </div>
                    <Typography className="w-fit flex items-center gap-3 text-pi-grey-back font-medium">
                        <Icon size="small" className="  text-pi-grey text-19">
                            <PiMapPinLineBold />
                        </Icon>
                        {data.mission.pinned} <span className="sm:flex hidden">Pinned</span>
                    </Typography>

                    <Box className="w-fit flex-1 text-right">
                        {/* <Button variant="text" className="text-14 font-bold text-pi-blue-1 self-end"><Icon className="mr-6">share</Icon><span className="sm:flex hidden">share</span></Button> */}
                        <SharerSvs link={`/public/mission-details/${data.mission.id}`} title={data.mission.title} description={data.mission.description} />
                    </Box>
                </Stack>
            </Stack>
        </>
    );
};

export default CreateContent;