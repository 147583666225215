import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { registerActivateAgent } from "app/main/apps/public/register/store/publicRegisterSlice"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import * as React from "react";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Paper, } from "@mui/material";

import { Link } from 'react-router-dom';


function PublicRegisterSuccess(props) {

    // const dispatch = useDispatch();

    // const routeParams = useParams();

    // const [message, setMessage] = useState("Activating...");

    // useEffect(() => {
    //     dispatch(registerActivateAgent(routeParams.id)).then((action) => {
    //         setMessage(action.payload.message);
    //     });
    // }, [dispatch]);

    return (

        <Paper className="flex flex-col flex-auto p-16 sm:p-24 md:flex-row md:p-0 overflow-hidden rounded-none">
            <div className="flex flex-col grow-0 items-center pl-0 md:pl-52 pr-0 md:pr-44 text-center pt-20 md:pt-60 md:items-start md:shrink-0 md:flex-1 md:text-left">
                <motion.div
                    initial={{ opacity: 0, y: 40 }}
                    animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
                >
                    <Typography className="text-32 lg:text-48 xsm:text-18 font-black leading-tight text-pi-primary">
                        Together, Let's Reach Impossible.
                    </Typography>
                </motion.div>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
                    <Typography variant="subtitle1" className="mt-4 text-18 lg:text-24 xsm:text-12 font-regular leading-snug">
                        Join the Planet Impossible Network. <br />
                        Create Your Own Mission. Become Part of Something Big.
                    </Typography>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, scale: 0.6 }}
                    animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
                >
                    <img className=" mt-24 hidden md:block" src="assets/images/pi-login-image.png" alt="Login Image" />
                </motion.div>
            </div>
            <Card
                component={motion.div}
                initial={{ x: 200 }}
                animate={{ x: 0 }}
                transition={{ bounceDamping: 0 }}
                elevation={0}
                className="w-full max-w-screen-mobile lg:max-w-screen-sm mx-auto m-16 md:m-0 rounded-20 md:rounded-none bg-default"
                square
                layout
            >
                <CardContent className="flex flex-col items-center justify-center p-16 sm:p-32 md:p-48 md:pt-88 ">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.6 }}
                        animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
                    >
                        <img className=" w-224 lg:w-360" src="assets/images/loader.gif" alt="logo" />
                    </motion.div>
                    <Typography variant="h6" className="mb-0 -mt-16 lg:-mt-28 font-800 text-18 lg:text-24 xsm:text-16 uppercase">
                        Congratulations!
                    </Typography>
                    <Typography variant="h6" className="mb-8 text-14 lg:text-18 xsm:text-12 font-regular">
                        Welcome to Planet Impossible Network.
                    </Typography>

                    <div className="w-full max-w-pie-382px leading-normal justify-center" style={{ textAlign: 'center' }}>
                        <Typography className="text-16">
                            Successfully Registered {' '}
                            {/* <span className="text-pi-blue-1 font-semibold">Hello</span> */}
                        </Typography>
                    </div>

                    <div className="flex flex-col items-center justify-center pt-32 pb-24">
                        <Button variant="contained" className=" rounded-md m-auto font-bold text-16 text-pi-dark-blue-text uppercase mt-10" component={Link} to="/agentlogin">Login to continue</Button>
                    </div>
                </CardContent>
            </Card>
        </Paper>
    );
}

export default PublicRegisterSuccess;