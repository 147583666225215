import { Icon } from '@mui/material';
import React from 'react';

// CommentIcon Component
export class CommentIcon extends React.Component {
    render() {
        return (
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <defs>
                    <clipPath id="clippath">
                        <rect width="24" height="24" fill="none" />
                    </clipPath>
                </defs>
                <g clipPath="url(#clippath)">
                    <g id="comment-icon">
                        <path id="Icon_awesome-comment" data-name="Icon awesome-comment" d="M11.56,5.5c4.18,0,7.56,2.75,7.56,6.15-.02,1.46-.63,2.85-1.68,3.86,.33,1.05,.88,2.02,1.62,2.83,.07,.07,.08,.17,.04,.26-.04,.09-.12,.14-.22,.14-1.52-.03-2.98-.56-4.15-1.52-1.01,.38-2.09,.57-3.17,.57-4.18,0-7.56-2.75-7.56-6.15s3.39-6.15,7.56-6.15Z" fill="none" stroke="#656565" />
                    </g>
                </g>
            </svg>
        );
    }
}

// PinIcon Component
export class PinIcon extends React.Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <defs>
                    <clipPath id="clippath">
                        <rect width="24" height="24" fill="none" />
                    </clipPath>
                </defs>
                <g clipPath="url(#clippath)">
                    <g id="pin-icon">
                        <g id="Icon_feather-map-pin" data-name="Icon feather-map-pin">
                            <path
                                id="Path_1055"
                                data-name="Path 1055"
                                d="M17.77,10.88c0,4.58-5.88,8.5-5.88,8.5,0,0-5.88-3.92-5.88-8.5,0-3.25,2.63-5.88,5.88-5.88s5.88,2.63,5.88,5.88Z"
                                fill="none"
                                stroke="#656565"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.17"
                            />
                            <path
                                id="Path_1056"
                                data-name="Path 1056"
                                d="M13.85,10.88c0,1.08-.88,1.96-1.96,1.96-1.08,0-1.96-.88-1.96-1.96,0-1.08,.88-1.96,1.96-1.96,1.08,0,1.96,.88,1.96,1.96h0Z"
                                fill="none"
                                stroke="#656565"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.17"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

// SeenIcon Component
export class SeenIcon extends React.Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 24 24"
            >
                <defs>
                    <clipPath id="clippath">
                        <rect width="24" height="24" fill="none" />
                    </clipPath>
                </defs>
                <g clipPath="url(#clippath)">
                    <g id="seen-icon">
                        <g id="Icon_feather-eye" data-name="Icon feather-eye">
                            <path
                                id="Path_1057"
                                data-name="Path 1057"
                                d="M4,12.23s2.73-5.23,7.5-5.23,7.5,5.23,7.5,5.23c0,0-2.73,5.23-7.5,5.23s-7.5-5.23-7.5-5.23Z"
                                fill="none"
                                stroke="#656565"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.17"
                            />
                            <path
                                id="Path_1058"
                                data-name="Path 1058"
                                d="M13.46,12.23c0,1.08-.88,1.96-1.96,1.96s-1.96-.88-1.96-1.96,.88-1.96,1.96-1.96c1.08,0,1.96,.88,1.96,1.96h0Z"
                                fill="#656565"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
export class LikesIcon extends React.Component {
    render() {
        return (
            <Icon className=" text-16 mx-5">favorite_border</Icon>
        );
    }
}


// Profile Pins Icon Blue and Yellow
export class PinnedIcon extends React.Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.933"
                height="17.243"
                viewBox="0 0 13.933 17.243"
                style={{ height: '26px', width: '26px' }}
            >
                <g id="Group_1738" data-name="Group 1738" transform="translate(-3974.453 136.122)">
                    <path
                        id="Union_6"
                        data-name="Union 6"
                        d="M5.14,14.872c-.075-.091-.137-.195-.2-.294L1.372,9.333c-.162-.239-.337-.47-.491-.712A5.576,5.576,0,0,1,.224,7.113a6.217,6.217,0,0,1-.186-.937C.018,6.015,0,5.855,0,5.692v-.38A5.59,5.59,0,0,1,4.179.249,6.233,6.233,0,0,1,5.3.032C5.464.016,5.625.006,5.787,0h.356A6.4,6.4,0,0,1,7.767.252a5.984,5.984,0,0,1,2.251,1.206,5.357,5.357,0,0,1,1.76,2.794,5.05,5.05,0,0,1,.137.83,5.16,5.16,0,0,1,0,.8,6.065,6.065,0,0,1-.754,2.546,5.2,5.2,0,0,1-.324.494c-.158.222-.307.449-.46.675L6.815,14.839a.95.95,0,0,1-.533.359,1.117,1.117,0,0,1-.318.046A1.071,1.071,0,0,1,5.14,14.872Z"
                        transform="translate(3974.453 -136.122)"
                        fill="#fff"
                    />
                    <g id="Group_1736" data-name="Group 1736" transform="translate(3976 -134.622)">
                        <g id="Group_1400" data-name="Group 1400" transform="translate(0)">
                            <path
                                id="Path_935"
                                data-name="Path 935"
                                d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z"
                                transform="translate(-0.044 0)"
                                fill="#fbb633"
                            />
                        </g>
                        <g
                            id="Ellipse_138"
                            data-name="Ellipse 138"
                            transform="translate(0.696 0.683)"
                            fill="none"
                            stroke="#fbb633"
                            strokeWidth="1.5"
                        >
                            <circle cx="3.744" cy="3.744" r="3.744" stroke="none" />
                            <circle cx="3.744" cy="3.744" r="2.994" fill="none" />
                        </g>
                    </g>
                </g>
            </svg>

        )
    }
}
export class PinnedIconBlue extends React.Component {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.933"
                height="17.243"
                viewBox="0 0 13.933 17.243"
                style={{ height: '26px', width: '26px' }}
            >
                <g id="Group_1738" data-name="Group 1738" transform="translate(-3974.453 136.122)">
                    <path
                        id="Union_6"
                        data-name="Union 6"
                        d="M5.14,14.872c-.075-.091-.137-.195-.2-.294L1.372,9.333c-.162-.239-.337-.47-.491-.712A5.576,5.576,0,0,1,.224,7.113a6.217,6.217,0,0,1-.186-.937C.018,6.015,0,5.855,0,5.692v-.38A5.59,5.59,0,0,1,4.179.249,6.233,6.233,0,0,1,5.3.032C5.464.016,5.625.006,5.787,0h.356A6.4,6.4,0,0,1,7.767.252a5.984,5.984,0,0,1,2.251,1.206,5.357,5.357,0,0,1,1.76,2.794,5.05,5.05,0,0,1,.137.83,5.16,5.16,0,0,1,0,.8,6.065,6.065,0,0,1-.754,2.546,5.2,5.2,0,0,1-.324.494c-.158.222-.307.449-.46.675L6.815,14.839a.95.95,0,0,1-.533.359,1.117,1.117,0,0,1-.318.046A1.071,1.071,0,0,1,5.14,14.872Z"
                        transform="translate(3974.453 -136.122)"
                        fill="#fff"
                    />
                    <g id="Group_1736" data-name="Group 1736" transform="translate(3976 -134.622)">
                        <g id="Group_1400" data-name="Group 1400" transform="translate(0)">
                            <path
                                id="Path_935"
                                data-name="Path 935"
                                d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z"
                                transform="translate(-0.044 0)"
                                fill="#2EB7D7"
                            />
                        </g>
                        <g
                            id="Ellipse_138"
                            data-name="Ellipse 138"
                            transform="translate(0.696 0.683)"
                            fill="none"
                            stroke="#2EB7D7"
                            strokeWidth="1.5"
                        >
                            <circle cx="3.744" cy="3.744" r="3.744" stroke="none" />
                            <circle cx="3.744" cy="3.744" r="2.994" fill="none" />
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}
export class CommentIconBadge extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style={{ height: '20px', width: '20px' }}>
                <g id="Group_1733" data-name="Group 1733" transform="translate(-92 -674)">
                    <circle id="Ellipse_145" data-name="Ellipse 145" cx="8" cy="8" r="8" transform="translate(92 674)" fill="#314159" />
                    <g id="MDI_comment" data-name="MDI / comment" transform="translate(94.802 677.127)">
                        <g id="Boundary" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0">
                            <rect width="10.683" height="10.683" stroke="none" />
                            <rect x="0.5" y="0.5" width="9.683" height="9.683" fill="none" />
                        </g>
                        <path id="Path_comment" data-name="Path / comment" d="M5.116,10.9a.445.445,0,0,1-.445-.445V9.122H2.89A.89.89,0,0,1,2,8.232V2.89A.89.89,0,0,1,2.89,2h7.122a.89.89,0,0,1,.89.89V8.232a.89.89,0,0,1-.89.89H7.3L5.65,10.773a.454.454,0,0,1-.312.129H5.116Z" transform="translate(-1.11 -1.11)" fill="#fff" />
                    </g>
                </g>
            </svg>
        )
    }
}
export class CommunityIconBadge extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" style={{ height: '18px', width: '18px' }}>
                <g id="Group_1734" data-name="Group 1734" transform="translate(-25.717 -23.717)">
                    <rect id="Rectangle_432" data-name="Rectangle 432" width="13" height="13" rx="3" transform="translate(25.717 23.717)" fill="#e80e64" />
                    <g id="MDI_signal-variant" data-name="MDI / signal-variant" transform="translate(28.38 25.997)">
                        <g id="Boundary" fill="#fff" stroke="rgba(0,0,0,0)" stroke-width="1" opacity="0">
                            <rect width="8.44" height="8.44" stroke="none" />
                            <rect x="0.5" y="0.5" width="7.44" height="7.44" fill="none" />
                        </g>
                        <path id="Path_signal-variant" data-name="Path / signal-variant" d="M4,4.987V4h.049a7.832,7.832,0,0,1,7.844,7.844v.049h-.987v-.049A6.853,6.853,0,0,0,4,4.987M4,6.96V5.973a5.92,5.92,0,0,1,5.92,5.92H8.933A4.933,4.933,0,0,0,4,6.96M4,8.933V7.947a3.947,3.947,0,0,1,3.947,3.947H6.96A2.96,2.96,0,0,0,4,8.933M4,9.92a1.973,1.973,0,0,1,1.973,1.973H4Z" transform="translate(-4 -4)" fill="#fff" />
                    </g>
                </g>
            </svg>
        )
    }
}