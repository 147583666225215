import React, { useState } from 'react';
import { Button, Icon, Menu, MenuItem } from '@mui/material';

function FilterButtonTabTwo({ onFilterChange }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option) => {
        onFilterChange(option);
        handleClose();
    };


    return (
        <div>
            <Button
                variant="contained"
                size="small"
                className="bg-pi-light-grey hover:bg-grey-400 rounded-md w-fit mt-14 absolute right-0"
                startIcon={<Icon>filter_list</Icon>}
                onClick={handleClick}
            >
                Filter
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleMenuItemClick('All')}>All</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Created Mission')}>Created Mission</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Join Mission')}>Join Mission</MenuItem>
            </Menu>
        </div>
    );
}

export default FilterButtonTabTwo;