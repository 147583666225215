// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
Basic Table Styles
 */
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
    width: 100%;
    border: none;
    border-spacing: 0;
    text-align: left;
}

table.simple thead tr th {
    padding: 16px 8px;
    font-weight: 500;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    white-space: nowrap;
}

table.simple thead tr th:first-child {
    padding-left: 24px;
}

table.simple thead tr th:last-child {
    padding-right: 24px;
}

table.simple tbody tr td {
    padding: 12px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.simple tbody tr td:first-child {
    padding-left: 24px;
}

table.simple tbody tr td:last-child {
    padding-right: 24px;
}

table.simple tbody tr:last-child td {
    border-bottom: none;
}

table.simple.clickable tbody tr {
    cursor: pointer;
}

table.simple.clickable tbody tr:hover {
    background: rgba(0, 0, 0, 0.03);
}

table.simple.borderless {
    border: none;
}

table.simple.borderless tbody tr td{
    border: none;
}

table.simple.borderless thead tr th{
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/tables.css"],"names":[],"mappings":"AAAA;;EAEE;AACF;IACI,cAAc;IACd,WAAW;IACX,gBAAgB;IAChB,iCAAiC;IACjC,4CAA4C;AAChD;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,4CAA4C;IAC5C,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,4CAA4C;AAChD;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":["/**\nBasic Table Styles\n */\n.table-responsive {\n    display: block;\n    width: 100%;\n    overflow-x: auto;\n    -webkit-overflow-scrolling: touch;\n    -ms-overflow-style: -ms-autohiding-scrollbar;\n}\n\ntable.simple {\n    width: 100%;\n    border: none;\n    border-spacing: 0;\n    text-align: left;\n}\n\ntable.simple thead tr th {\n    padding: 16px 8px;\n    font-weight: 500;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n    white-space: nowrap;\n}\n\ntable.simple thead tr th:first-child {\n    padding-left: 24px;\n}\n\ntable.simple thead tr th:last-child {\n    padding-right: 24px;\n}\n\ntable.simple tbody tr td {\n    padding: 12px 8px;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n}\n\ntable.simple tbody tr td:first-child {\n    padding-left: 24px;\n}\n\ntable.simple tbody tr td:last-child {\n    padding-right: 24px;\n}\n\ntable.simple tbody tr:last-child td {\n    border-bottom: none;\n}\n\ntable.simple.clickable tbody tr {\n    cursor: pointer;\n}\n\ntable.simple.clickable tbody tr:hover {\n    background: rgba(0, 0, 0, 0.03);\n}\n\ntable.simple.borderless {\n    border: none;\n}\n\ntable.simple.borderless tbody tr td{\n    border: none;\n}\n\ntable.simple.borderless thead tr th{\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
