import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
    Avatar,
    Box,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    InputAdornment,
    Badge,
    Menu,
    MenuItem,
    Container,
    TextareaAutosize,
} from "@mui/material";
import AttachmentSvs from "app/services/AttachmentSvs";
import PhotoGridView from "app/services/PhotoGridView";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import Stack from "@mui/material/Stack";
import FriendsActivityJoinMissionModal from "../modal/FriendsActivityJoinMissionModal";
import CreateContent from "./CreatedContent";
import TextLimit from "app/services/TextLimit";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import { useLocation, useNavigate } from "react-router-dom";

import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";
import { LikesIcon, PinIcon, PinnedIcon, PinnedIconBlue, SeenIcon } from "app/main/custom-icon/MissionCounterIcons";
import { FavoriteBorder } from "@mui/icons-material";
import SharerSvs from "app/services/SharerSvs";
import Commentsv2 from "app/services/comments/Commentsv2";
import OtherAgents from "app/main/apps/agent/agentprofile/content/OthersAgents";
import AgentJoiners from "./AgentJoiners";
function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}
const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        fontFamily: "Nunito, sans-serif !important",
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        backgroundColor: "unset",
        border: "1px solid #E9E9E9",
        fontSize: '14px',
        borderRadius: '18px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '14px',
        },
    },
    "& .MuiInputLabel-root": {
        fontSize: '14px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '14px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },

}));
const JoinedContentV2 = ({ data }) => {
    const navigate = useNavigate();
    const [isExpandedrep, setIsExpandedrep] = useState(false);

    const toggleExpand = () => {
        setIsExpandedrep(!isExpandedrep);
    };

    console.log("JoinedContentV2", data)

    return (
        <>
            <CardHeader
                className="p-0"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                    "& .MuiAvatar-root": { border: '1px solid #2EB7D7' },
                }}
                avatar={
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                        badgeContent={
                            <span
                            >
                                <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                            </span>
                        }
                    >
                        <Link
                            style={{ textDecoration: 'none' }
                            }
                            to={`/agentprofile?agentId=${data.pinMission?.agent?.userId}`}
                        >
                            <AttachmentSvs module={"at-6"} path={data.pinMission?.agent?.profilePicPath} data={{ name: data.pinMission?.agent?.name }} />
                        </Link>

                    </Badge>
                }
                title={
                    <Typography className=" font-extrabold text-18 flex gap-6">
                        <Link
                            style={{ textDecoration: 'none' }
                            }
                            to={`/agentprofile?agentId=${data.pinMission?.agent?.userId}`}
                        >
                            {data.pinMission?.agent?.flName}
                        </Link>
                        <Typography className=" line-clamp-1 text-16 text-pi-grey mt-2">
                            Joined this mission
                        </Typography>
                    </Typography>
                }
                subheader={
                    <Typography className=" line-clamp-1 text-16 text-pi-black">
                        {<AgentJoiners item={data} />}
                    </Typography>
                }
            // action={
            //     <IconButton><Icon>more_vert</Icon></IconButton>
            // }
            />
            <Card elevation={0} className=" flex flex-row gap-8 bg-pi-primary bg-opacity-10 rounded-none -mx-16 mt-8 p-12">
                <div className=" w-216 h-136 ">
                    <img
                        src={'assets/images/pi-images/no-image.png'}
                        alt={''}
                        loading="lazy"
                        objectFit='cover'
                        className=" h-full"
                    />
                </div>
                <div className="flex-1 pr-12">
                    <CardHeader
                        className="p-0"
                        sx={{
                            "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                            "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                        }}
                        avatar={
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                badgeContent={
                                    <span
                                    >
                                        <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                    </span>
                                }
                            >
                                {/* <Avatar alt={"data?.name"} {...stringAvatar("Adesna, Christian")}
                                    className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} /> */}
                                <Avatar style={{ height: '46px', width: '46px' }}></Avatar>

                            </Badge>
                        }
                        title={
                            <Typography className=" font-extrabold text-18 flex gap-6">
                                {data.mission?.title}
                            </Typography>
                        }
                        subheader={
                            <Typography className=" line-clamp-1 text-16">
                                by {data.mission?.agent?.flName}
                            </Typography>
                        }

                    />
                    <CardContent style={{ cursor: 'pointer' }}
                        // onClick={() => navigate('/public/mission-details/' + item.missionId)}
                        className="p-0 !pb-0">
                        <Typography
                            className="text-16  transition duration-300 line-clamp-2 pt-4 !pb-0"
                        >
                            {data.mission?.description}
                        </Typography>
                        <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full mt-6">
                            <Typography className=" gap-1 flex items-center  text-pi-grey">
                                <div className="-mt-2">
                                    <SeenIcon />
                                </div>
                                <span className="mt-1 text-16">
                                    {data.mission.views ? data.mission.views : '0'}

                                </span>
                            </Typography>
                            <Typography className=" gap-1 flex items-center text-pi-grey">
                                <LikesIcon />
                                <span className="mt-1 text-16">
                                    1
                                </span>
                            </Typography>
                            <Typography className=" gap-1 flex items-center text-pi-grey">
                                <div className="-mt-2"><PinIcon /> </div>
                                <span className="mt-1 text-16">
                                    {data.mission.pinned ? data.mission.pinned : '0'}
                                </span>
                            </Typography>
                            <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2">
                                <SharerSvs link={`/public/mission-details/${data.mission.id}`} title={data.mission.title} description={data.mission.description} />
                            </Button>
                            {/* <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2"><Icon className="mr-6 text-18">share</Icon><span className="sm:flex hidden">share</span></Button> */}

                            <div className=" flex-1 text-right">
                                <Button variant="contained" className=" text-16 bg-pi-blue-1 hover:bg-pi-blue-1 !py-4 !px-16 rounded-4 text-white">Join this mission now</Button>

                            </div>
                        </Stack>
                    </CardContent>
                </div>
            </Card>
            <CardHeader
                className="p-0 mt-8"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                    "& .MuiAvatar-root": { border: '1px solid #2EB7D7' },
                }}
                avatar={
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                        badgeContent={
                            <span
                            >
                                <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                            </span>
                        }
                    >
                        {/* <Avatar alt={"data?.name"} {...stringAvatar("Adesna, Christian")}
                            className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} /> */}
                        <Avatar style={{ height: '46px', width: '46px' }}></Avatar>

                    </Badge>
                }
                title={
                    <Typography
                        component="div"
                        className="font-extrabold text-18 -mb-2 text-pi-black"
                    >
                        {/* {item.title} */}
                        {data.pinMission.title}
                    </Typography>
                }
                subheader={
                    <Typography className=" line-clamp-1 text-16 text-pi-grey">
                        {/* {item.agent.flName} */}
                        {data.pinMission?.agent?.flName} joined  <DateTimeDisplay dateTime={data.pinMission?.dateCreated} />.
                    </Typography>
                }

            />
            <CardContent style={{ cursor: 'pointer' }} className="py-0 px-60 !cursor-default">

                <Box className="flex items-end justify-start mb-0 toggleReadmore relative">
                    <Typography
                        onClick={() => navigate('/public/mission-details/' + data.pinMission.id)}
                        className="text-16  transition duration-300 line-clamp-3 mb-3 paragraph contents cursor-pointer"
                    >
                        {/* {item.description} */}
                        {data.pinMission.description}
                    </Typography>
                </Box>
                <PhotoGridView
                // fileId={item.filesId}
                />
                <Stack className="mt-6 -ml-5" direction={'row'} gap={3} alignItems={'center'}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <IconButton size="small"
                            // onClick={handleClick}
                            className="-mt-2">
                            {/* {isFavorite ? ( 
                                <FavoriteIcon fontSize="inherit" sx={{ color: 'red' }} />
                           ) : ( */}
                            <FavoriteBorder fontSize="inherit" sx={{ color: 'red' }} />
                            {/* )} */}
                        </IconButton>
                        <Typography className="h-fit font-regular text-14 !bg-none hover:!bg-none text-black">
                            {/* {count} */}
                            101
                        </Typography>
                    </Stack>
                    <Button
                        onClick={toggleExpand}
                        size="small" variant="text" className="h-fit font-bold text-16 !bg-none hover:!bg-none text-black !p-o rounded-md">
                        {/* {item.countOnCommentsById + ' ' + 'Replies'} */}
                        <Icon>{isExpandedrep ? 'expand_more' : 'expand_less'}</Icon>
                        {/* <Icon>expand_less</Icon> kapag naghide */}
                    </Button>
                </Stack>
                {isExpandedrep && (
                    <Commentsv2 moduleType="missions" moduleId={data.missionId} />
                )}
                {/* <div className=" border-1 rounded-md border-grey-300 p-16 mt-8 mb-14 text-right">
                    <TextareaAutosize
                        maxRows={3}
                        minRows={1}
                        className="text-16 leading-tight w-full resize-none "
                        placeholder="Please write your thoughts here..."
                    />
                    <Button variant='contained' color="primary" className=" rounded-md">Add Reply</Button>
                </div> */}

            </CardContent>

        </>
    );
};

export default JoinedContentV2;