import { styled } from "@mui/material/styles";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { inputLabelClasses } from "@mui/material/InputLabel";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { FiSearch, FiBell } from "react-icons/fi";
import {
    Avatar,
    Box,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    Link,
    InputAdornment,
} from "@mui/material";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getBlogAuthorList, getBlogCategoryList, getBlogs } from "../store/PublicBlogSlice";
import PublicBlogContentList from "./PublicBlogContentList";
import ScrollToBottomTrigger from "app/services/ScrollToBottomTrigger";
import { useLocation } from "react-router-dom";
import { debounce } from 'lodash';
import SkeletonLoader from "../components/SkeletonLoader";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#FAFAFB",
        border: "1px solid #E9E9E9",
        height: 40,
        width: '100%',
        minWidth: '0px',
        fontSize: '16px'
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiFilledInput-input": {
        paddingTop: "8px"
    },
    "& input#mui-1::placeholder": {
        color: "#8B8B8B",
        opacity: 'unset',
        fontWeight: 'semi-bold',
        fontSize: '16px'


    },
}));

function PublicBlogContent(props) {
    const initial = 10;
    const pageLayout = useRef(null);
    const dispatch = useDispatch();
    const [blogs, setBlogs] = useState([]);
    const [author, setAuthor] = useState('all_author');
    const [category, setCategory] = useState('all_post');
    const [authorList, setAuthorList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [keywordTemp, setKeywordTemp] = useState("");
    const [loading, setLoading] = useState(true);
    const [isLoadingBlogs, setIsLoadingBlogs] = useState(true);
    const [range, setRange] = useState([0, initial]);
    const [hasLoadMore, setHasLoadMore] = useState(true);
    const [reset, setReset] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    useEffect(() => {
        dispatch(getBlogCategoryList()).then((action) => {
            setCategoryList(action.payload);
        })
    }, [dispatch]);

    useEffect(() => {
        dispatch(getBlogAuthorList()).then((action) => {
            setAuthorList(action.payload);
        })
    }, [dispatch]);

    const handleReachBottom = () => {
        if (hasLoadMore && !isLoadingBlogs && !reset && !loading) {
            const start = range[0] + initial;
            const end = range[1] + initial;

            loadItems(start, end);
            setRange([start, end]);
        } else {
            setLoading(false)
            setIsLoadingBlogs(false)
        }
    };

    function loadItems(start, end) {
        setLoading(true)
        setIsLoadingBlogs(true)
        dispatch(getBlogs({ id: id, author: author, category: category, keyword: keyword, start: start, end: end })).then((action) => {
            setBlogs([...blogs, ...action.payload]);
            setLoading(false)
            setIsLoadingBlogs(false)
        });
    }

    useEffect(() => {
        setLoading(true)
        loadItems(range[0], range[1]);
    }, []);

    useEffect(() => {
        if (range[1] > blogs.length) {
            setHasLoadMore(false)
        } else {
            setHasLoadMore(true)
        }
    }, [blogs, range, hasLoadMore]);

    useEffect(() => {
        setHasLoadMore(true)
        setRange([0, initial]);
        setBlogs([])
        setReset(true);
    }, [author, category, keyword]);

    useEffect(() => {
        if (reset && blogs.length == 0 && range[1] == initial && hasLoadMore) {
            setLoading(true)
            loadItems(0, initial);
            setReset(false)
        }
    }, [reset, blogs]);

    const handleKeyUp = (e) => {
        setKeyword(e)
    }

    const debouncedHandleKeyUp = useRef(debounce(handleKeyUp, 500)).current;

    const renderSkeletonLoaders = (count) => {
        const skeletonLoaders = [];
        for (let i = 0; i < count; i++) {
            skeletonLoaders.push(
                <Card key={i} className="p-16 mb-4 rounded-lg shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                    <Stack direction={'column'} gap={{ lg: 3, md: 1.5, sm: 1.5, xs: 1.5 }} className="p-0 w-full">
                        <SkeletonLoader />
                    </Stack>
                </Card>
            );
        }
        return skeletonLoaders;
    };

    return (
        <Stack direction={'column'} gap={{ lg: 4, md: 2, sm: 2, xs: 2 }} className=" px-0 max1200px:py-40 py-20 m-auto max1200px:w-pi-655px-vw sm:w-4/5 w-full">
            {!id && (
                <Card className="p-16 rounded-lg shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                    <Stack
                        direction={"row"}
                        gap={1}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        className="w-full"
                    >
                        <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                            className="w-full"
                        >
                            <Select
                                className=""
                                classes={{ select: 'py-8 ' }}
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                inputProps={{
                                    name: 'Category',
                                }}
                                variant="filled"
                            >
                                <MenuItem value="all_post">
                                    All Post
                                </MenuItem>
                                {categoryList.map((n, key) => {
                                    return (
                                        <MenuItem key={key} value={n.id}>
                                            {n.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <Select
                                className=""
                                classes={{ select: 'py-8 ' }}
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                                inputProps={{
                                    name: 'Author',
                                }}
                                variant="filled"
                            >
                                <MenuItem value="all_author">
                                    All Author
                                </MenuItem>
                                {authorList.map((n, key) => {
                                    return (
                                        <MenuItem key={key} value={n.id}>
                                            {n.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Stack>

                        <Stack direction={"row"} gap={2} className="">
                            <TextFieldStyled
                                placeholder="Search blogs..."
                                value={keywordTemp}
                                onChange={(e) => setKeywordTemp(e.target.value)}
                                onKeyUp={(e) => debouncedHandleKeyUp(e.target.value)}
                                variant="filled"
                                InputLabelProps={{
                                    sx: {
                                        color: "#48525C",
                                        fontSize: { xl: '18px', lg: '16px', md: '14px' },
                                        [`&.${inputLabelClasses.shrink}`]: {
                                            fontSize: '14px',
                                            color: "#141F2C"
                                        }
                                    }
                                }}
                                type="text"

                            />
                            <IconButton className="hidden" edge={false}>
                                <Icon>search</Icon>
                            </IconButton>
                        </Stack>
                    </Stack>
                </Card>
            )}
            {isLoadingBlogs ? (
                renderSkeletonLoaders(3) // Adjust the number of skeleton loaders
            ) : blogs.length === 0 ? (
                <Typography className="text-center" color="textSecondary" variant="h5">
                    No results were found that meet all of your specified search criteria.
                </Typography>
            ) : (
                <>
                    {!isLoadingBlogs && blogs.length > 0 && (
                        <PublicBlogContentList data={blogs} loading={loading} keyword={keyword} id={id} />
                    )}
                </>
            )}



            <ScrollToBottomTrigger onReachBottom={handleReachBottom} />
        </Stack>
    );
}

export default PublicBlogContent;
