import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { GlobalStyles, height, minHeight } from "@mui/system";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon, FormHelperText } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/agent/store';
import { createPage, checkTypeExistence, getPageInfo, updatePage } from '../store/PageSlice';
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { useEffect, useState, useRef, useMemo } from "react";
import { useForm, useFormContext, Controller, FormProvider } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Paper from '@mui/material/Paper';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { showMessage } from "app/store/fuse/messageSlice";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import { useLocation, useNavigate } from 'react-router-dom';
import MyCKEditor from "app/services/CKEditor";
import JoditEditor from 'jodit-react';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const token = AgentJwtService.getDecodedAccessToken();
console.log(token, 'token');

const defaultValues = {
    id: token.id,
    title: "",
    slugs: "",
    // shortDescription: "",
    bodyContent: "",
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
};

const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
}));
const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        // height: 56,
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none"
    },

    "& .MuiInputLabel-root": {
        top: "0px",
    },
}));
const FormControlStyled = styled(FormControl)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff !important",
        border: "1px solid #141F2C",
        borderRadius: "8px"
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
        border: "unset",
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none",
    },

    "& .MuiInputLabel-root": {
        top: "0px",
        fontSize: "15px",
        color: '#48525C'
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: '#48525C'
    },
    "& .MuiSelect-select.MuiSelect-filled": {
        paddingTop: '20px'
    },
    "& .MuiSelect-nativeInput": {
        paddingTop: '3px'
    },
}));

function PageManageCreate({ props, placeholder } ) {

    const editor = useRef(null);
    const [content, setContent] = useState('');

    const config = useMemo(() => ({
        height: 500,
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: placeholder || 'Start typings...'
    }),
        [placeholder]
    );

    // const [content, setContent] = useState('');
    const [touchBody, setTouchBody] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isEdit = params.get('id') ? true : false;
    const schema = yup.object().shape(!isEdit ? {
        title: yup
            .string()
            .required("Title is required")
            .test({
                message: () => 'Title already exist.',
                test: async (title) => {
                    if (!!title) {
                        const res = await checkExistence(title);
                        if (res) {
                            return null;
                        } else {
                            return [];
                        }
                    } else {
                        return [];
                    }
                },
            }),
        slugs: yup
            .string()
            .required("Slugs is required")
            .test({
                message: () => 'Slugs already exist.',
                test: async (slugs) => {
                    if (!!slugs) {
                        const res = await checkExistence(slugs);
                        if (res) {
                            return null;
                        } else {
                            return [];
                        }
                    } else {
                        return [];
                    }
                },
            }),
        // shortDescription: yup.string().required("Short Description is required"),
        bodyContent: yup.string().required("Body Content is required"),
        metaTitle: yup.string().required("Meta Title is required"),
        metaDescription: yup.string().required("Meta Description is required"),
        metaKeyword: yup.string().required("Meta Keyword is required"),
    } : {
        title: yup.string().required("Title is required"),
        slugs: yup.string().required("Slugs is required"),
        // shortDescription: yup.string().required("Short Description is required"),
        metaTitle: yup.string().required("Meta Title is required"),
        metaDescription: yup.string().required("Meta Description is required"),
        metaKeyword: yup.string().required("Meta Keyword is required"),
    });

    useEffect(() => {
        if (params.get('id')) {
            dispatch(getPageInfo({ id: params.get('id') })).then((action) => {
                setValue("id", action.payload.id)
                setValue("title", action.payload.title)
                setValue("slugs", action.payload.slugs)
                // setValue("shortDescription", action.payload.shortDescription)
                setValue("metaTitle", action.payload.metaTitle)
                setValue("metaDescription", action.payload.metaDescription)
                setValue("metaKeyword", action.payload.metaKeyword)
                setContent(action.payload.bodyContent)
            })
        }
    }, [dispatch])

    async function checkExistence(input) {
        return new Promise((resolve, reject) => {
            if (isEdit) {
                resolve(true);
            } else {
                dispatch(checkTypeExistence({ input: input })).then((action) => {
                    if (action.payload.status == 'success') {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            }
        });
    }

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });
    const { control, formState, setValue, watch, trigger, getValues } = methods;
    const { isValid, dirtyFields, errors } = formState;

    async function handlecreatePage() {
        const result = await trigger(["title", "slugs", "metaTitle", "metaDescription", "metaKeyword"]);
        if ((((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length)) || (isEdit && touchBody && content)) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        } else {
            const data = getValues();
            data.bodyContent = content;

            if (data.bodyContent == "") {
                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            } else {
                if (isEdit) {
                    dispatch(updatePage(data)).then((action) => {
                        if (action.payload.status == 'success') {
                            navigate('/apps/pages/manage');
                        }
                    });
                } else {
                    dispatch(createPage(data)).then((action) => {
                        if (action.payload.status == 'success') {
                            navigate('/apps/pages/manage');
                        }
                    });
                }
            }
        }
    }


    function onslugs(_slugs) {
        setValue('title', _slugs, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        });
        if (_slugs != undefined || _slugs != null) {
            const newSlugs = _slugs.replace(/\s+/g, "-").toLowerCase();
            setValue('slugs', newSlugs, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true
            });
        };
    }



    const color = "#2EB7D7";
    return (
        <FormProvider {...methods}>
            <>
                <GlobalStyles
                    styles={(theme) => ({
                        "#fuse-main": {
                            height: "100% !important",
                        },
                        " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                        {
                            background: "white !important",
                        },
                        " .absolute-important":
                        {
                            position: "absolute !important",
                        },
                        " div.FusePageSimple-sidebarHeader.permanent": {
                            minHeight: 0,
                            height: "unset"
                        },
                        "& .FusePageSimple-toolbar": {
                            height: "76px !important",
                        },
                    })}
                />

                <Root

                    content={

                        <FuseScrollbars className="h-full max-h-screen-content-100 overflow-auto overscroll-auto">
                            <Stack direction={'column'} gap={{ lg: 4, md: 2, sm: 2, xs: 2 }} justifyContent={"space-around"}>
                                <Card className="p-24 rounded-lg mb-16 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                    <Grid container spacing={2} className="m-100">
                                        <Grid item xs={12} md={6}>
                                            <Stack gap={1.5}>
                                                <Typography className=" text-18 font-bold mt-16">
                                                    Page Informations
                                                </Typography>
                                                <Controller
                                                    name="title"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <div className="flex">
                                                            <TextFieldStyled
                                                                label={<Typography className="text-15" color="text.primary">Title <span className="text-red-500">*</span></Typography>}
                                                                placeholder="Enter Title"
                                                                variant="filled"
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        color: "#48525C",
                                                                        fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                                            fontSize: '14px',
                                                                            color: !!errors.title ? "red" : "#141F2C"
                                                                        }
                                                                    }
                                                                }}
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);  // This ensures the form state is updated
                                                                    onslugs(e.target.value);  // This calls your custom function
                                                                }}
                                                                error={!!errors.title}
                                                                helperText={errors?.title?.message}
                                                                required
                                                                sx={{
                                                                    "& .MuiInputBase-root": {
                                                                        height: 56
                                                                    }
                                                                }}
                                                                id="title"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                <Controller
                                                    name="slugs"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <div className="flex">
                                                            <TextFieldStyled
                                                                label={<Typography className="text-15" color="text.primary">Slugs <span className="text-red-500">*</span></Typography>}
                                                                placeholder="Enter Slugs"
                                                                variant="filled"
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        color: "#48525C",
                                                                        fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                                            fontSize: '14px',
                                                                            color: !!errors.slugs ? "red" : "#141F2C"
                                                                        }
                                                                    }
                                                                }}
                                                                {...field}
                                                                onChange={(e) => {
                                                                    field.onChange(e);  // This ensures the form state is updated
                                                                    onslugs(e.target.value);  // This calls your custom function
                                                                }}
                                                                className=""
                                                                error={!!errors.slugs}
                                                                required
                                                                helperText={errors?.slugs?.message}
                                                                sx={{
                                                                    "& .MuiInputBase-root": {
                                                                        height: 56
                                                                    }
                                                                }}
                                                                id="slugs"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                {/* <Controller
                                                    name="shortDescription"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <div className="flex">
                                                            <TextFieldStyled
                                                                label={<Typography className="text-15" color="text.primary">Short Description <span className="text-red-500">*</span></Typography>}
                                                                placeholder="Enter Short Description"
                                                                variant="filled"
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        color: "#48525C",
                                                                        fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                                            fontSize: '14px',
                                                                            color: !!errors.shortDescription ? "red" : "#141F2C"
                                                                        }
                                                                    }
                                                                }}
                                                                {...field}
                                                                className=""
                                                                error={!!errors.shortDescription}
                                                                required
                                                                helperText={errors?.shortDescription?.message}
                                                                id="shortDescription"
                                                                sx={{
                                                                    "& .MuiInputBase-root": {
                                                                        height: 56
                                                                    }
                                                                }}
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                                /> */}
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Stack gap={1.5}>

                                                <Typography className=" text-18 font-bold mt-16">
                                                    Meta Properties <span className="text-red-500">*</span>
                                                </Typography>
                                                <Controller
                                                    name="metaTitle"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <div className="flex">
                                                            <TextFieldStyled
                                                                label={<Typography className="text-15" color="text.primary">Meta Title <span className="text-red-500">*</span></Typography>}
                                                                placeholder="Enter Meta Title"
                                                                variant="filled"
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        color: "#48525C",
                                                                        fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                                            fontSize: '14px',
                                                                            color: !!errors.metaTitle ? "red" : "#141F2C"
                                                                        }
                                                                    }
                                                                }}
                                                                {...field}
                                                                error={!!errors.metaTitle}
                                                                required
                                                                helperText={errors?.metaTitle?.message}
                                                                sx={{
                                                                    "& .MuiInputBase-root": {
                                                                        height: 56
                                                                    }
                                                                }}
                                                                id="metaTitle"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                <Controller
                                                    name="metaDescription"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <div className="flex">
                                                            <TextFieldStyled
                                                                label={<Typography className="text-15" color="text.primary">Meta Description <span className="text-red-500">*</span></Typography>}
                                                                placeholder="Enter Meta Description"
                                                                variant="filled"
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        color: "#48525C",
                                                                        fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                                            fontSize: '14px',
                                                                            color: !!errors.metaDescription ? "red" : "#141F2C"
                                                                        }
                                                                    }
                                                                }}
                                                                {...field}
                                                                className=""
                                                                error={!!errors.metaDescription}
                                                                required
                                                                helperText={errors?.metaDescription?.message}
                                                                sx={{
                                                                    "& .MuiInputBase-root": {
                                                                        height: 56
                                                                    }
                                                                }}
                                                                id="metaDescription"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                <Controller
                                                    name="metaKeyword"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <div className="flex">
                                                            <TextFieldStyled
                                                                label={<Typography className="text-15" color="text.primary">Meta Keyword <span className="text-red-500">*</span></Typography>}
                                                                placeholder="Enter Meta Keyword"
                                                                variant="filled"
                                                                InputLabelProps={{
                                                                    sx: {
                                                                        color: "#48525C",
                                                                        fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                                            fontSize: '14px',
                                                                            color: !!errors.metaKeyword ? "red" : "#141F2C"
                                                                        }
                                                                    }
                                                                }}
                                                                {...field}
                                                                className=""
                                                                error={!!errors.metaKeyword}
                                                                required
                                                                helperText={errors?.metaKeyword?.message}
                                                                id="metaKeyword"
                                                                sx={{
                                                                    "& .MuiInputBase-root": {
                                                                        height: 56
                                                                    }
                                                                }}
                                                                fullWidth
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid xs={12} md={12}>
                                            <Stack gap={1.5} className="ml-16">
                                                <Typography className=" text-16 mt-16">
                                                    Body Content <span className="text-red-500">*</span>
                                                </Typography>
                                                <Controller
                                                    className="w-full"
                                                    name="bodyContent"
                                                    control={control}
                                                    onClick={() => setTouchBody(true)}
                                                    render={({ field }) => (
                                                        <div className="w-full">
                                                            <JoditEditor
                                                                ref={editor}
                                                                value={content}
                                                                config={config}
                                                                tabIndex={1} // tabIndex of textarea
                                                                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                                                onChange={newContent => { }}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                <FormHelperText className="mt-16" error={!!errors.bodyContent}>{errors?.bodyContent?.message}</FormHelperText>
                                            </Stack>
                                            <Stack gap={1.5} className="ml-16" alignItems={"center"}>
                                                <Button onClick={handlecreatePage} variant="contained" color="primary"
                                                    className=" mt-16 px-24 py-8 text-16 rounded-full w-1/2 font-bold bg-pi-yellow-green">
                                                    {isEdit ? 'UPDATE' : 'SAVE'}
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Stack>
                        </FuseScrollbars >
                    }
                />
            </>
        </FormProvider >
    );
}

export default withReducer('pageApp', reducer)(PageManageCreate);