import * as React from "react";
import Typography from "@mui/material/Typography";
import { Stack, Avatar, useMediaQuery, Box, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon, } from "@mui/material";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { logoutUser } from 'app/agent/store/agentUserSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agentById } from "./store/AgentInfoSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import { Link } from 'react-router-dom';

// icons
class EditProfileIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(-32 -669)"><g transform="translate(32 669)"><g stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M22,3H2A2.074,2.074,0,0,0,0,5V19a2.074,2.074,0,0,0,2,2H22a2.074,2.074,0,0,0,2-2V5a2.074,2.074,0,0,0-2-2m0,16H2V5H22V19m-8-2V15.75c0-1.66-3.34-2.5-5-2.5s-5,.84-5,2.5V17H14M9,7a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,9,7m5,0V8h6V7H14m0,2v1h6V9H14m0,2v1h4V11H14" /></g></g></svg>
        )
    }
}
class UpdateAccountIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(-281 -450)"><g transform="translate(281 450)" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M10,4a4,4,0,1,0,4,4,4,4,0,0,0-4-4m0,2A2,2,0,1,1,8,8a2,2,0,0,1,2-2m7,6a.212.212,0,0,0-.24.24L16.5,13.5c-.22.18-.54.34-.78.5l-1.28-.5a.409.409,0,0,0-.32.1l-.96,1.76a.2.2,0,0,0,.08.32l1.04.82v1l-1.04.82c-.08.08-.16.24-.08.32l.96,1.76a.409.409,0,0,0,.32.1l1.28-.5c.24.16.56.32.78.5l.26,1.26A.212.212,0,0,0,17,22h2a.255.255,0,0,0,.24-.24l.16-1.26c.32-.18.64-.34.88-.5l1.22.5c.14,0,.3,0,.3-.1l1.04-1.76c.08-.08,0-.24-.08-.32l-1.04-.82v-1l1.04-.82c.08-.08.16-.24.08-.32L21.8,13.6c0-.1-.16-.1-.3-.1l-1.22.5c-.24-.16-.56-.32-.88-.5l-.16-1.26A.255.255,0,0,0,19,12H17m-7,1c-2.67,0-8,1.33-8,4v3h9.67a6.764,6.764,0,0,1-.58-1.9H3.9V17c0-.64,3.13-2.1,6.1-2.1a9.483,9.483,0,0,1,1.3.1,7.465,7.465,0,0,1,.82-1.79A13.512,13.512,0,0,0,10,13m8.04,2.5a1.487,1.487,0,0,1,1.46,1.54,1.468,1.468,0,0,1-1.46,1.46,1.487,1.487,0,0,1-1.54-1.46A1.5,1.5,0,0,1,18.04,15.5Z" transform="translate(280 449)" /></g></svg>
        )
    }
}
class FriendManegerIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M12,5a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,12,5m0,2a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,12,7M5.5,8a2.5,2.5,0,1,0,1.21,4.68,2.614,2.614,0,0,0,.91-.87A5.42,5.42,0,0,1,6.5,8.5V8.22A2.363,2.363,0,0,0,5.5,8m13,0a2.363,2.363,0,0,0-1,.22V8.5a5.42,5.42,0,0,1-1.12,3.31,2.756,2.756,0,0,0,.4.49,2.335,2.335,0,0,0,.51.38,2.448,2.448,0,0,0,2.42,0A2.5,2.5,0,0,0,18.5,8M12,14c-2.34,0-7,1.17-7,3.5V19H19V17.5c0-2.33-4.66-3.5-7-3.5m-7.29.55C2.78,14.78,0,15.76,0,17.5V19H3V17.07a3.168,3.168,0,0,1,1.71-2.52m14.58,0A3.168,3.168,0,0,1,21,17.07V19h3V17.5c0-1.74-2.78-2.72-4.71-2.95M12,16a10.374,10.374,0,0,1,4.23,1H7.77A10.374,10.374,0,0,1,12,16Z" /></svg>
        )
    }
}
class ProDashboardIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M9,17H7V10H9v7m4,0H11V7h2V17m4,0H15V13h2v4m2,2H5V5H19V19.1M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Z" /></svg>
        )
    }
}
class FriendsActivityIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g transform="translate(-121 -198)"><g transform="translate(121 198)" stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M12,1a2.5,2.5,0,0,0-1,4.79V7H7A2,2,0,0,0,5,9v.71a2.5,2.5,0,0,0,0,4.58V15H4a2,2,0,0,0-2,2v1.21a2.5,2.5,0,1,0,2,0V17H8v1.21a2.5,2.5,0,1,0,2,0V17a2,2,0,0,0-2-2H7v-.71A2.5,2.5,0,0,0,7,9.71V9H17v.71a2.5,2.5,0,0,0,0,4.58V15H16a2,2,0,0,0-2,2v1.21a2.5,2.5,0,1,0,2,0V17h4v1.21a2.5,2.5,0,1,0,2,0V17a2,2,0,0,0-2-2H19v-.71a2.5,2.5,0,0,0,0-4.58V9a2,2,0,0,0-2-2H13V5.79A2.5,2.5,0,0,0,12,1m0,1.5a1,1,0,1,1-1,1,1,1,0,0,1,1-1M6,11a1,1,0,1,1-1,1,1,1,0,0,1,1-1m12,0a1,1,0,1,1-1,1,1,1,0,0,1,1-1M3,19.5a1,1,0,1,1-1,1,1,1,0,0,1,1-1m6,0a1,1,0,1,1-1,1,1,1,0,0,1,1-1m6,0a1,1,0,1,1-1,1,1,1,0,0,1,1-1m6,0a1,1,0,1,1-1,1A1,1,0,0,1,21,19.5Z" transform="translate(120.5 197)" /></g></svg>
        )
    }
}
class BlogsIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M19,5V19H5V5H19m2-2H3V21H21V3M17,17H7V16H17v1m0-2H7V14H17v1m0-3H7V7H17Z" /></svg>
        )
    }
}
class HomeIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g stroke="rgba(0,0,0,0)" strokeWidth="1" opacity="0"><rect width="24" height="24" stroke="none" /><rect x="0.5" y="0.5" width="23" height="23" fill="none" /></g><path d="M19,5V7H15V5h4M9,5v6H5V5H9m10,8v6H15V13h4M9,17v2H5V17H9M21,3H13V9h8V3M11,3H3V13h8V3m10,8H13V21h8V11M11,15H3v6h8Z" /></svg>
        )
    }
}

function useScrollTop() {
    const [scrollTop, setScrollTop] = React.useState(0);
    const onScroll = (event) => setScrollTop(event.target.scrollTop);
    return [scrollTop, { onScroll }];
}

function PublicSidebarLeft(props) {
    const dispatch = useDispatch();

    const [scrollTop, scrollProps] = useScrollTop();

    const token = AgentJwtService.getDecodedAccessToken();

    const agent = useSelector(({ agent }) => agent.agentuser);

    const wActiveMenu = window.localStorage.getItem('active_menu');

    const [activeMenu, setActiveMenu] = useState("");

    useEffect(() => {

        setActiveMenu(wActiveMenu);

    }, [wActiveMenu])


    function infoChecker() {
        if (token.id && !token.firstName) {
            return dispatch(
                showMessage({
                    message: "Fill in the profile information first.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }
    }

    console.log("DECODE", token);

    return (
        <>
            <Box className="pl-8 h-full max-h-screen-sidebar-100 overflow-auto overscroll-auto"
                {...scrollProps}
                sx={{
                    boxShadow:
                        scrollTop > 0 ? "inset 0 8px 5px -9px rgb(0 0 0 / 0.4) !important" : "none",
                    transition: "box-shadow 0.8s !important",
                    '&::-webkit-scrollbar': { width: '8px', },
                    '&::-webkit-scrollbar-thumb': { boxShadow: 'inset 0 0 0 20px rgba(0, 0, 0, 0)', },
                    ':hover&::-webkit-scrollbar-thumb': { boxShadow: 'inset 0 0 0 20px rgba(0, 0, 0, 0.24)' },
                }}>

                <Box className="h-full flex flex-col " sx={{ width: '100%', bgcolor: 'transparent' }}>
                    {Object.keys(token).length > 0 ?
                        <Stack direction={'column'} alignItems={'center'} className="mt-10">
                            {token.data.photoURL ? (
                                <Avatar sx={{ width: 86, height: 86 }} className=" border-2 border-pi-yellow-orange" alt="user photo" src={token.data.photoURL} />
                            ) : (
                                <Avatar sx={{ width: 86, height: 86 }} className=" border-2 border-pi-yellow-orange"></Avatar>
                            )}
                            <Typography className=" text-20 font-extrabold mt-8">{token.data.displayName}</Typography>
                            <Typography variant="body1" className=" text-pi-grey -mt-3 text-16">{token.username}</Typography>

                            <Stack className="my-24 px-16 w-full" direction={'row'} useflexgap="true" gridTemplateRows={.5} justifyContent={'space-between'}>
                                <Stack justifyContent={'center'} alignItems={'center'}>
                                    <Typography className=" text-18 font-extrabold">800</Typography>
                                    <ListSubheader className=" px-0 leading-normal text-pi-grey text-14 font-medium">FOLLOWERS</ListSubheader>
                                </Stack>
                                <Stack justifyContent={'center'} alignItems={'center'}>
                                    <Typography className=" text-18 font-extrabold">200</Typography>
                                    <ListSubheader className=" px-0 leading-normal text-pi-grey text-14 font-medium">REACH</ListSubheader>
                                </Stack>
                                <Stack justifyContent={'center'} alignItems={'center'}>
                                    <Typography className=" text-18 font-extrabold">150</Typography>
                                    <ListSubheader className=" px-0 leading-normal text-pi-grey text-14 font-medium">MISSIONS</ListSubheader>
                                </Stack>
                            </Stack>
                        </Stack>
                        :
                        <Box className=" m-auto max-w-max min-w-fit py-16">
                            <Typography className=" text-18 self-start">Together, Let's Reach Impossible.ss</Typography>
                            <Typography className=" text-18 self-start">Join the Planet Impossible Network.</Typography>
                            <Button component={Link} to="/agentlogin" variant="outlined" color="secondary" className=" mt-16 px-24 py-4 text-pi-blue-1 border-pi-text-grey hover:border-pi-text-grey text-18 rounded-full font-bold self-start"> <Icon className="mr-6 text-pi-blue-1 fill-pi-blue-1">account_circle</Icon> Login</Button>
                        </Box>
                    }
                    <Divider className="w-full mt-16" />



                    <List className=" text-pi-grey fill-pi-grey" component="nav" aria-label="Navigations Menu" sx={{ '& .Mui-selected': { color: "#FBB633 !important" }, '& .Mui-selected svg': { fill: "#FBB633 !important" }, '& .MuiListItemIcon-root svg': { fill: "#7F7F7F" } }} >
                        <ListSubheader disableSticky component="div" id="nested-list-subheader">
                            NAVIGATIONS
                        </ListSubheader>
                        <ListItemButton
                            component={Link}
                            to={token.firstName || !token.id ? "/public/home" : "/agentprofile/edit"}
                            onClick={() => { infoChecker() }}
                            className="my-10"
                            selected={activeMenu === 'Home'}
                        >
                            <ListItemIcon> <HomeIcon className={activeMenu === 0 ? "text-pi-grey" : " text-pi-yellow-orange"} /></ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}
                                primary="Home" />
                        </ListItemButton>

                        <ListItemButton
                            component={Link}
                            to={token.firstName || !token.id ? "/public/friends-activity" : "/agentprofile/edit"}
                            onClick={() => { infoChecker() }}
                            className="my-10"
                            selected={activeMenu === "Friends Activity"}
                        >
                            <ListItemIcon> <FriendsActivityIcon /></ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}
                                primary="Friends Activity" />
                        </ListItemButton>

                        {/* <ListItemButton
                            component={Link}
                            to={token.firstName || !token.id ? "/public/blog" : "/agentprofile/edit"}
                            onClick={() => { infoChecker() }}
                            className="my-10"
                            selected={activeMenu === "Blogs"}
                        >
                            <ListItemIcon> <BlogsIcon /></ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}
                                primary="Blogs" />
                        </ListItemButton> */}

                        <Divider className="my-10" />
                        <ListSubheader disableSticky component="div" id="nested-list-subheader">
                            ACCOUNT MENU
                        </ListSubheader>
                        <ListItemButton
                            className="my-10"
                            selected={activeMenu === 'Dashboard'}
                        >
                            <ListItemIcon> <ProDashboardIcon /></ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}
                                primary="Dashboard" />
                        </ListItemButton>
                        <ListItemButton
                            className="my-10"
                            selected={activeMenu === 'Update Account'}
                            component={Link}
                            to={token.firstName || !token.id ? "/agentprofile/account" : "/agentprofile/edit"}
                            onClick={() => { infoChecker() }}
                        >
                            <ListItemIcon> <UpdateAccountIcon /></ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}
                                primary="Update Account" />
                        </ListItemButton>
                        <ListItemButton
                            className="my-10"
                            selected={activeMenu === 'Edit Profile'}
                            component={Link}
                            to="/agentprofile/edit"
                        >
                            <ListItemIcon> <EditProfileIcon /></ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}
                                primary="Edit Profile" />
                        </ListItemButton>
                        <ListItemButton
                            component={Link}
                            to={token.firstName || !token.id ? "/public/friends-manager" : "/agentprofile/edit"}
                            onClick={() => { infoChecker() }}
                            className="my-10"
                            selected={activeMenu === 'Friend Manager'}
                        >
                            <ListItemIcon> <FriendManegerIcon /></ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}
                                primary="Friend Manager" />
                        </ListItemButton>
                        <ListItemButton
                            component={Link}
                            to={token.firstName || !token.id ? "/public/mission-manager" : "/agentprofile/edit"}
                            onClick={() => { infoChecker() }}
                            className="my-10"
                            selected={activeMenu === 'Mission Manager'}
                        >
                            <ListItemIcon> <ProDashboardIcon /></ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                }}
                                primary="Mission Manager" />
                        </ListItemButton>
                        <ListItemButton
                            className="my-10 rounded-md bg-pi-light-grey"
                            onClick={() => dispatch(logoutUser())}
                        >
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    letterSpacing: 0,
                                    textAlign: 'center',
                                    color: "#314159"
                                }}
                                primary="Logout" />
                        </ListItemButton>
                    </List>

                    <div className="h-full flex flex-col justify-end pt-16 px-16">
                        <Stack direction={'row'} useflexgap="true" gap={1} flexWrap={'wrap'}>
                            <Typography className="text-pi-dark-blue-text text-16 font-medium">Contact</Typography>
                            <Typography className="text-pi-dark-blue-text text-16 font-medium">Terms</Typography>
                            <Typography className="text-pi-dark-blue-text text-16 font-medium">Privacy</Typography>
                            <Typography className="text-pi-dark-blue-text text-16 font-medium">About</Typography>
                            <Typography className="text-pi-dark-blue-text text-16 font-medium">Policy & Safety</Typography>

                        </Stack>
                        <Typography className="text-pi-grey text-14 mt-12">Copyright © 2023 Planet Impossible.</Typography>
                    </div>
                </Box>
            </Box >
        </>
    );
}

export default PublicSidebarLeft;
