import * as React from "react";
import Typography from "@mui/material/Typography";
import { Badge, Card, CardHeader, Stack, Box } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import format from "date-fns/format";
import AttachmentSvs from "app/services/AttachmentSvs";
import PhotoGridView from "app/services/PhotoGridView";
import SharerSvs from "app/services/SharerSvs";
import { PinIcon, SeenIcon, PinnedIcon, LikesIcon } from "../../../../custom-icon/MissionCounterIcons"
import SkeletonLoader from "app/main/apps/public/friends-activity/components/SkeletonLoader";
import { useEffect, useState } from "react";

function CreateMissionTabTwo({ missions }) {
    const [missionLoading, setmissionLoading] = useState(true);

    useEffect(() => {
        setmissionLoading(false);
    }, [missions]);

    const renderSkeletonLoaders = (count) => {
        const skeletonLoaders = [];
        for (let i = 0; i < count; i++) {
            skeletonLoaders.push(
                <Stack key={i} direction={'column'} gap={1.5} className=" w-full">
                    <SkeletonLoader></SkeletonLoader>
                </Stack>
            );
        }
        return skeletonLoaders;
    };



    return (
        <>
            {missionLoading ? (
                renderSkeletonLoaders(3)
            ) : (
                <>
                    {missions.length !== 0 ? (
                        missions.map((item) => (
                            <React.Fragment key={item.id}>
                                <Card className="p-16 pb-8 rounded-lg shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)] bg-pi-primary bg-opacity-10" elevation={0}>
                                    <CardHeader
                                        className="p-0"
                                        sx={{
                                            "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                            "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                                        }}
                                        avatar={
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                                badgeContent={
                                                    <span>
                                                        <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                                    </span>
                                                }
                                            >
                                                <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={item.agent} />
                                            </Badge>
                                        }
                                        title={
                                            <Typography className="font-extrabold text-18">
                                                {item.agent.flName}
                                            </Typography>
                                        }
                                        subheader={
                                            <Typography className="line-clamp-1 text-14 text-pi-grey">
                                                {format(new Date(item.dateCreated), 'MM/dd/yyyy hh:mm a')}
                                            </Typography>
                                        }
                                        action={
                                            ""
                                        }
                                    />
                                    <CardContent style={{ cursor: 'pointer' }} onClick={() => navigate('/public/mission-details/' + item.missionId)} className="p-0">
                                        <Typography
                                            component="div"
                                            className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                        >
                                            {item.title}
                                        </Typography>
                                        <Box className="flex items-end justify-start mb-6 toggleReadmore relative">
                                            <Typography
                                                className="text-16 transition duration-300 line-clamp-3 mb-3 paragraph contents"
                                            >
                                                {item.description}
                                            </Typography>
                                        </Box>
                                        <PhotoGridView fileId={item.filesId} />
                                    </CardContent>
                                    <CardActions className="flex-col pt-2 pb-0 px-0 justify-start items-start mt-10">
                                        <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                                            <Typography className="gap-1 flex items-center text-pi-grey text-16">
                                                <SeenIcon />
                                                <span className="mt-1">{item.missionViews ? item.missionViews : '0'} Seen</span>
                                            </Typography>
                                            <Typography className="gap-1 flex items-center text-pi-grey text-16">
                                                <LikesIcon />
                                                <span className="mt-1">{item.missionCommentsCount ? item.missionCommentsCount : '0'} Likes</span>
                                            </Typography>
                                            <Typography className="gap-1 flex items-center text-pi-grey text-16">
                                                <PinIcon />
                                                <span className="mt-1">{item.missionPinned ? item.missionPinned : '0'} Pinned</span>
                                            </Typography>
                                            <Box className="flex-1 text-right text-pi-grey text-16">
                                                <SharerSvs link={`/public/mission-details/${item.missionId}`} title={item.title} description={item.description} />
                                            </Box>
                                        </Stack>
                                    </CardActions>
                                </Card>
                            </React.Fragment>
                        ))
                    ) : (
                        <Card className="p-16 rounded-lg mb-18 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                            <CardContent className="!p-0">
                                <Typography
                                    component="div"
                                    className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                                >
                                    There are no created missions available
                                </Typography>
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
        </>
    );
}

export default CreateMissionTabTwo;