import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker, InfoBox, InfoWindow } from "@react-google-maps/api";
import CONFIG from "app/config";
import { Card, TextField, Button, Item, Typography, Container, Dialog, Alert } from "@mui/material";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Box, GlobalStyles, height } from "@mui/system";
import MapInfoContents from "./content/MapInfoContent";
import { yupResolver } from "@hookform/resolvers/yup";
import { getMissionById } from "app/main/apps/public/mission/store/missionSlice";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";

const mapOptions = (maps) => {
    return {
        mapTypeId: maps.MapTypeId.HYBRID,
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.TOP_CENTER,
        },
        zoomControl: true,
        zoomControlOptions: {
            position: maps.ControlPosition.LEFT_CENTER,
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
            position: maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: false,
    };
};

const defaultValues = {
    id: "",
    title: "",
    description: "",
    fileId: "",
    attachmentData: [],
    lattitude: "",
    longtitude: "",
    images: []
};

export default function MissionCreate(params) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: CONFIG.MAPS,
    });

    if (!isLoaded) return <div></div>;
    return <Map agentsId={params.returnAgentId} id={params.id} path={params.returnPath} />;
}

function Map(params) {
    const dispatch = useDispatch();

    const [markerPosition, setMarkerPosition] = useState({ lat: 40.7128, lng: -74.006 });
    const [activePin, setActivePin] = useState(false);
    const [visibleInfo, setVisibleInfo] = useState(false);
    const [missionData, setMissionData] = useState(null);
    const [map, setMap] = useState(null);

    const schema = yup.object().shape({
        title: yup.string().required("Mission Title is required").min(8, "Mission Title must be at least 8 characters").max(32, "Mission Title cannot exceed 32 characters"),
        description: yup.string().required("Description is required"),
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const onMapClick = (e) => {
        const clickedLatLng = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };

        setValue("lattitude", e.latLng.lat(), { shouldDirty: true });
        setValue("longtitude", e.latLng.lng(), { shouldDirty: true });
        setMarkerPosition(clickedLatLng);
        setActivePin(true);
        setVisibleInfo(true)
    };

    const onClickPin = (e) => {
        setVisibleInfo(visibleInfo ? false : true)
    }

    const onDragPin = (e) => {
        if (visibleInfo) {
            setVisibleInfo(false)
        }
    }

    const onDragPinEnd = (e) => {
        if (!visibleInfo) {
            setVisibleInfo(true)
        }
        const clickedLatLng = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };
        setValue("lattitude", e.latLng.lat(), { shouldDirty: true });
        setValue("longtitude", e.latLng.lng(), { shouldDirty: true });
        setMarkerPosition(clickedLatLng);
    }

    if (params.id && !missionData) {
        dispatch(getMissionById({ id: params.id })).then((action) => {
            const pData = action.payload;
            const clickedLatLng = {
                lat: parseFloat(pData.lat),
                lng: parseFloat(pData.lng),
            };
            setMarkerPosition(clickedLatLng);
            setValue("id", pData.id)
            setValue('agentId', params.agentsId)
            setMissionData(pData)
        })
    }

    useEffect(() => {
        console.log('mapParammmms', params.agentsId)
        animateMapCenter(markerPosition);
    }, [markerPosition]);

    const animateMapCenter = (newCenter) => {
        if (map) {
            const start = map.getCenter();
            const startTime = Date.now();
            const duration = 50; // Animation duration in milliseconds

            const animate = () => {
                const now = Date.now();
                const elapsedTime = now - startTime;
                const easing = Math.sin((elapsedTime / duration) * (Math.PI / 2));
                const newLat = start.lat() + easing * (newCenter.lat - start.lat());
                const newLng = start.lng() + easing * (newCenter.lng - start.lng());
                const newCenterPosition = { lat: newLat, lng: newLng };

                map.panTo(newCenterPosition);

                if (elapsedTime < duration) {
                    requestAnimationFrame(animate);
                }
            };
            animate();
        }
    };

    const handleInfoWindowClose = () => {
        setVisibleInfo(false)
        reset();
    };

    return (
        <>
            <GoogleMap
                onClick={onMapClick}
                options={mapOptions}
                zoom={14}
                center={markerPosition}
                mapContainerClassName="map-container"
                fullscreenControl={false}
                onLoad={(map) => setMap(map)}
                clickableIcons={false}
            >
                {activePin && (
                    <Marker
                        position={markerPosition}
                        onClick={onClickPin}
                        draggable={true}
                        animation={google.maps.Animation.DROP}
                        icon={{
                            url: "assets/images/pin-background.png",
                        }}
                        onDrag={onDragPin}
                        onDragEnd={onDragPinEnd}
                    />
                )}
                {visibleInfo && (
                    <InfoWindow
                        options={{
                            gestureHandling: "greedy",
                            pixelOffset: new google.maps.Size(0, -50),
                        }}
                        disableAutoPan={true}
                        position={markerPosition}
                        visible={visibleInfo}
                        onCloseClick={handleInfoWindowClose}
                    >
                        <div className=" !p-16">
                            <FormProvider {...methods}>
                                <MapInfoContents item={missionData} />
                            </FormProvider>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
            {!params.id && !activePin && <Alert severity="info" sx={{ '& svg': { color: '#ffff' } }} className="absolute w-max left-0 right-0 text-16 bg-pi-blue-1 text-white rounded-md bottom-10 m-auto">To create the mission, you need to tap the map where your mission is located. You can move the map by dragging it.</Alert>}

            {
                params.id &&
                <>
                    <div className="map-button-container ">
                        <Link
                            to={params.path !== 'false' ? params.path : `/public/mission-details/${params.id}`}
                        >
                            <Button
                                size="large"
                                variant="contained"
                                color="secondary"
                                className="px-24 py-8 text-16 font-bold max-w-max rounded-md bg-pi-blue-1  hover:bg-pi-blue-1 text-white"
                            >
                                CANCEL
                            </Button>
                        </Link>
                    </div>
                </>
            }
        </>
    );
}