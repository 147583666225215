const mutation = {
    saveComment: `
        mutation saveComment($data: JSONString!) {
            saveComment (data: $data) {
                status,
                res,
                message
            }
        }
    `,
    deletePublicComment: `
        mutation deletePublicComment($id: String!) {
            deletePublicComment (id: $id) {
                status,
                res,
                message            
            }
        }
    `,
};

const query = {
    getComments: `
        query getComments($moduleId: String, $moduleType: String){
            getComments(moduleId: $moduleId, moduleType: $moduleType){
                id
                userId
                agentId
                agentPhotoUrl
                agentName
                moduleId
                moduleType
                fileId
                comments
                dateCreated
                dateUpdated
                agent {
                    userId
                    flName
                    username
                }
                timeAgo
            }
        }
    `,
    getMissionById: `
        query getMissionById($id: String){
            getMissionById(id: $id){
            id
            title
            description
            dateCreated
            filesId
            views
            pinned
            commentsCount
            agent{
                userId
                profilePicPath
                name
                flName
                username
            }
        }
    }`,
};
export { mutation, query };