import { Icon } from '@mui/material';
import React from 'react';

// CommentIcon Component
export class CommentIcon extends React.Component {
    render() {
        return (
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <defs>
                    <clipPath id="clippath">
                        <rect width="24" height="24" fill="none" />
                    </clipPath>
                </defs>
                <g clipPath="url(#clippath)">
                    <g id="comment-icon">
                        <path id="Icon_awesome-comment" data-name="Icon awesome-comment" d="M11.56,5.5c4.18,0,7.56,2.75,7.56,6.15-.02,1.46-.63,2.85-1.68,3.86,.33,1.05,.88,2.02,1.62,2.83,.07,.07,.08,.17,.04,.26-.04,.09-.12,.14-.22,.14-1.52-.03-2.98-.56-4.15-1.52-1.01,.38-2.09,.57-3.17,.57-4.18,0-7.56-2.75-7.56-6.15s3.39-6.15,7.56-6.15Z" fill="none" stroke="#656565" />
                    </g>
                </g>
            </svg>
        );
    }
}

// PinIcon Component
export class PinIcon extends React.Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <defs>
                    <clipPath id="clippath">
                        <rect width="24" height="24" fill="none" />
                    </clipPath>
                </defs>
                <g clipPath="url(#clippath)">
                    <g id="pin-icon">
                        <g id="Icon_feather-map-pin" data-name="Icon feather-map-pin">
                            <path
                                id="Path_1055"
                                data-name="Path 1055"
                                d="M17.77,10.88c0,4.58-5.88,8.5-5.88,8.5,0,0-5.88-3.92-5.88-8.5,0-3.25,2.63-5.88,5.88-5.88s5.88,2.63,5.88,5.88Z"
                                fill="none"
                                stroke="#656565"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.17"
                            />
                            <path
                                id="Path_1056"
                                data-name="Path 1056"
                                d="M13.85,10.88c0,1.08-.88,1.96-1.96,1.96-1.08,0-1.96-.88-1.96-1.96,0-1.08,.88-1.96,1.96-1.96,1.08,0,1.96,.88,1.96,1.96h0Z"
                                fill="none"
                                stroke="#656565"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.17"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}

// SeenIcon Component
export class SeenIcon extends React.Component {
    render() {
        return (
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 24 24"
            >
                <defs>
                    <clipPath id="clippath">
                        <rect width="24" height="24" fill="none" />
                    </clipPath>
                </defs>
                <g clipPath="url(#clippath)">
                    <g id="seen-icon">
                        <g id="Icon_feather-eye" data-name="Icon feather-eye">
                            <path
                                id="Path_1057"
                                data-name="Path 1057"
                                d="M4,12.23s2.73-5.23,7.5-5.23,7.5,5.23,7.5,5.23c0,0-2.73,5.23-7.5,5.23s-7.5-5.23-7.5-5.23Z"
                                fill="none"
                                stroke="#656565"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.17"
                            />
                            <path
                                id="Path_1058"
                                data-name="Path 1058"
                                d="M13.46,12.23c0,1.08-.88,1.96-1.96,1.96s-1.96-.88-1.96-1.96,.88-1.96,1.96-1.96c1.08,0,1.96,.88,1.96,1.96h0Z"
                                fill="#656565"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
export class LikesIcon extends React.Component {
    render() {
        return (
            <Icon className=" text-16 mx-5">favorite_border</Icon>
        );
    }
}


// Profil Pins Icon Blue and Yellow
export class PinnedIcon extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.5em', height: '1.5em' }} viewBox="0 0 8.84 12.243">
                <g id="Group_1452" data-name="Group 1452" transform="translate(-1197.877 -2025.664)">
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M3.744,7.489A3.744,3.744,0,1,1,7.489,3.744,3.749,3.749,0,0,1,3.744,7.489ZM3.693,1.5A2.245,2.245,0,1,0,5.937,3.744,2.248,2.248,0,0,0,3.693,1.5Z" transform="translate(1198.553 2026.501)" fill="#FBB633" />
                    <path id="Path_1201" data-name="Path 1201" d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z" transform="translate(1197.833 2025.664)" fill="#FBB633" />
                </g>
            </svg>
        )
    }
}
export class PinnedIconBlue extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '1.5em', height: '1.5em' }} viewBox="0 0 8.84 12.243">
                <g id="Group_1452" data-name="Group 1452" transform="translate(-1197.877 -2025.664)">
                    <path id="Subtraction_1" data-name="Subtraction 1" d="M3.744,7.489A3.744,3.744,0,1,1,7.489,3.744,3.749,3.749,0,0,1,3.744,7.489ZM3.693,1.5A2.245,2.245,0,1,0,5.937,3.744,2.248,2.248,0,0,0,3.693,1.5Z" transform="translate(1198.553 2026.501)" fill="#2EB7D7" />
                    <path id="Path_1201" data-name="Path 1201" d="M8.87,4.082a4.317,4.317,0,0,0-.1-.667A4.412,4.412,0,0,0,4.594,0H4.33c-.119,0-.239.013-.357.026A4.3,4.3,0,0,0,3.139.2a4.419,4.419,0,0,0-3.1,4.066v.306c0,.13.014.259.028.388a5.429,5.429,0,0,0,.137.753A4.668,4.668,0,0,0,.7,6.924c.115.195.244.381.364.572L3.7,11.709c.05.079.1.163.152.236a.77.77,0,0,0,.845.26.7.7,0,0,0,.395-.288L7.733,7.706c.113-.18.224-.363.341-.541a4.235,4.235,0,0,0,.24-.4,5.18,5.18,0,0,0,.559-2.045,4.441,4.441,0,0,0,0-.642ZM6.343,7.16c-.031.022-.062.043-.094.063l-.039.025c-.036.023-.073.044-.11.065s-.075.042-.112.061l-.005,0-.11.054c-.039.018-.079.036-.119.052-.08.033-.162.063-.245.091s-.169.052-.255.073c-.024.006-.048.012-.072.017l-.045.01-.082.016L4.971,7.7l-.082.012h0c-.084.011-.168.018-.253.022l-.045,0H4.58l-.116,0h-.01l-.091,0c-.026,0-.051,0-.077,0s-.053,0-.079,0a3.369,3.369,0,0,1-.5-.076c-.054-.011-.106-.025-.159-.041L3.51,7.6l-.069-.021-.062-.021L3.318,7.54h0q-.088-.032-.174-.07l-.073-.032c-.048-.022-.1-.045-.142-.07l-.071-.038-.02-.011A3.335,3.335,0,1,1,6.343,7.16Z" transform="translate(1197.833 2025.664)" fill="#2EB7D7" />
                </g>
            </svg>
        )
    }
}