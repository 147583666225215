import { authAgentRoles } from 'app/agent';
import AgentLogin from 'app/main/agent/login/AgentLogin';
import PublicRegister from '../apps/public/register/PublicRegister';
import PublicRegisterActivate from '../apps/public/register/PublicRegisterActivate';
import PublicRegisterSuccess from '../apps/public/register/PublicRegisterSuccess';

const AgentConfig = {
    settings: {
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authAgentRoles.onlyGuest,
    routes: [
        {
            path: 'agentlogin',
            element: <AgentLogin />,
        },
        {
            path: "public/register",
            element: <PublicRegister />,
        },
        {
            path: "public/register/activate/:id",
            element: <PublicRegisterActivate />,
        },
        {
            path: "public/register/success",
            element: <PublicRegisterSuccess />,
        },
    ],
};

export default AgentConfig;
