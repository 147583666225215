import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useDispatch } from "react-redux";
import { getFriendsActivity } from "../store/FriendsActivitySlice";
import ScrollToBottomTrigger from "app/services/ScrollToBottomTrigger";
import CreateContent from "./CreatedContent";
import JoinedContentV2 from "./JoinedContentV2";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import { motion } from "framer-motion";
import { CardContent, CardHeader, Skeleton } from "@mui/material";
import SkeletonLoader from "../components/SkeletonLoader";

function FriendsActivityContentV2(props) {
    const token = AgentJwtService.getDecodedAccessToken();
    const [loading, setLoading] = useState(true);
    const [onLoading, setOnLoading] = useState(true);
    const [category, setCategory] = useState('latest');
    const [hasLoadMore, setHasLoadMore] = useState(true);
    const [activities, setActivities] = useState([]);

    const dispatch = useDispatch();

    const loadItems = (start, end) => {
        dispatch(getFriendsActivity({ agentId: token.id, category: category, start: start, end: end }))
            .then((action) => {
                if (action.payload.length > 0) {
                    setActivities((prevActivities) => [...prevActivities, ...action.payload]);
                    console.log("setActivities",action.payload);
                    setHasLoadMore(true);
                } else {
                    setHasLoadMore(false);
                }
                setLoading(false);
                setOnLoading(false);
            });
    };

    const handleReachBottom = () => {
        if (hasLoadMore && !loading) {
            const start = activities.length;
            const end = start + 10;

            loadItems(start, end);
        }
    };

    useEffect(() => {
        setLoading(true);
        loadItems(0, 10);
    }, []);

    const renderSkeletonLoaders = (count) => {
        const skeletonLoaders = [];
        for (let i = 0; i < count; i++) {
            skeletonLoaders.push(
                <Stack key={i} direction={'column'} gap={{ lg: 3, md: 1.5, sm: 1.5, xs: 1.5 }} className="p-0 w-full">
                    <SkeletonLoader />
                </Stack>
            );
        }
        return skeletonLoaders;
    };

    return (
        <div>
            <Stack direction={'column'} gap={{ lg: 3, md: 1.5, sm: 1.5, xs: 1.5 }} className="font-nunito px-0 max1200px:py-28 py-16 m-auto  max1200px:max-w-lg md:w-10/12 sm:w-full w-full">
                <Card className="sm:p-0 px-16 rounded-none bg-transparent" elevation={0}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        className="w-full"
                    >
                        <Typography className="text-18 font-bold">Friends Activity</Typography>
                    </Stack>
                </Card>
                {onLoading ? (
                    renderSkeletonLoaders(3) // Adjust the number of skeleton loaders
                ) : activities.length === 0 ? (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { delay: 0.1 } }}
                        className="flex flex-1 items-center justify-center h-full"
                    >
                        <Typography color="textSecondary" variant="h5">
                            There are no Activities!
                        </Typography>
                    </motion.div>
                ) : (
                    activities.map((val, key) => (
                        <React.Fragment key={key}>
                            {val.kingPin == 1 ? (
                                <Card className="p-16 pb-8 rounded-lg mb-18 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)] bg-pi-primary bg-opacity-10" elevation={0}>
                                    <CreateContent data={val} isMain={true} />
                                </Card>
                            ) : (
                                <Card className="p-16 pb-8 rounded-lg mb-18 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                                    <JoinedContentV2 data={val} />
                                </Card>
                            )}
                        </React.Fragment>
                    ))
                )}
            </Stack>
            <ScrollToBottomTrigger onReachBottom={handleReachBottom} />
        </div>
    );
}

export default FriendsActivityContentV2;
