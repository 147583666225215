import { Avatar, Badge, Button, Card, CardContent, CardHeader, Icon, Stack, Typography } from '@mui/material';
import { LikesIcon, PinIcon, PinnedIcon, SeenIcon } from 'app/main/custom-icon/MissionCounterIcons';
import React from 'react';
function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}
export class MapsContent extends React.Component {
    render() {
        return (
            <Stack className=' my-16'>
                <Card className=" p-20 rounded-lg shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                    <Stack gap={3}>
                        <Stack direction={'row'} gap={3}>
                            <div className=" w-216 h-136 ">
                                <img
                                    src={'assets/images/pi-images/no-image.png'}
                                    alt={''}
                                    loading="lazy"
                                    objectFit='cover'
                                    className=" h-full"
                                />
                            </div>
                            <Stack className="flex-1 pr-12">
                                <CardHeader
                                    className="p-0"
                                    sx={{
                                        "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                        "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                    }}
                                    avatar={
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            sx={{
                                                '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                    borderRadius: '50%',
                                                    backgroundColor: 'white',
                                                    padding: '0px',
                                                    paddingTop: '3px',
                                                    bottom: '10px',
                                                    minWidth: '18px',
                                                    height: '20px'
                                                }
                                            }}
                                            badgeContent={
                                                <span
                                                >
                                                    <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                                </span>
                                            }
                                        >
                                            <Avatar alt={"data?.name"} {...stringAvatar("Ico, John Paul Baniqued")}
                                                className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />

                                        </Badge>
                                    }
                                    title={
                                        <Typography className=" font-extrabold text-18 flex gap-6">
                                            Let's make changes for a better life.
                                        </Typography>
                                    }
                                    subheader={
                                        <Typography className=" line-clamp-1 text-16 text-pi-grey">
                                            Created this mission yesterday
                                        </Typography>
                                    }

                                />
                                <CardContent style={{ cursor: 'pointer' }} className="p-0 !pb-0">
                                    <Typography
                                        className="text-16  transition duration-300 line-clamp-2 pt-4 !pb-0"
                                    >
                                        Our world is a beautiful tapestry of diverse cultures, landscapes, and experiences, all interconnected in a delicate balance of nature and humanity.
                                    </Typography>
                                </CardContent>
                                <Stack flex={1} alignItems={'flex-end'} direction="row" gap={'18px'} justifyContent={'start'} className="w-full mt-6">
                                    <Typography className=" gap-1 flex items-center  text-pi-grey">
                                        <div className="-mt-2">
                                            <SeenIcon />
                                        </div>
                                        <span className="mt-1 text-16">101 views</span>
                                    </Typography>
                                    <Typography className=" gap-1 flex items-center text-pi-grey">
                                        <LikesIcon />
                                        <span className="mt-1 text-16">101 likes</span>
                                    </Typography>
                                    <Typography className=" gap-1 flex items-center text-pi-grey">
                                        <div className="-mt-2"><PinIcon /> </div>
                                        <span className="mt-1 text-16">101 pinned</span>
                                    </Typography>

                                    <div className=" flex-1 text-right">
                                        <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2"><Icon className="mr-6 text-18">share</Icon><span className="sm:flex hidden">share</span></Button>
                                    </div>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} gap={3}>
                            <div className=" w-216 h-136 ">
                                <img
                                    src={'assets/images/pi-images/no-image.png'}
                                    alt={''}
                                    loading="lazy"
                                    objectFit='cover'
                                    className=" h-full"
                                />
                            </div>
                            <Stack className="flex-1 pr-12">
                                <CardHeader
                                    className="p-0"
                                    sx={{
                                        "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                        "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                    }}
                                    avatar={
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            sx={{
                                                '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                    borderRadius: '50%',
                                                    backgroundColor: 'white',
                                                    padding: '0px',
                                                    paddingTop: '3px',
                                                    bottom: '10px',
                                                    minWidth: '18px',
                                                    height: '20px'
                                                }
                                            }}
                                            badgeContent={
                                                <span
                                                >
                                                    <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                                </span>
                                            }
                                        >
                                            <Avatar alt={"data?.name"} {...stringAvatar("Ico, John Paul Baniqued")}
                                                className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />

                                        </Badge>
                                    }
                                    title={
                                        <Typography className=" font-extrabold text-18 flex gap-6">
                                            Our planet is a remarkable sphere of life.
                                        </Typography>
                                    }
                                    subheader={
                                        <Typography className=" line-clamp-1 text-16 text-pi-grey">
                                            Created this mission 2 days ago
                                        </Typography>
                                    }

                                />
                                <CardContent style={{ cursor: 'pointer' }} className="p-0 !pb-0">
                                    <Typography
                                        className="text-16  transition duration-300 line-clamp-2 pt-4 !pb-0"
                                    >
                                        Our planet is a remarkable sphere of life, teeming with vibrant ecosystems, breathtaking landscapes, and a rich array of species that together create a delicate balance necessary for sustaining life.
                                    </Typography>
                                </CardContent>
                                <Stack flex={1} alignItems={'flex-end'} direction="row" gap={'18px'} justifyContent={'start'} className="w-full mt-6">
                                    <Typography className=" gap-1 flex items-center  text-pi-grey">
                                        <div className="-mt-2">
                                            <SeenIcon />
                                        </div>
                                        <span className="mt-1 text-16">101 views</span>
                                    </Typography>
                                    <Typography className=" gap-1 flex items-center text-pi-grey">
                                        <LikesIcon />
                                        <span className="mt-1 text-16">101 likes</span>
                                    </Typography>
                                    <Typography className=" gap-1 flex items-center text-pi-grey">
                                        <div className="-mt-2"><PinIcon /> </div>
                                        <span className="mt-1 text-16">101 pinned</span>
                                    </Typography>

                                    <div className=" flex-1 text-right">
                                        <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2"><Icon className="mr-6 text-18">share</Icon><span className="sm:flex hidden">share</span></Button>
                                    </div>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} gap={3}>
                            <div className=" w-216 h-136 ">
                                <img
                                    src={'assets/images/pi-images/no-image.png'}
                                    alt={''}
                                    loading="lazy"
                                    objectFit='cover'
                                    className=" h-full"
                                />
                            </div>
                            <Stack className="flex-1 pr-12">
                                <CardHeader
                                    className="p-0"
                                    sx={{
                                        "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                        "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                    }}
                                    avatar={
                                        <Badge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            sx={{
                                                '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                    borderRadius: '50%',
                                                    backgroundColor: 'white',
                                                    padding: '0px',
                                                    paddingTop: '3px',
                                                    bottom: '10px',
                                                    minWidth: '18px',
                                                    height: '20px'
                                                }
                                            }}
                                            badgeContent={
                                                <span
                                                >
                                                    <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                                </span>
                                            }
                                        >
                                            <Avatar alt={"data?.name"} {...stringAvatar("Ico, John Paul Baniqued")}
                                                className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />

                                        </Badge>
                                    }
                                    title={
                                        <Typography className=" font-extrabold text-18 flex gap-6">
                                            Our country is a unique blend of history.
                                        </Typography>
                                    }
                                    subheader={
                                        <Typography className=" line-clamp-1 text-16 text-pi-grey">
                                            Created this mission 3 days ago
                                        </Typography>
                                    }

                                />
                                <CardContent style={{ cursor: 'pointer' }} className="p-0 !pb-0">
                                    <Typography
                                        className="text-16  transition duration-300 line-clamp-2 pt-4 !pb-0"
                                    >
                                        Our country is a unique blend of history, culture, and innovation, where the spirit of its people drives progress and fosters a sense of community and belonging. </Typography>
                                </CardContent>
                                <Stack flex={1} alignItems={'flex-end'} direction="row" gap={'18px'} justifyContent={'start'} className="w-full mt-6">
                                    <Typography className=" gap-1 flex items-center  text-pi-grey">
                                        <div className="-mt-2">
                                            <SeenIcon />
                                        </div>
                                        <span className="mt-1 text-16">101 views</span>
                                    </Typography>
                                    <Typography className=" gap-1 flex items-center text-pi-grey">
                                        <LikesIcon />
                                        <span className="mt-1 text-16">101 likes</span>
                                    </Typography>
                                    <Typography className=" gap-1 flex items-center text-pi-grey">
                                        <div className="-mt-2"><PinIcon /> </div>
                                        <span className="mt-1 text-16">101 pinned</span>
                                    </Typography>

                                    <div className=" flex-1 text-right">
                                        <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2"><Icon className="mr-6 text-18">share</Icon><span className="sm:flex hidden">share</span></Button>
                                    </div>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        );
    }
}