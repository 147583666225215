import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import "../../../../../styles/wyswyg-editor.css";
import MapContent from "./content/MapContent";
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";
import { createIpAddress } from "../friends-activity/store/FriendsActivitySlice";
import { useDispatch } from "react-redux";
import MissionCreate from "app/main/apps/public/mission/v2/MissionCreate";
import MissionDetails from "app/main/apps/public/mission/v2/MissionDetails";

function PublicMissionDetails(props) {

    const { id } = useParams();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isJoin = queryParams.get('join');
    const returnPath = queryParams.get('return');
    const returnAgentId = queryParams.get('agent');

    console.log("SITE PARAMS", isJoin, returnPath, returnAgentId)

    return isJoin ? (<MissionCreate returnAgentId={returnAgentId} id={id} returnPath={returnPath} />) : (<MissionDetails />);
}
export default PublicMissionDetails;
