import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import AttachmentUploader from "app/services/AttachmentUploader";
import FuseLoading from "@fuse/core/FuseLoading";
import ReactPlayer from "react-player";
import { InputLabel, OutlinedInput } from "@mui/material";
import CONFIG from "app/config";
import {
    FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon, TelegramShareButton, TelegramIcon, LinkedinShareButton, LinkedinIcon
} from 'react-share';

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));




function SharerSvs(props) {
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);

    const { module = '', link, title, description } = props;

    useEffect(() => {
        if (openDialog) {

        }
    }, [openDialog]);


    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    const [url, setURL] = useState(CONFIG.WEB + link);
    const inputRef = React.useRef(null);

    const handleCopyClick = (e) => {
        e.preventDefault();
        inputRef.current.querySelector('input').select();
        document.execCommand('copy');
        dispatch(
            showMessage({
                message: "Link has been successfully copied.",
                autoHideDuration: 5000, //ms
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                variant: "success",
            })
        );

    };

    return (

        <Root>
            {
                (() => {
                    switch (module) {
                        case "at-1":
                            return <div>
                                <Button onClick={handleOpenDialog} className=" rounded-md text-white" variant="text" startIcon={<Icon >share</Icon>}>
                                    share
                                </Button>
                            </div >;
                        default:
                            return <div>
                                <Button onClick={handleOpenDialog} variant="text" className="text-16 text-pi-grey !p-0 rounded-2"><Icon className="mr-6 text-18">share</Icon><span>share</span></Button>
                                {/* <Button variant="text" onClick={handleOpenDialog} className="text-14 font-bold text-pi-blue-1 self-end p-0"><Icon className="mr-6">share</Icon><span className="sm:flex hidden">share</span></Button> */}
                            </div>;

                    }
                })()
            }

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                scroll="body"
                sx={{

                    '& .MuiPaper-root': {
                        width: "100%",
                    },
                }}
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full">
                        <Typography variant="subtitle1" color="inherit">
                            Share - {title}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <form
                    noValidate
                    className="flex flex-col"
                >
                    <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
                        {/* <input
                            ref={inputRef}
                            type="text"
                            value={url}
                            onChange={(e) => setURL(e.target.value)}
                        />
                        <button onClick={handleCopyClick}>Copy</button> */}
                        <div className="w-full">
                            <FormControl sx={{ m: 1, }} variant="outlined" className="w-full">
                                <InputLabel htmlFor="outlined-adornment-password">URL</InputLabel>
                                <OutlinedInput
                                    ref={inputRef}
                                    id="outlined-adornment-password"
                                    value={url}
                                    type="text"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleCopyClick}
                                                edge="end"
                                            >
                                                <Icon className="mr-6">content_copy</Icon>
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            <div className="share-buttons flex items-center justify-center w-full">
                                <FacebookShareButton url={url} quote={title}>
                                    <FacebookIcon round />
                                </FacebookShareButton>
                                <TwitterShareButton url={url} title={title}>
                                    <TwitterIcon round />
                                </TwitterShareButton>
                                <TelegramShareButton url={url} title={title}>
                                    <TelegramIcon round />
                                </TelegramShareButton>
                                <WhatsappShareButton url={url} title={title}>
                                    <WhatsappIcon round />
                                </WhatsappShareButton>
                                <LinkedinShareButton url={url} title={title} summary={description}>
                                    <LinkedinIcon round />
                                </LinkedinShareButton>
                            </div>
                        </div>
                    </DialogContent>

                    <DialogActions className="justify-center px-8 py-16">
                        <div className="px-16">
                            <Button
                                className="rounded-md ml-5"
                                variant="outlined"
                                type="button"
                                onClick={handleCloseDialog}
                            >
                                Cancel
                            </Button>
                        </div>
                    </DialogActions>
                </form>
            </Dialog>
        </Root>
    );
}

export default SharerSvs;
