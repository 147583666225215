import React, { useState } from 'react';
import { Collapse, Typography, Button, Icon } from '@mui/material';
import FuseScrollbars from '@fuse/core/FuseScrollbars';

const SeeMoreCollapsible = ({ text, maxLength }) => {
    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };
    const truncatedText = text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

    return (
        <div>
            <div className=" max-600px:!max-h-full " style={{ overflowY: 'scroll', maxHeight: "13.6rem" }} >
                <Typography
                    className="text-16 font-600 text-pi-black"
                    variant="body1"
                    component="div"
                    style={{ whiteSpace: 'pre-wrap' }} // This will preserve newlines
                >
                    {expanded ? text : truncatedText}
                </Typography>
            </div>
            {text.length > maxLength ?
                <Button className=' text-16 text-pi-blue-1 font-600 rounded-md' endIcon={expanded ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>} onClick={handleToggle} size="small" color="primary">
                    {expanded ? 'See less' : 'See more'}
                </Button>
                : null}

        </div>
    );
};

export default SeeMoreCollapsible;
