import { authAgentRoles } from 'app/agent';
import PageContent from './pages/PageContent';

const PagesAppConfig = {
    settings: {
        type: "public",
        theme: {
            main: "default",
            navbar: "default",
            toolbar: "default",
            footer: "default"
        },
        layout: {
            config: {
                navbar: {
                    display: true,
                },
                toolbar: {
                    display: true,
                },
                footer: {
                    display: true,
                },
                leftSidePanel: {
                    display: true,
                },
                rightSidePanel: {
                    display: true,
                },
            },
        },
    },
    auth: authAgentRoles.agent,
    routes: [
        {
            path: 'page/:slugs',
            element: <PageContent />,
        },
    ],
};

export default PagesAppConfig;
