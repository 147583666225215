import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getSignedUrlPath } from "./store/ServiceSlice";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, } from "@mui/material";
import LazyLoad from 'react-lazy-load';
import { styled } from "@mui/material/styles";
import { color } from '@mui/system';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
    // background: '#dfdfdf',
}));


function AttachmentSvs({ module, path, data }) {
    const dispatch = useDispatch();

    const [signedUrl, setSignedUrl] = useState(null);

    useEffect(() => {
        dispatch(getSignedUrlPath(path)).then((action) => {
            const data = action.payload;
            setSignedUrl(data);
        })
    }, [dispatch]);

    function stringToColor(string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = "#";

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }

        return color;
    }

    function stringAvatar(name) {
        var username = name.split(", "),
            lastName = username[0].split(" ")[0][0],
            firstName = username[username.length - 1].split(" ")[0][0];
        if (firstName == lastName) {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                    color: '#fff'
                },

                children: `${firstName}`,
            };
        } else {
            return {
                sx: {
                    bgcolor: stringToColor(name),
                    color: '#fff'
                },

                children: `${firstName}${lastName}`,
            };
        }
    }

    const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
        // width: 40,
        borderRadius: "unset",
        width: 60,
        height: 60,
        "& img": {
            objectFit: "contain",
        },
    }));



    return (
        <>
            {
                (() => {
                    switch (module) {
                        case "at-1":
                            return <div>
                                {signedUrl ? (
                                    <Avatar className=" bg-pi-light-grey border-1 border-pi-yellow-orange" alt="user photo" src={signedUrl} />
                                ) : (

                                    (data ? (
                                        // <Avatar className="border-1 border-pi-yellow-orange uppercase" {...stringAvatar(data.name)} ></Avatar>
                                        <Avatar className="border-1 border-pi-yellow-orange" style={{ height: '46px', width: '46px' }}></Avatar>
                                    ) : (
                                        <Avatar className="border-1 border-pi-yellow-orange" style={{ height: '46px', width: '46px' }}></Avatar>

                                    ))
                                )}
                            </div >;
                        case "at-2":
                            return <>
                                {signedUrl ? (
                                    <LazyLoad>
                                        <img
                                            className="max-w-none w-full h-full object-cover rounded-none sm:rounded-md"
                                            src={signedUrl}
                                            alt={data?.title}
                                            loading="lazy"
                                        />
                                    </LazyLoad>
                                ) : (
                                    <div></div>
                                )}
                            </>;
                        case "at-3":
                            return <>
                                {signedUrl ? (
                                    <Avatar className='bg-pi-light-grey' aria-label="recipe" style={{ height: '47px', width: '47px' }} src={signedUrl}>
                                    </Avatar>
                                ) : (
                                    <Avatar className='bg-pi-light-grey' aria-label="recipe" style={{ height: '47px', width: '47px' }}>
                                    </Avatar>
                                )}
                            </>;
                        case "at-4":
                            return <>
                                {signedUrl ? (
                                    <Avatar sx={{ bgcolor: 'grey', color: '#314159', width: "98px", height: "98px" }} className=" bg-pi-light-grey !font-nunito !text-36 !font-bold !border-pi-yellow-orange !border-3 " src={signedUrl} />

                                ) : (
                                    <div></div>
                                )}
                            </>;
                        case "at-5":
                            return <>
                                {signedUrl ? (
                                    <Avatar sx={{ bgcolor: 'grey', color: '#314159', width: "38px", height: "38px" }} className="bg-pi-light-grey !font-nunito !text-16 !font-bold !border-pi-yellow-orange !border-1" src={signedUrl} />
                                ) : (
                                    <div></div>
                                )}
                            </>;
                        case "at-6":
                            return <>
                                {signedUrl ? (

                                    <Avatar alt={data?.name} src={signedUrl}
                                        className="bg-pi-light-grey uppercase text-16" style={{ height: '46px', width: '46px' }} />

                                ) : (
                                    (data.name ? (

                                        // <Avatar alt={data?.name} {...stringAvatar(data?.name)}
                                        //     className=" uppercase text-16" style={{ height: '46px', width: '46px' }} />
                                        <Avatar className="border-1" style={{ height: '46px', width: '46px' }}></Avatar>

                                    ) : (
                                        <SmallAvatar className=" uppercase text-16" style={{ height: '46px', width: '46px' }} />
                                    ))
                                )}
                            </>;
                        case "at-8":
                            return <>
                                {signedUrl ? (
                                    <Avatar className=" bg-pi-light-grey border-1 border-pi-yellow-orange" style={{ width: 95, height: 95 }} src={signedUrl} ></Avatar>
                                ) : (
                                    <Avatar className="border-1 border-pi-yellow-orange" style={{ width: 95, height: 95 }}></Avatar>
                                )}
                            </>;
                        case "at-9":
                            return <>
                                {signedUrl ? (
                                    <Badge
                                        overlap="circular"
                                        sx={{
                                            "& .MuiBadge-anchorOriginTopRightCircular": {
                                                top: "11px",
                                                right: "13px",
                                                padding: 0,
                                                transform: "unset",
                                            },
                                        }}
                                        badgeContent={
                                            <SmallAvatar aria-label="recipe" src={signedUrl}>
                                            </SmallAvatar>
                                        } >
                                        <BackgroundAvatar
                                            alt="Remy Sharp"
                                            src={data.kingPin == 1 ? "assets/images/pin-background.webp" : "assets/images/pin-blue-background.svg"}
                                            loading="lazy"
                                        />
                                    </Badge>
                                ) : (
                                    (data ? (
                                        <Badge
                                            overlap="circular"
                                            sx={{
                                                "& .MuiBadge-anchorOriginTopRightCircular": {
                                                    top: "11px",
                                                    right: "13px",
                                                    padding: 0,
                                                    transform: "unset",
                                                },
                                            }}
                                            badgeContent={data.agent ?
                                                <SmallAvatar className="border-1 uppercase"
                                                // {...stringAvatar(data.agent?.name)}
                                                ></SmallAvatar> : <SmallAvatar className="border-1 "></SmallAvatar>

                                            } >
                                            <BackgroundAvatar
                                                alt="Remy Sharp"
                                                src={data.kingPin == 1 ? "assets/images/pin-background.webp" : "assets/images/pin-blue-background.svg"}
                                                loading="lazy"
                                            />
                                        </Badge>
                                    ) : (
                                        null
                                    ))
                                )}
                            </>;
                        default:
                            return <div></div>;

                    }
                })()
            }
        </>
    );
}

export default AttachmentSvs;