import * as React from "react";
import Typography from "@mui/material/Typography";
import { Badge, Card, CardHeader, Stack, Box, IconButton, Icon, Avatar, Button } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CardActions from "@mui/material/CardActions";
import format from "date-fns/format";
import AttachmentSvs from "app/services/AttachmentSvs";
import PhotoGridView from "app/services/PhotoGridView";
import SharerSvs from "app/services/SharerSvs";
import { PinIcon, SeenIcon, PinnedIcon, LikesIcon, PinnedIconBlue } from "../../../../custom-icon/MissionCounterIcons"
import SkeletonLoader from "app/main/apps/public/friends-activity/components/SkeletonLoader";
import { useEffect, useState } from "react";
import OtherAgents from "./OthersAgents";
import Commentsv2 from "app/services/comments/Commentsv2";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { getMarkersByMissionId } from "app/main/apps/public/mission/store/missionDetailsSlice";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import JoinButtonSvs from "app/services/JoinButtonSvs";
import { AgentMissionActivityTabLoader } from "../components/AgentSkeletonLoader";
import LikeButton from "app/main/apps/public/friends-activity/components/LikeButton";
import { useCallback } from "react";
import { agentById, getMarkersByAgentId, getMissionJoined } from '../../store/agentSlice';

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}

function JoinMissionTabTwo({ missions, joined, agentToken, agentId }) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [missionLoading, setmissionLoading] = useState(true);
    const [isFavorite, setIsFavorite] = useState(false);
    const [count, setCount] = useState(0);
    const [isExpandedrep, setIsExpandedrep] = useState(false);
    const [kingPinMission, setKingPinMission] = useState([]);
    const [objectReload, setObjectReload] = useState({});

    const [missionData, setMissionData] = useState([]);


    useEffect(() => {
        setMissionData(missions);
    }, [missions]);



    console.log("datarReload", missionData)

    const toggleExpand = () => {
        setIsExpandedrep(!isExpandedrep);
    };

    const handleClick = () => {
        setIsFavorite(!isFavorite);
        setCount(count + 1);
    };

    useEffect(() => {
        missionData.forEach(mission => {
            dispatch(getMarkersByMissionId({
                id: mission.missionId, // Make sure this matches your mission object structure
                type: "missions"
            })).then((missionAction) => {
                console.log("getMarkersByMissionId", missionAction.payload[0]);
                setKingPinMission(missionAction.payload[0])
            });
        });
        // setLoading(false);
        setmissionLoading(false);
    }, [missions, dispatch]);

    const renderSkeletonLoaders = (count) => {
        const skeletonLoaders = [];
        for (let i = 0; i < count; i++) {
            skeletonLoaders.push(
                <Stack key={i} direction={'column'} gap={1.5} className=" w-full">
                    <SkeletonLoader></SkeletonLoader>
                </Stack>
            );
        }
        return skeletonLoaders;
    };

    const dataReload = useCallback(async () => {
        try {
            // setmissionLoading(true);

            const agentAction = await dispatch(agentById(agentId));

            const agentData = agentAction.payload;

            if (!agentData || !agentData.id) {
                console.error('Invalid agent data received');
                return;
            }

            const markersAction = await dispatch(getMarkersByAgentId({
                id: agentData.id,
                type: "markers"
            }));

            const newData = markersAction.payload;
            console.log("MissionDetailContentdataReload", newData);

            if (Array.isArray(newData)) {
                setMissionData(prevMissions =>
                    prevMissions.map(mission => {
                        const updatedMission = newData.find(item => item.missionId === mission.missionId);
                        if (updatedMission) {
                            return {
                                ...mission,
                                // action: updatedMission.action,
                                likers: updatedMission.likers,
                                // countOnCommentsById: updatedMission.countOnCommentsById
                            };
                        }
                        return mission;
                    })
                );
            } else {
                console.error('Unexpected data structure received:', newData);
            }

        } catch (error) {
            console.error("Error reloading mission data:", error);
        } finally {
            setmissionLoading(false);
        }
    }, [missions, dispatch]);





    return (
        <>
            {missionLoading ? (
                renderSkeletonLoaders(3)
            ) : (
                <>
                    {missionData.length !== 0 ? (
                        missionData.map((item) => (
                            <React.Fragment key={item.id}>
                                <Card className="p-16 pb-8 rounded-lg mb-18 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} key={item.id}>
                                    <CardHeader
                                        className="p-0"
                                        sx={{
                                            "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                            "& .MuiAvatar-root": { border: '1px solid #2EB7D7' },
                                        }}
                                        avatar={
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                                badgeContent={
                                                    <span
                                                    >
                                                        <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                                                    </span>
                                                }
                                            >
                                                <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={item.agent} />

                                            </Badge>
                                        }
                                        title={
                                            <Typography className=" font-extrabold text-18 flex gap-6">
                                                {item.agent.flName}
                                                <Typography className=" line-clamp-1 text-16 text-pi-grey mt-2">
                                                    Joined this mission
                                                </Typography>
                                            </Typography>
                                        }
                                        subheader={<OtherAgents item={item} />}
                                        action={
                                            <>
                                            </>
                                        }
                                    />
                                    <Card elevation={0} className=" flex flex-row gap-8 bg-[#fff8df] rounded-none -mx-16 mt-8 p-12">
                                        <div className=" w-216 h-136 ">
                                            <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${kingPinMission.missionId}`}>
                                                <PhotoGridView fileId={kingPinMission.filesId} single={true} isDisabledModal={true} />
                                            </Link>
                                        </div>
                                        <div className="flex-1 flex-grow-[3]" >
                                            <CardHeader
                                                className="p-0"
                                                sx={{
                                                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                                    "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                                                }}
                                                avatar={
                                                    <Badge
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                                        badgeContent={
                                                            <span
                                                            >
                                                                <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                                            </span>
                                                        }
                                                    >
                                                        {/* <Avatar alt={"item.joinedAgents?.flName"} {...stringAvatar(item.joinedAgents?.flName || "Unknown")}
                                                            className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} /> */}
                                                        <Avatar style={{ height: '46px', width: '46px' }}></Avatar>

                                                    </Badge>
                                                }
                                                title={
                                                    <Typography onClick={() => navigate('/public/mission-details/' + kingPinMission.missionId)} className=" font-extrabold text-18 flex gap-6">
                                                        {kingPinMission.title}
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography className=" line-clamp-1 text-16">
                                                        by {kingPinMission.agent?.flName}
                                                    </Typography>
                                                }

                                            />
                                            <CardContent style={{ cursor: 'pointer' }} className="p-0 !pb-0">
                                                <Typography
                                                    onClick={() => navigate('/public/mission-details/' + item.missionId)}
                                                    className="text-16  transition duration-300 line-clamp-2 pt-4 !pb-0"
                                                >
                                                    {kingPinMission.description}
                                                </Typography>
                                                <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full mt-6">
                                                    <Typography className=" gap-1 flex items-center  text-pi-grey">
                                                        <div className="-mt-2">
                                                            <SeenIcon />
                                                        </div>
                                                        <span className="mt-1 text-16">{kingPinMission.missionViews ? kingPinMission.missionViews : '0'} </span>
                                                    </Typography>
                                                    <Typography className=" gap-1 flex items-center text-pi-grey">
                                                        <LikesIcon />
                                                        <span className="mt-1 text-16">{kingPinMission.likers ? kingPinMission.likers.split(',').length : '0'} Likes </span>
                                                    </Typography>
                                                    <Typography className=" gap-1 flex items-center text-pi-grey">
                                                        <div className="-mt-2"><PinIcon /> </div>
                                                        <span className="mt-1 text-16">{kingPinMission.missionPinned ? kingPinMission.missionPinned : '0'} </span>
                                                    </Typography>

                                                    <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2">
                                                        <SharerSvs link={`/public/mission-details/${item.missionId}`} title={item.title} description={item.description} />
                                                    </Button>

                                                    <div className=" flex-1 text-right">

                                                        {agentToken !== agentId ? (
                                                            <>
                                                                <JoinButtonSvs agent_id={agentId} mission_id={item.missionId} module={"at-3"} />
                                                            </>
                                                        ) :

                                                            <>
                                                                <Button
                                                                    variant="contained"
                                                                    className="!text-[1.3rem] whitespace-nowrap bg-pi-blue-1 hover:bg-pi-blue-1 !px-8 !py-2 rounded-4 text-white"
                                                                    onClick={() => navigate('/public/mission-details/' + item.missionId)}
                                                                >
                                                                    View mission
                                                                </Button>
                                                            </>
                                                        }

                                                    </div>
                                                </Stack>
                                            </CardContent>
                                        </div>
                                    </Card>
                                    {/* Card for parent agent */}
                                    <CardHeader
                                        className="p-0 mt-8"
                                        sx={{
                                            "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                            "& .MuiAvatar-root": { border: '1px solid #2EB7D7' },
                                        }}
                                        avatar={
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                                badgeContent={
                                                    <span
                                                    >
                                                        <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                                                    </span>
                                                }
                                            >
                                                <AttachmentSvs module={"at-6"} path={item.agent?.profilePicPath} data={item.agent} />

                                            </Badge>
                                        }
                                        title={
                                            <Typography className=" font-extrabold text-18 flex gap-6">
                                                <Typography
                                                    component="div"
                                                    className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-3"
                                                >
                                                    {item.title}
                                                </Typography>
                                            </Typography>
                                        }
                                        subheader={
                                            <Typography className=" line-clamp-1 text-16 text-pi-grey">
                                                {item.agent.flName} joined {item.timeAgo}.
                                            </Typography>
                                        }

                                    />
                                    <CardContent style={{ cursor: 'pointer' }} className="py-0 px-60 !cursor-default">

                                        <Box className="flex items-end justify-start mb-0 toggleReadmore relative">
                                            <Typography
                                                className="text-16  transition duration-300 line-clamp-3 mb-3 paragraph contents cursor-pointer"
                                                onClick={() => navigate('/public/mission-details/' + item.missionId)}
                                            >
                                                {item.description}
                                            </Typography>
                                        </Box>
                                        <PhotoGridView fileId={item.filesId} />
                                        <Stack className="mt-6 -ml-5" direction={'row'} gap={3} alignItems={'center'}>
                                            <Stack direction={'row'} alignItems={'center'}>
                                                <LikeButton
                                                    missionId={item.missionId}
                                                    userId={item.agent?.userId}
                                                    flName={item.agent?.flName}
                                                    initialAction={item.action}
                                                    reloadActivities={() => dataReload()}
                                                    likers={item.likers}
                                                    link={'/public/mission-details/' + item.missionId}
                                                />
                                            </Stack>
                                            <Button
                                                onClick={toggleExpand}
                                                size="small" variant="text" className="h-fit font-bold text-16 !bg-none hover:!bg-none text-black !p-o rounded-md">
                                                {item.countOnCommentsById + ' ' + 'Replies'}
                                                <Icon>{isExpandedrep ? 'expand_less' : 'expand_more'}</Icon>
                                                {/* <Icon>expand_less</Icon> kapag naghide */}
                                            </Button>
                                        </Stack>
                                        {isExpandedrep && (
                                            <Commentsv2 moduleType="mission-joiners" moduleId={item.id} reloadActivities={() => dataReload()} />
                                        )}
                                    </CardContent>
                                </Card>
                            </React.Fragment>
                        ))
                    ) : (
                        // <Card className="p-16 rounded-lg mb-18 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0}>
                        //     <CardContent className="!p-0">
                        //         <Typography
                        //             component="div"
                        //             className="font-bold mt-5 text-18 mb-0 text-pi-black line-clamp-2"
                        //         >
                        //             There are no joined missions available
                        //         </Typography>
                        //     </CardContent>
                        // </Card>
                        <Stack spacing={2}>
                            <AgentMissionActivityTabLoader />
                            <AgentMissionActivityTabLoader />
                            <AgentMissionActivityTabLoader />
                        </Stack>
                    )}
                </>
            )}
        </>
    );
}

export default JoinMissionTabTwo;