import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";

import React, { useState, useRef } from "react";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { GlobalStyles, height } from "@mui/system";
import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Stack, TextareaAutosize, Typography } from "@mui/material";
import { PinIcon, SeenIcon, CommentIcon, PinnedIcon, PinnedIconBlue } from "../../../custom-icon/MissionCounterIcons"
import SharerSvs from "app/services/SharerSvs";
const Root = styled(FusePageSimple)(() => ({
    minHeight: "unset",
    "& .FusePageSimple-header.FusePageSimple-topBg": {
        display: "none",
    },
    "& .flex.flex-auto.flex-col.container.z-10.h-full`": {
        width: "100%",
        maxWidth: "unset",
    },
    "& .FusePageSimple-header": {
        background: "transparent",
        backgroundSize: "cover",

        height: 152.99,
    },
    "& .FusePageSimple-wrapper": {
        background: "transparent",
        height: "unset",
        width: "100%",
    },
    "& .MuiDrawer-root.MuiDrawer-docked": {
        "& .MuiPaper-root": {
            width: "17.708333333333332vw",
            minWidth: "290px"
        }
    },
    "& .max-h-screen-content-100 .ps__rail-y": {
        display: "none !important",
    },
    "& .FusePageSimple-sidebarContent": {
        height: "calc(100vh - 7.6rem)",
    },
    "& .FusePageSimple-toolbar": {
        height: "76px",
    },
}));
function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}

function PublicCommunityDetails(props) {
    return (
        <>
            <GlobalStyles
                styles={(theme) => ({
                    "#fuse-main": {
                        height: "100% !important",
                    },
                    " .FusePageSimple-sidebar, .FusePageSimple-sidebarHeader":
                    {
                        background: "white !important",
                    },
                    " .absolute-important":
                    {
                        position: "absolute !important",
                    },
                    " div.FusePageSimple-sidebarHeader.permanent": {
                        minHeight: 0,
                        height: "unset"
                    }
                })}
            />

            <Root

                content={
                    <Stack gap={1.5} className="w-full  max1200px:w-lg md:w-10/12 sm:w-full p-16 mt-16 m-auto mb-32 min-h-screen">
                        <Card className="p-16 pb-8 rounded-lg mt-4 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} >
                            <CardHeader
                                className="p-0"
                                sx={{
                                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                    // "& .MuiAvatar-root": { border: '2.5px solid #FBB633' },
                                }}
                                avatar={
                                    <>

                                        <Avatar alt={"data?.name"} {...stringAvatar("Ico, John Paul Baniqued")}
                                            className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />

                                    </>
                                }
                                title={
                                    <Typography className=" font-extrabold text-18">
                                        John Paul Ico
                                    </Typography>
                                }
                                subheader={
                                    <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                        @superagent · Posted just now
                                    </Typography>
                                }
                                action={
                                    ""
                                }
                            />
                            <CardContent className="p-0">
                                <Box className="flex items-end justify-start relative">
                                    <Typography
                                        className="text-16 my-4 "
                                    >
                                        {/* {item.description} */}
                                        Hey everyone!

                                        As we dive into another week, I wanted to take a moment to connect. We all have so much going on in our lives, and it’s important to share and support each other.

                                        What’s been on your mind lately?
                                        Whether it’s something exciting, a challenge you’re facing, or just a thought you can’t shake, we’d love to hear from you!

                                        Feel free to share your thoughts in the comments below. Let’s create a space where we can encourage one another, brainstorm ideas, or just chat about life!

                                        Looking forward to hearing from you all!
                                    </Typography>
                                </Box>
                                {/* <PhotoGridView fileId={item.filesId} /> */}
                            </CardContent>

                            <CardActions className="flex-col pt-2 pb-0  px-0 justify-start items-start">
                                <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full">
                                    <Typography className=" gap-1 flex items-center ">
                                        <SeenIcon />
                                        <span className="mt-1">101 Seen</span>
                                    </Typography>
                                    <Typography className=" gap-1 flex items-center">
                                        <CommentIcon />
                                        <span className="mt-1">101 Comments</span>
                                    </Typography>

                                    <Box className="flex-1 text-right">
                                        <SharerSvs />
                                    </Box>
                                </Stack>
                            </CardActions>
                        </Card>

                        <Card className="p-16 pb-8 rounded-lg mt-4 shadow-[0_6px_12px_-15px_rgba(0,0,0,0.5)]" elevation={0} >
                            <Typography component="div" variant="h5" className="font-bold">
                                2 Comments
                            </Typography>
                            <Divider className=" -mx-16 mt-16" />

                            <div className=" border-1 rounded-md border-grey-300 p-16 my-16 text-right">
                                <TextareaAutosize
                                    maxRows={4}
                                    minRows={2}
                                    className="text-16 leading-tight w-full resize-none "
                                    placeholder="Please write your thoughts here..."
                                />
                                <Button variant='contained' color="primary">Add Comment</Button>
                            </div>
                            <Stack gap={1}>
                                <div>
                                    <CardHeader
                                        className="p-0"
                                        sx={{
                                            alignItems: 'start',
                                            "& .MuiCardHeader-avatar": { mr: "8px", ml: '0px', mt: '5px' },
                                            "& .MuiCardHeader-content": { background: '#F8FAFB', borderRadius: '8px', p: '4px' },
                                        }}
                                        avatar={
                                            <>

                                                <Avatar alt={"data?.name"} {...stringAvatar("Makapalad, Shanang")}
                                                    className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />

                                            </>
                                        }
                                        title={
                                            <Typography className=" font-extrabold text-18">
                                                Shanang Makapalad
                                            </Typography>
                                        }
                                        subheader={
                                            <>
                                                <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                    @superagent · Posted just now
                                                </Typography>
                                                <Typography className=" text-16 text-black">
                                                    This is my comment Example Wahahahah
                                                </Typography>
                                            </>
                                        }
                                    />
                                    <div className=" ml-44">
                                        <Button variant="text" className=" font-bold text-pi-blue-1">Reply</Button>
                                        {/* Reply */}
                                        <CardHeader
                                            className="p-0"
                                            sx={{
                                                alignItems: 'start',
                                                "& .MuiCardHeader-avatar": { mr: "8px", ml: '0px', mt: '5px' },
                                                "& .MuiCardHeader-content": { background: '#F8FAFB', borderRadius: '8px', p: '4px' },
                                            }}
                                            avatar={
                                                <>

                                                    <Avatar alt={"data?.name"} {...stringAvatar("Pranti, Makalaglag")}
                                                        className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />

                                                </>
                                            }
                                            title={
                                                <Typography className=" font-extrabold text-18">
                                                    Makalaglag Pranti
                                                </Typography>
                                            }
                                            subheader={
                                                <>
                                                    <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                        @superagent · Posted just now
                                                    </Typography>
                                                    <Typography className=" text-16 text-black">
                                                        <a className=" !no-underline !text-pi-blue-1">@superagent </a> This is my Reply Example sa agent
                                                    </Typography>
                                                </>
                                            }
                                        />
                                        <div className=" ml-44">
                                            <Button variant="text" className=" font-bold text-pi-blue-1">Reply</Button>
                                        </div>
                                    </div>
                                </div>
                                <CardHeader
                                    className="p-0"
                                    sx={{
                                        alignItems: 'start',
                                        "& .MuiCardHeader-avatar": { mr: "8px", ml: '0px', mt: '5px' },
                                        "& .MuiCardHeader-content": { background: '#F8FAFB', borderRadius: '8px', p: '4px' },
                                    }}
                                    avatar={
                                        <>

                                            <Avatar alt={"data?.name"} {...stringAvatar("Pranti, Makalaglag")}
                                                className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} />

                                        </>
                                    }
                                    title={
                                        <Typography className=" font-extrabold text-18">
                                            Makalaglag Pranti
                                        </Typography>
                                    }
                                    subheader={
                                        <>
                                            <Typography className=" line-clamp-1 text-14 text-pi-grey">
                                                @superagent · Posted just now
                                            </Typography>
                                            <Typography className=" text-16 text-black">
                                                This is my Comment Example
                                            </Typography>
                                        </>
                                    }
                                />
                                <div className=" ml-44">
                                    <Button variant="text" className=" font-bold text-pi-blue-1">Reply</Button>
                                </div>
                            </Stack>

                        </Card>

                    </Stack>
                }

            />
        </>
    );
}

export default PublicCommunityDetails;
