import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import * as yup from "yup";
import reducer from 'app/main/apps/blogs/store';
import withReducer from "app/store/withReducer";
import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { updateAgent, checkBlogAuthorName } from "../../store/manageAgentsSlice";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled, lighten } from '@mui/material/styles';
import { showMessage } from "app/store/fuse/messageSlice";
import BlogManageAuthorsProfile from "./ManageAgentsProfile";
import Select from "@mui/material/Select";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import AgentProfileImage from "../../modal/AgentProfileImage";
import AgentProfileBannerImage from "../../modal/AgentProfileBannerImage";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from '@mui/material/FormHelperText';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import MenuItem from '@mui/material/MenuItem';
import format from 'date-fns/format';

const FormControlStyled = styled(FormControl)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff !important",
        border: "1px solid #141F2C",
        borderRadius: "8px"
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
        border: "unset",
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none",
    },

    "& .MuiInputLabel-root": {
        top: "0px",
        fontSize: "15px",
        color: '#48525C'
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: '#48525C'
    },
    "& .MuiSelect-select.MuiSelect-filled": {
        paddingTop: '20px'
    },
    "& .MuiSelect-nativeInput": {
        paddingTop: '3px'
    },
}));
const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        // height: 56,
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none"
    },

    "& .MuiInputLabel-root": {
        top: "0px",
    },
}));

const Root = styled('div')(({ theme }) => ({
    background: theme.palette.primary,
    color: theme.palette.primary.contrastText,

    '& .bg-icon': {
        background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${lighten(
            theme.palette.primary.dark,
            0.1
        )} 100%)`,
        color: theme.palette.primary.contrastText,
    },

}));

const defaultValues = {
    id: null,
    firstName: "",
    middleName: "",
    lastName: "",
    about: "",
    featuredImageId: "",
    images: [],
    bannerImages: []

};

function EditAgentsModal(props) {
    const dispatch = useDispatch();

    /**
     * Form Validation Schema
     */
    const schema = yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        country: yup.string().required("Country is required"),
        state: yup.string().required("State is required"),
    });

    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    const {
        control,
        formState,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        trigger
    } = methods;

    const [states, setStates] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { isValid, dirtyFields, errors } = formState;
    const [origData, setOrigData] = useState({});
    const n_id = watch("id");
    const wCountry = watch("country");

    useEffect(() => {
        console.log(CountryRegionData, wCountry, 'console')
        if (wCountry) {
            var i = CountryRegionData.filter((i) => {

                return i[0] == wCountry;
            });
            console.log('i', i);
            setStates((i[0][2].split('|')));
        }
    }, [wCountry]);

    useEffect(() => {
        if (openDialog) {
            console.log(props.item, 'origData');
            if (props.item) {
                setValue("id", props.item["userId"])
                setValue("firstName", props.item["firstName"])
                setValue("middleName", props.item["middleName"])
                setValue("lastName", props.item["lastName"])
                setValue("gender", props.item["gender"])
                setValue("city", props.item["city"])
                setValue("birthdate", props.item["birthdate"])
                setValue("state", props.item["state"])
                setValue("country", props.item["country"])
                setValue("about", props.item["about"])
                setValue("featuredImageId", props.item["featuredImageId"])
                setValue("featuredImagePath", props.item["featuredImagePath"])
                setValue("images", props.item["images"] ? [props.item["images"]] : [])
                setValue("bannerImages", props.item["bannerImages"] ? [props.item["bannerImages"]] : [])
            }
        } else {
            setTimeout(() => {
                reset(defaultValues);
            }, 500);
        }

    }, [openDialog]);

    function handleOpenDialog() {
        setOpenDialog(true);
    }

    function handleCloseDialog() {
        setOpenDialog(false);
    }

    async function handleSave() {
        setIsSaving(true);
        const result = await trigger(["firstName"]);
        if (props.module === 'edit' && JSON.stringify(origData) == JSON.stringify(getValues())) {
            dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );
            setIsSaving(false);

        } else {
            const data = getValues();
            if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length || data.state == "") {
                setIsSaving(false);
                return dispatch(
                    showMessage({
                        message: "Please fill in the required fields.",
                        autoHideDuration: 5000, //ms
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "right",
                        },
                        variant: "error",
                    })
                );
            } else {
                const data = getValues();
                data.birthdate = format(new Date(data.birthdate), 'yyyy-MM-dd');
                const mdata = data;
                dispatch(updateAgent(mdata)).then((action) => {
                    setOpenDialog(false);
                    setIsSaving(false);
                    props.loadData();

                });
            }
        }
    }
    const color = "#2EB7D7";

    return (
        <FormProvider {...methods}>

            <Root>
                <Tooltip title={"Edit " + getValues("firstName")} arrow>
                    <IconButton
                        onClick={handleOpenDialog}
                        size="large"
                        edge={false}
                    >
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>

                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="form-dialog-title"
                    scroll="body"
                    sx={{

                        '& .MuiPaper-root': {
                            width: "100%",
                        },
                    }}
                >
                    <AppBar position="static" elevation={0}>
                        <Toolbar className="flex w-full">
                            <Typography variant="subtitle1" color="inherit">
                                {'Edit Agent - ' + props.item["lastName"] + ', ' + props.item["firstName"]}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <form
                        noValidate
                        className="flex flex-col"
                    >
                        <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0 max-h-640' }}>
                            <FormControl
                                className="flex w-full  ml-0"
                                variant="outlined"
                            >
                                <AgentProfileImage data={props.item}/>
                            </FormControl>
                            <Divider />
                            <FormControl
                                className="flex w-full  ml-0"
                                variant="outlined"
                            >
                                <AgentProfileBannerImage />
                            </FormControl>
                            <Divider />
                            <Typography className="mb-16 text-18 font-bold">
                                User Information
                            </Typography>
                            <Typography className="mt-8" color="text.primary">First Name <span className="text-red-500">*</span></Typography>
                            <Controller
                                name="firstName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className=" mb-16"
                                        id="firstName"
                                        variant="outlined"
                                        error={!!errors.name}
                                        helperText={errors?.name?.message}
                                        required
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: "40px"
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Typography className="mt-8" color="text.primary">Middle Name</Typography>
                            <Controller
                                name="middleName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className=" mb-16"
                                        id="middleName"
                                        variant="outlined"
                                        error={!!errors.name}
                                        helperText={errors?.name?.message}
                                        required
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: "40px"
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Typography className="mt-8" color="text.primary">Last Name <span className="text-red-500">*</span></Typography>
                            <Controller
                                name="lastName"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className=" mb-16"
                                        id="lastName"
                                        variant="outlined"
                                        error={!!errors.name}
                                        helperText={errors?.name?.message}
                                        required
                                        fullWidth
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: "40px"
                                            }
                                        }}
                                    />
                                )}
                            />
                            <FormControl
                                className="flex w-full max-w-fit"
                                variant="outlined">
                                <Controller
                                    control={control}
                                    name="birthdate"
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                value={field.value}
                                                onChange={(date) => field.onChange(date)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={<Typography className="text-15" color="text.primary">Birthday</Typography>}
                                                        variant="filled"
                                                        InputLabelProps={{
                                                            sx: {
                                                                color: "#48525C",
                                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                [`&.${inputLabelClasses.shrink}`]: {
                                                                    fontSize: '14px',
                                                                    color: !!errors.birthdate ? "red" : "#141F2C"
                                                                }
                                                            }
                                                        }}
                                                        sx={{
                                                            svg: { color },
                                                            "& .MuiInputBase-root": {
                                                                height: 56
                                                            }
                                                        }}
                                                        placeholder="MM/DD/YYYY"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            readOnly: true,
                                                        }}
                                                    />
                                                )}
                                                // Set minDate to today
                                                maxDate={maxDate} // This will prevent selection of dates after 18 years ago
                                                shouldDisableDate={(date) => date.toDateString() === new Date().toDateString()} // Disable today's date
                                                onChangeRaw={() => {
                                                    setFieldTouched(field.name, true, true);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </FormControl>
                            <FormControl
                                className="flex w-full max-w-fit"
                                variant="outlined">
                                <Controller
                                    render={({ field }) => (
                                        <div className="">
                                            <Typography id="demo-row-radio-buttons-group-label" className="text-15" color="text.primary">
                                                Gender <span className="text-red-500">*</span>
                                            </Typography>
                                            <RadioGroup {...field} row>
                                                <FormControlLabel
                                                    value="Male"
                                                    className="text-15"
                                                    control={<Radio sx={{
                                                        '&.Mui-checked': {
                                                            color: color,
                                                        },
                                                    }} />}
                                                    label="Male"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            fontSize: 15
                                                        }
                                                    }}
                                                />
                                                <FormControlLabel
                                                    value="Female"
                                                    control={<Radio sx={{
                                                        '&.Mui-checked': {
                                                            color: color,
                                                        },
                                                    }} />}
                                                    label="Female"
                                                    sx={{
                                                        "& .MuiTypography-root": {
                                                            fontSize: 15
                                                        }
                                                    }}
                                                />
                                            </RadioGroup>
                                        </div>
                                    )}
                                    name="gender"
                                    control={control}
                                />
                            </FormControl>
                            <Controller
                                name="about"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">About yourself</Typography>}
                                            placeholder="Short description about yourself"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 'auto !important'
                                                }
                                            }}
                                            {...field}
                                            className=" "
                                            error={!!errors.about}
                                            helperText={errors?.about?.message}
                                            id="about"
                                            fullWidth
                                            multiline
                                            maxRows={6}
                                            minRows={3}
                                        />
                                    </div>

                                )}
                            />
                            <Typography className=" text-18 font-bold mt-16">
                                Address
                            </Typography>
                            <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                    <FormControlStyled fullWidth variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.email ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        sx={{
                                            svg: { color },
                                            "& .MuiInputBase-root": {
                                                height: 56
                                            }
                                        }}
                                        placeholder="Choose your country"

                                    >
                                        <InputLabel id="country-label">Country <span className="text-red-500">*</span></InputLabel>
                                        <Select
                                            labelId="country-label"
                                            label="Country"
                                            {...field}
                                            id="type-select"
                                            required
                                            error={!!errors.country}
                                        >
                                            {CountryRegionData.map((type) => (
                                                <MenuItem value={type[0]} key={type[1]} onClick={() => {
                                                    setValue("country", type[0]);
                                                    setValue("state", []);
                                                }}>
                                                    {type[0] + ' (' + type[1] + ')'}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControlStyled>
                                )}
                            />
                            <FormHelperText className="mt-16" error={!!errors.type}>{errors?.type?.message}</FormHelperText>
                            <Controller
                                className="mt-16"
                                name="state"
                                control={control}
                                render={({ field }) => (
                                    <FormControlStyled fullWidth variant="filled"
                                        InputLabelProps={{
                                            sx: {
                                                color: "#48525C",
                                                fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                [`&.${inputLabelClasses.shrink}`]: {
                                                    fontSize: '14px',
                                                    color: !!errors.email ? "red" : "#141F2C"
                                                }
                                            }
                                        }}
                                        sx={{
                                            svg: { color },
                                            "& .MuiInputBase-root": {
                                                height: 56
                                            }
                                        }}
                                        placeholder="Choose your country">
                                        <InputLabel id="state-label">State <span className="text-red-500">*</span></InputLabel>
                                        <Select
                                            labelId="state-label"
                                            label="Country"
                                            {...field}
                                            id="type-select"
                                            required
                                            error={!!errors.state}
                                        >
                                            {states.map((type) => (
                                                <MenuItem value={type.split('~')[0]} key={type.split('~')[0]} onClick={() => {
                                                    setValue("state", type.split('~')[0]);
                                                }}>
                                                    {type.split('~')[0]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControlStyled>
                                )}
                            />
                            <FormHelperText className="mt-16" error={!!errors.state}>{errors?.state?.message}</FormHelperText>
                            <Controller
                                className="mt-16"
                                name="city"
                                control={control}
                                render={({ field }) => (
                                    <div className="flex">
                                        <TextFieldStyled
                                            label={<Typography className="text-15" color="text.primary">City </Typography>}
                                            placeholder="Enter your City"
                                            variant="filled"
                                            InputLabelProps={{
                                                sx: {
                                                    color: "#48525C",
                                                    fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                    [`&.${inputLabelClasses.shrink}`]: {
                                                        fontSize: '14px',
                                                        color: !!errors.email ? "red" : "#141F2C"
                                                    }
                                                }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    height: 56
                                                }
                                            }}
                                            {...field}
                                            className=""
                                            error={!!errors.city}
                                            required
                                            helperText={errors?.city?.message}
                                            id="city"
                                            fullWidth
                                        />
                                    </div>
                                )}
                            />
                        </DialogContent>

                        <DialogActions className="justify-center px-8 py-16">
                            <div className="px-16">
                                <LoadingButton
                                    className="rounded-md mr-5"
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={isSaving}
                                    onClick={handleSave}
                                >
                                    Save
                                </LoadingButton>
                                <Button
                                    className="rounded-md ml-5"
                                    variant="outlined"
                                    type="button"
                                    onClick={handleCloseDialog}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </DialogActions>
                    </form>
                </Dialog>
            </Root>
        </FormProvider>
    );
}

export default withReducer("agentsApp", reducer)(EditAgentsModal);
