import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from "@mui/material/styles";
import * as React from "react";
import Typography from "@mui/material/Typography";
import FuseScrollbars from "@fuse/core/FuseScrollbars/FuseScrollbars";
import { Badge, Card, CardHeader, Stack, Avatar, useMediaQuery, Box, Grid, Button, List, ListItem, ListItemText, ListItemButton, ListItemIcon, Divider, ListSubheader, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import withReducer from 'app/store/withReducer';
import reducer from 'app/main/apps/agent/store';
import { getSettings, updateSite } from './store/settingsSlice';
import { useEffect, useState } from "react";
import { useForm, useFormContext, Controller, FormProvider } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import SystemLogo from "./template/SystemLogo";
import { showMessage } from "app/store/fuse/messageSlice";
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import ManageSiteHeader from "./ManageSiteHeader";

const Root = styled(FusePageCarded)(({ theme }) => ({
    '& .FusePageCarded-header': {
        minHeight: 72,
        height: 72,
        alignPatrons: 'center',
        [theme.breakpoints.up('sm')]: {
            minHeight: 136,
            height: 136,
        },
    },
    '& .FusePageCarded-content': {
        display: 'flex',
        width: "100%",
        margin: "auto"
    },
    '& .FusePageCarded-contentCard': {
        overflow: 'hidden',
        marginBottom: "3rem !important",
        borderRadius: "20px",
        width: "100%",
        maxWidth: "600px",
        margin: "auto"
    },
    '& .FusePageCarded-toolbar': {
        overflow: "auto",
        height: 110,
    },
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        backgroundColor: "#ffff",
        border: "1px solid #E9E9E9",
        // height: 56,
        fontSize: '16px',
        [theme.breakpoints.up("lg")]: {
            height: 57,
            fontSize: '18px',
        },
        // borderRadius:"8px"
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },
    "& .MuiInputLabel-asterisk": {
        display: "none"
    },

    "& .MuiInputLabel-root": {
        top: "0px",
    },
}));

const defaultValues = {
    siteName: "",
    email: "",
    address: "",
    phone: "",
    copyright: "",
    images: [],
};

function ManageSite(props) {
    const user = useSelector(({ auth }) => auth.user);

    const mainTheme = useSelector(selectMainTheme);
    const [isSaving, setIsSaving] = useState(false);

    const schema = yup.object().shape({
        // siteName: yup.string().required("Site Name is required"),
        email: yup
            .string()
            .required("Email is required")
            .email("Invalid email format")
            .test('is-valid-domain', 'Email domain is not allowed', (value) => {
                if (!value) return true; // Skip this test if email is empty (it will be caught by required())
                const domain = value.split('@')[1];
                return !['example.com', 'test.com'].includes(domain); // Disallow specific domains
            }),
        copyright: yup.string().required("Copyright is required"),
        address: yup.string().required("Address is required"),
        phone: yup
            .string()
            .required("Phone number is required")
            .matches(/^[0-9]+$/, "Phone number must be only digits")
            .test('is-valid', 'Phone number invalid format', (value) => {
                if (!value) return true; // Skip this test if phone is empty (it will be caught by required())
                // You can add more specific validation logic here
                // For example, checking for specific country codes or formats
                return /^(\+\d{1,3}[- ]?)?\d{10,14}$/.test(value);
            }),
    });
    const methods = useForm({
        mode: "onChange",
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });
    const { control, formState, setValue, watch, trigger, getValues } = methods;
    const { isValid, dirtyFields, errors } = formState;

    async function handleupdateSite() {
        setIsSaving(true);
        const result = await trigger(["siteName", "email", "address", "phone", "copyright"]);

        if ((_.isEmpty(dirtyFields) && !isValid) || Object.keys(errors).length) {
            return dispatch(
                showMessage({
                    message: "Please fill in the required fields.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        }

        if (Object.keys(dirtyFields).length === 0) {
            dispatch(
                showMessage({
                    message: "No changes have been made.",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "warning",
                })
            );

        } else {
            dispatch(updateSite(getValues())).then((action) => {
                setIsSaving(false);
            });
        }
    }

    const dispatch = useDispatch();
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getSettings()).then((action) => {
            const pData = action.payload;
            setValue("email", pData?.email);
            setValue("phone", pData?.phone);
            setValue("address", pData?.address);
            setValue("siteName", pData?.siteName);
            setValue("copyright", pData?.copyright);
            setValue("images", pData ? [pData] : []);
            setIsSaving(false);
        });
    }, [dispatch])


    const color = "#2EB7D7";
    return (
        <FormProvider {...methods}>
            <>
                <Root
                    header={<ManageSiteHeader />}
                    content={
                        <Stack direction={'column'} gap={{ lg: 4, md: 2, sm: 2, xs: 2 }} className=" px-0 m-auto w-full">
                            <Card className="p-24" elevation={0}>
                                <Stack gap={1.5}>
                                    {/* <FormControl
                                        className="flex w-full  ml-0"
                                        variant="outlined"
                                    >
                                        <SystemLogo />
                                    </FormControl>
                                    <Typography className=" text-18 font-bold mt-16">
                                        Site Name:
                                    </Typography>
                                    <Controller
                                        name="siteName"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex">
                                                <TextFieldStyled
                                                    label={<Typography className="text-15" color="text.primary">Site Name <span className="text-red-500">*</span></Typography>}
                                                    placeholder="Enter your first name"
                                                    variant="filled"
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: "#48525C",
                                                            fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                            [`&.${inputLabelClasses.shrink}`]: {
                                                                fontSize: '14px',
                                                                color: !!errors.email ? "red" : "#141F2C"
                                                            }
                                                        }
                                                    }}
                                                    {...field}
                                                    error={!!errors.siteName}
                                                    required
                                                    helperText={errors?.siteName?.message}
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            height: 56
                                                        }
                                                    }}
                                                    id="siteName"
                                                    fullWidth
                                                />
                                            </div>
                                        )}
                                    /> */}
                                    <Typography className=" text-18 font-bold mt-16">
                                        Email:
                                    </Typography>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex">
                                                <TextFieldStyled
                                                    label={<Typography className="text-15" color="text.primary">Email <span className="text-red-500">*</span></Typography>}
                                                    placeholder="Enter your first name"
                                                    variant="filled"
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: "#48525C",
                                                            fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                            [`&.${inputLabelClasses.shrink}`]: {
                                                                fontSize: '14px',
                                                                color: !!errors.email ? "red" : "#141F2C"
                                                            }
                                                        }
                                                    }}
                                                    {...field}
                                                    error={!!errors.email}
                                                    required
                                                    helperText={errors?.email?.message}
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            height: 56
                                                        }
                                                    }}
                                                    id="email"
                                                    fullWidth
                                                />
                                            </div>
                                        )}
                                    />
                                    <Typography className=" text-18 font-bold mt-16">
                                        Address:
                                    </Typography>
                                    <Controller
                                        name="address"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex">
                                                <TextFieldStyled
                                                    label={<Typography className="text-15" color="text.primary">Address <span className="text-red-500">*</span></Typography>}
                                                    placeholder="Enter your first name"
                                                    variant="filled"
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: "#48525C",
                                                            fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                            [`&.${inputLabelClasses.shrink}`]: {
                                                                fontSize: '14px',
                                                                color: !!errors.email ? "red" : "#141F2C"
                                                            }
                                                        }
                                                    }}
                                                    {...field}
                                                    error={!!errors.address}
                                                    required
                                                    helperText={errors?.address?.message}
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            height: 56
                                                        }
                                                    }}
                                                    id="address"
                                                    fullWidth
                                                />
                                            </div>
                                        )}
                                    />
                                    <Typography className=" text-18 font-bold mt-16">
                                        Phone:
                                    </Typography>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex">
                                                <TextFieldStyled
                                                    label={<Typography className="text-15" color="text.primary">Phone <span className="text-red-500">*</span></Typography>}
                                                    placeholder="Enter your first name"
                                                    variant="filled"
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: "#48525C",
                                                            fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                            [`&.${inputLabelClasses.shrink}`]: {
                                                                fontSize: '14px',
                                                                color: !!errors.email ? "red" : "#141F2C"
                                                            }
                                                        }
                                                    }}
                                                    {...field}
                                                    error={!!errors.phone}
                                                    required
                                                    helperText={errors?.phone?.message}
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            height: 56
                                                        }
                                                    }}
                                                    id="phone"
                                                    fullWidth
                                                />
                                            </div>
                                        )}
                                    />
                                    <Typography className=" text-18 font-bold mt-16">
                                        Copyright:
                                    </Typography>
                                    <Controller
                                        name="copyright"
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex">
                                                <TextFieldStyled
                                                    label={<Typography className="text-15" color="text.primary">Copyright <span className="text-red-500">*</span></Typography>}
                                                    placeholder="Enter your first name"
                                                    variant="filled"
                                                    InputLabelProps={{
                                                        sx: {
                                                            color: "#48525C",
                                                            fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                            [`&.${inputLabelClasses.shrink}`]: {
                                                                fontSize: '14px',
                                                                color: !!errors.email ? "red" : "#141F2C"
                                                            }
                                                        }
                                                    }}
                                                    {...field}
                                                    error={!!errors.copyright}
                                                    required
                                                    helperText={errors?.copyright?.message}
                                                    sx={{
                                                        "& .MuiInputBase-root": {
                                                            height: 56
                                                        }
                                                    }}
                                                    id="copyright"
                                                    fullWidth
                                                />
                                            </div>
                                        )}
                                    />
                                    <Button onClick={handleupdateSite} variant="contained" color="primary"
                                        className=" mt-16 px-24 py-8 text-16 rounded-full font-bold w-full bg-pi-yellow-green">
                                        UPDATE
                                    </Button>

                                </Stack>
                            </Card>
                        </Stack>

                    }
                />
            </>
        </FormProvider >
    );
}

export default withReducer('agentApp', reducer)(ManageSite);