import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import FuseLoading from "@fuse/core/FuseLoading";
import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import { inputLabelClasses } from "@mui/material/InputLabel";
import AgentJwtService from "app/services/agent/AgentJwtService";
import { showMessage } from "app/store/fuse/messageSlice";
import FormHelperText from '@mui/material/FormHelperText';
import { saveComment, getComments, deletePublicComment } from "./store/commentsSlice";
import { deletePublicAttachment } from "app/services/store/PublicUploadsSlice";

import {
    Card,
    CardContent,
    Stack,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Avatar,
    Badge,
    CardHeader,
    IconButton,
    Container,
    Button,
    Divider,
    Icon,
    Tab,
    TextField,
    InputAdornment,
    Typography,
    Menu,
    MenuItem
} from "@mui/material";
import DateTimeDisplay from "../DateTimeDisplay";
import AttachmentUploader from "../AttachmentUploader";
import randomString from "app/services/randomString";
import AttachmentContainer from "../AttachmentContainer";
import ReactPlayer from "react-player";
import MoreVert from '@mui/icons-material/MoreVert';
import CommentAction from "./content/CommentAction";
import CommentsSkeletonLoader from "./components/CommentsSkeletonLoader";
import { Link } from "react-router-dom";
import { addNotification } from "app/fuse-layouts/shared-components/quickPanel/store/dataSlice";
import notification from "../notification";
import moment from "moment";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        fontFamily: "Nunito, sans-serif !important",
        paddingTop: "6px !important",
        paddingBottom: "6px !important",
        backgroundColor: "#F8FAFB",
        border: "1px solid #E9E9E9",
        fontSize: '14px',
        borderRadius: '23px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '14px',
        },
    },
    "& .MuiInputLabel-root": {
        fontSize: '14px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '14px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },

}));

const DisabledPlayer = styled('div')(({ theme }) => ({
    position: "relative",

    "& .disable": {
        position: "relative",
        height: 80,
        width: 80,
    },
    "& .react-player": {
        position: "absolute",
        top: 0,
        left: 0,
    },


}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 34,
    height: 34,
}));

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}

const BackgroundAvatar = styled(Avatar)(({ theme }) => ({
    borderRadius: "unset",
    width: 60,
    height: 60,
    "& img": {
        objectFit: "contain",
    },

}));

function CommentsReplies(props) {
    const dispatch = useDispatch();
    const token = AgentJwtService.getDecodedAccessToken();
    const { reply, setReply } = props;
    const { data, moduleId, moduleType, mission } = props;

    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingASection, setLoadingASection] = useState(false);
    const [attachmentTempId, setAttachmentTempId] = useState(randomString())
    const [attachmentData, setAttachmentData] = useState([])
    const [attachmentEditData, setAttachmentEditData] = useState([])
    const [isEditId, setIsEditId] = useState(null);

    const handleDeleteAttachment = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentData.filter(item => item.id !== id);
            setAttachmentData(updatedAttachments);
        })
    };

    const handleDeleteAttachmentEdit = (id) => {
        dispatch(deletePublicAttachment({ id: id })).then(() => {
            const updatedAttachments = attachmentEditData.filter(item => item.id !== id);
            setAttachmentEditData(updatedAttachments);
        })
    };

    const handleDeleteComment = (id) => {
        setLoadingASection(true);
        dispatch(deletePublicComment({ id: id })).then(() => {
            const updatedComments = comments.filter(item => item.id !== id);
            setComments(updatedComments);
            setLoadingASection(false);
        })
    };

    const setEditComment = (comment) => {
        setValue("editComments", comment)
    };

    const setCommentById = (id, comData) => {
        const updatedArray = comments.map(item => {
            if (item.id === id) {
                return { ...item, comments: comData };
            }
            return item;
        });

        setComments(updatedArray);
    }

    const defaultValues = {
        agentId: token.agentId,
        moduleId: moduleId,
        moduleType: moduleType,
        comments: "",
        editComments: "",
        commentType: "",
    };

    const schema = yup.object().shape({
        comments: yup.string().required("Reply is required."),
        editComments: yup.string().required("Reply is required."),
        reply: yup.string().required("Reply is required"),
    });

    function fetchComments() {
        dispatch(getComments({ moduleId: moduleId, moduleType: moduleType })).then((action) => {
            setLoading(false);
            setComments(action.payload);
            setLoadingASection(false)
        })
    }

    useEffect(() => {
        fetchComments();
    }, [])

    function createNotification(obj) {
        dispatch(addNotification(obj));
    }

    const {
        control,
        formState,
        handleSubmit,
        reset,
        setValue,
        watch,
        getValues,
        trigger,
    } = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { isValid, dirtyFields, errors } = formState;

    async function handleComment() {
        var fData = getValues();
        console.log(fData, data, 'ALKSJDNAKSJDNASDJANSDKJASNKDJN');
        if (fData.comments == '' && attachmentData.length == 0) {
            return dispatch(
                showMessage({
                    message: "Please write a reply or add attachments",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        } else {
            fData.commentType = 1;
            fData.fileId = attachmentTempId;
            dispatch(saveComment(fData)).then((action) => {
                if (action.payload.status == 'success' && moduleType === "missions" && token.id != data.agent.userId) {
                    if (data.agent.userId == mission.agent.userId) {
                        createNotification({
                            uid: token.id,
                            uname: token.data.displayName,
                            upicPath: token.data.profilePicPath,
                            userId: data.agent.userId,
                            userName: data.agent.flName,
                            link: 'public/mission-details/' + data.moduleId,
                            message: notification({
                                uname: token.data.displayName,
                                username: data.agent.flName
                            }, 'mission', 'reply'),
                            module: 'mission',
                            time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                            status: "Unread"
                        })
                    } else {
                        createNotification({
                            uid: token.id,
                            uname: token.data.displayName,
                            upicPath: token.data.profilePicPath,
                            userId: data.userId,
                            userName: data.agentName,
                            link: 'public/mission-details/' + data.moduleId,
                            message: notification({
                                uname: token.data.displayName,
                                username: data.agent.flName
                            }, 'comment', 'reply'),
                            module: 'mission',
                            time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                            status: "Unread"
                        })

                    }
                    // createNotification({
                    //     uid: token.id,
                    //     uname: token.data.displayName,
                    //     upicPath: token.data.profilePicPath,
                    //     userId: mission.agent.userId,
                    //     userName: mission.agent.flName,
                    //     link: 'public/mission-details/' + data.moduleId,
                    //     message: notification({
                    //         uname: token.data.displayName,
                    //         username: mission.agent.flName
                    //     }, 'comment', 'reply'),
                    //     module: 'mission',
                    //     time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                    //     status: "Unread"
                    // })
                }
                if (action.payload.status == 'success' && moduleType === "blogs") {
                    createNotification({
                        uid: token.id,
                        uname: token.data.displayName,
                        upicPath: token.data.profilePicPath,
                        userId: data.userId,
                        userName: data.agentName,
                        link: 'public/blog?id=' + data.moduleId,
                        message: notification({
                            uname: token.data.displayName,
                            username: data.agent.flName
                        }, 'comment', 'reply'),
                        module: 'blogs',
                        time: moment().format('yyyy-MM-DD[T]HH:mm:ss'),
                        status: "Unread"
                    })
                }
                setLoadingASection(true);
                reset(defaultValues);
                setAttachmentTempId(randomString());
                setAttachmentData([]);
                fetchComments();
            });
        }
    }

    async function handleEditComment(id, attchmentId) {
        var fData = getValues();
        if (fData.editComments == '' && attachmentEditData.length == 0) {
            return dispatch(
                showMessage({
                    message: "Please write a reply or add attachments",
                    autoHideDuration: 5000, //ms
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    variant: "error",
                })
            );
        } else {
            fData.id = id;
            fData.commentType = 1;
            fData.fileId = attchmentId;
            dispatch(saveComment(fData)).then(function () {
                setIsEditId(null)
                reset(defaultValues);
                setCommentById(id, fData.editComments)
            });
        }
    }

    return (
        <div>
            <form name="commentForm" noValidate>

                {
                    loading ? (<CommentsSkeletonLoader />) :

                        comments.map((item, key) => (
                            <div>
                                <CardHeader
                                    className=" !px-0 !py-0"
                                    sx={{
                                        alignItems: 'flex-start',
                                        "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                        "& .MuiAvatar-root": { border: '2.5px solid #314159' },
                                        "& .MuiCardHeader-content": {
                                            alignSelf: "start",
                                            background: "#F8FAFB",
                                            width: "100%",
                                            borderRadius: "15px",
                                            padding: "8px",
                                            flex: "unset",
                                        },
                                    }}
                                    avatar={
                                        <>

                                            <Badge
                                                className="mt-6"
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                sx={{
                                                    '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                        borderRadius: '50%',
                                                        backgroundColor: '#314159',
                                                        padding: '0px',
                                                        paddingTop: '2.8px',
                                                        paddingleft: '4.5px',
                                                        bottom: '10px',
                                                        minWidth: '20px',
                                                        height: '20px'
                                                    }
                                                }}
                                                badgeContent={
                                                    <span
                                                    >
                                                        <Icon className=" text-white text-11">chat_bubble</Icon>

                                                    </span>
                                                }
                                            >
                                                {/* <AttachmentSvs module={"at-6"} path={item.agent?.agentPhotoUrl ? (<img src={item.agentPhotoUrl} />) : null} data={item.agentName} /> */}
                                                <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${item.agent?.userId}`}>
                                                    {/* {item.agentPhotoUrl ? (<Avatar className=" uppercase text-16" style={{ height: '46px', width: '46px' }} src={item.agentPhotoUrl} />) : (<Avatar className=" uppercase text-16" style={{ height: '46px', width: '46px' }} src="assets/images/agent-pi-circle-yellow-gray-white.png" />)} */}
                                                    {(<Avatar className=" uppercase text-16" style={{ height: '46px', width: '46px' }} src={item.agentPhotoUrl} />)}
                                                </Link>
                                            </Badge>
                                        </>
                                    }
                                    title={
                                        <Typography className="!font-bold !text-16 !font-nunito">
                                            <Link style={{ textDecoration: 'none' }} to={`/agentprofile?agentId=${item.agent?.userId}`}>
                                                {"Agent " + item.agentName}
                                            </Link>
                                        </Typography>
                                    }
                                    subheader={
                                        <>
                                            {isEditId == item.id ? (
                                                <>
                                                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                                        <Controller
                                                            name="editComments"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextFieldStyled
                                                                    {...field}
                                                                    id="editComments"
                                                                    className="w-full"
                                                                    size="medium"
                                                                    placeholder="Write a reply..."
                                                                    variant="filled"
                                                                    InputLabelProps={{
                                                                        sx: {
                                                                            color: "#48525C",
                                                                            fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                                            [`&.${inputLabelClasses.shrink}`]: {
                                                                                fontSize: '14px',
                                                                                color: !!errors.username ? "red" : "#141F2C"
                                                                            }
                                                                        }
                                                                    }}
                                                                    fullWidth
                                                                    multiline
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        endAdornment: (
                                                                            <InputAdornment
                                                                                position="end"
                                                                                className="!h-full !max-h-max !justify-between !pr-4"
                                                                            >
                                                                                <AttachmentUploader module="blogs" id={item.fileId} attachmentData={attachmentEditData} setAttachmentData={setAttachmentEditData} />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}

                                                                />
                                                            )}
                                                        />
                                                        <IconButton
                                                            onClick={() => { handleEditComment(item.id, item.fileId) }}
                                                            aria-label="toggle password visibility"
                                                            title="Submit"
                                                            edge="end"
                                                            className="  !p-5"
                                                        >
                                                            <Icon className=" !text-20" size="large">send</Icon>
                                                        </IconButton>
                                                    </Stack>
                                                    {/* <FormHelperText error={!!errors.editComments}>{errors?.editComments?.message}</FormHelperText> */}
                                                    <Stack direction={'row'} gap={1} className="mt-8" flexWrap={'wrap'}>
                                                        {
                                                            attachmentEditData.length > 0 &&
                                                            attachmentEditData.map((itemAttchment) => (
                                                                <Stack direction={'row'} gap={1.5} flexWrap={'wrap'}>
                                                                    <div
                                                                        style={{ width: 80, height: 80 }}
                                                                        className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey relative"
                                                                    >

                                                                        <Icon className='text-red-600 cursor-pointer absolute top-0 right-5 z-20'
                                                                            onClick={() => { handleDeleteAttachmentEdit(itemAttchment.id) }}
                                                                        >delete</Icon>
                                                                        {
                                                                            (() => {
                                                                                switch (itemAttchment.switchType) {
                                                                                    case "youtube":
                                                                                        return <div className="image-container">
                                                                                            <DisabledPlayer>
                                                                                                <div className=" z-10 disable"></div>
                                                                                                <ReactPlayer
                                                                                                    light={true}
                                                                                                    playing={false}
                                                                                                    className="react-player rounded-lg overflow-hidden "
                                                                                                    url={itemAttchment.srcUrl}
                                                                                                    height={80}
                                                                                                    width={80}
                                                                                                />
                                                                                            </DisabledPlayer>
                                                                                        </div>;
                                                                                    case "video":
                                                                                        return <div className="image-container">
                                                                                            <video autoPlay={false} className="blog">
                                                                                                <source
                                                                                                    type={itemAttchment.fileType}
                                                                                                    src={itemAttchment.srcUrl}
                                                                                                />
                                                                                            </video>
                                                                                        </div>;
                                                                                    default:
                                                                                        return <div className="image-container2">
                                                                                            <img

                                                                                                className="max-w-none w-full h-full object-cover"
                                                                                                src={itemAttchment.srcUrl}
                                                                                            />
                                                                                        </div>;
                                                                                }
                                                                            })()
                                                                        }
                                                                    </div>
                                                                </Stack>
                                                            ))
                                                        }

                                                    </Stack>
                                                </>
                                            ) : (
                                                <div>
                                                    <>
                                                        <div className=" !font-500 !text-15 !font-nunito">
                                                            Replied <DateTimeDisplay dateTime={item.dateCreated} />
                                                        </div>
                                                        <p className=" !text-pi-black !font-500 !text-16 mt-3 ">
                                                            {item.comments}
                                                        </p>
                                                        <div>
                                                            {!loadingASection && (
                                                                <AttachmentContainer id={item.fileId} />
                                                            )}
                                                        </div>
                                                    </>
                                                </div>
                                            )
                                            }
                                        </>
                                    }
                                    action={
                                        isEditId != item.id && (<CommentAction data={item} id={item.id} attachmentId={item.fileId} handleDeleteComment={handleDeleteComment} setAttachmentData={setAttachmentEditData} setIsEditId={setIsEditId} setEditComment={setEditComment} />)
                                    }
                                />

                                <Container className="pl-56 pr-0 pt-10">
                                    {isEditId == item.id && (
                                        <Button variant="Text" className="p-b-10 !text-14 !text-pi-blue-1 !capitalize !font-nunito !p-0 !w-fit !min-w-fit" onClick={() => setIsEditId(null)}>Cancel</Button>
                                    )}
                                </Container>
                            </div>
                        ))

                }
                {reply === data.id && (
                    <>
                        <CardHeader
                            className=" !p-0"
                            sx={{
                                alignItems: 'flex-start',
                                "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                                "& .MuiAvatar-root": { border: '2.5px solid #314159' },

                            }}
                            avatar={
                                <>

                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        sx={{
                                            '& .MuiBadge-anchorOriginBottomRightCircular': {
                                                borderRadius: '50%',
                                                backgroundColor: '#314159',
                                                padding: '0px',
                                                paddingTop: '2.8px',
                                                paddingleft: '4.5px',
                                                bottom: '10px',
                                                minWidth: '20px',
                                                height: '20px'
                                            }
                                        }}
                                        badgeContent={
                                            <span
                                            >
                                                <Icon className=" text-white text-11">chat_bubble</Icon>

                                            </span>
                                        }
                                    >
                                        {/* {token.data.photoURL ? (<Avatar className=" uppercase text-16" style={{ height: '46px', width: '46px' }} src={token.data.photoURL} />) : (<Avatar className=" uppercase text-16" style={{ height: '46px', width: '46px' }} src="assets/images/agent-pi-circle-yellow-gray-white.png" />)} */}
                                        {(<Avatar className=" uppercase text-16" style={{ height: '46px', width: '46px' }} src={token.data.photoURL} />)}
                                    </Badge>
                                </>
                            }
                            subheader={
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                    <Controller
                                        name="comments"
                                        control={control}
                                        render={({ field }) => (
                                            <TextFieldStyled
                                                {...field}
                                                id="comments"
                                                className="w-full"
                                                size="medium"
                                                placeholder="Write a reply..."
                                                variant="filled"
                                                InputLabelProps={{
                                                    sx: {
                                                        color: "#48525C",
                                                        fontSize: { xl: '16px', lg: '16px', md: '14px' },
                                                        [`&.${inputLabelClasses.shrink}`]: {
                                                            fontSize: '14px',
                                                            color: !!errors.username ? "red" : "#141F2C"
                                                        }
                                                    }
                                                }}
                                                fullWidth
                                                autoFocus
                                                multiline
                                                InputProps={{
                                                    disableUnderline: true,
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            className="!h-full !max-h-max !justify-between !pr-4"
                                                        >
                                                            <AttachmentUploader module="blogs" id={attachmentTempId} attachmentData={attachmentData} setAttachmentData={setAttachmentData} />
                                                        </InputAdornment>
                                                    ),
                                                }}

                                            />
                                        )}
                                    />
                                    <IconButton
                                        onClick={handleComment}
                                        aria-label="toggle password visibility"
                                        title="Send"
                                        edge="end"
                                        className="  !p-5"
                                    >
                                        <Icon className=" !text-20" size="large">send</Icon>
                                    </IconButton>
                                </Stack>
                            }
                        />
                        {/* <FormHelperText className="pl-56" error={!!errors.comments}>{errors?.comments?.message}</FormHelperText> */}
                        <Stack direction={'row'} gap={1} className="mt-8 ml-52" flexWrap={'wrap'}>
                            {
                                attachmentData.length > 0 &&
                                attachmentData.map((item) => (
                                    <Stack direction={'row'} gap={1.5} flexWrap={'wrap'}>
                                        <div
                                            style={{ width: 80, height: 80 }}
                                            className="overflow-hidden bg-white rounded-lg border-1 border-pi-light-grey relative"
                                        >

                                            <Icon className='text-red-600 cursor-pointer absolute top-0 right-5 z-20'
                                                onClick={() => { handleDeleteAttachment(item.id) }}
                                            >delete</Icon>
                                            {
                                                (() => {
                                                    switch (item.switchType) {
                                                        case "youtube":
                                                            return <div className="image-container">
                                                                <DisabledPlayer>
                                                                    <div className=" z-10 disable"></div>
                                                                    <ReactPlayer
                                                                        light={true}
                                                                        playing={false}
                                                                        className="react-player rounded-lg overflow-hidden "
                                                                        url={item.srcUrl}
                                                                        height={80}
                                                                        width={80}
                                                                    />
                                                                </DisabledPlayer>
                                                            </div>;
                                                        case "video":
                                                            return <div className="image-container">
                                                                <video autoPlay={false} className="blog">
                                                                    <source
                                                                        type={item.fileType}
                                                                        src={item.srcUrl}
                                                                    />
                                                                </video>
                                                            </div>;
                                                        default:
                                                            return <div className="image-container2">
                                                                <img

                                                                    className="max-w-none w-full h-full object-cover"
                                                                    src={item.srcUrl}
                                                                />
                                                            </div>;
                                                    }
                                                })()
                                            }
                                        </div>
                                    </Stack>
                                ))
                            }
                        </Stack>
                    </>
                )}
            </form >
        </div >
    );
}

export default CommentsReplies;
