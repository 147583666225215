import React, { useState, useRef, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
    Avatar,
    Box,
    CardActionArea,
    Divider,
    Icon,
    IconButton,
    InputAdornment,
    Badge,
    Menu,
    MenuItem,
    Container,
    TextareaAutosize,
} from "@mui/material";
import AttachmentSvs from "app/services/AttachmentSvs";
import PhotoGridView from "app/services/PhotoGridView";
import { TextField, Button, Item, CardHeader } from "@mui/material";
import Stack from "@mui/material/Stack";
import FriendsActivityJoinMissionModal from "../modal/FriendsActivityJoinMissionModal";
import CreateContent from "./CreatedContent";
import TextLimit from "app/services/TextLimit";
import DateTimeDisplay from "app/services/DateTimeDisplay";
import { useLocation, useNavigate } from "react-router-dom";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    useParams,
} from "react-router-dom";
import { LikesIcon, PinIcon, PinnedIcon, PinnedIconBlue, SeenIcon } from "app/main/custom-icon/MissionCounterIcons";
import { FavoriteBorder } from "@mui/icons-material";
import SharerSvs from "app/services/SharerSvs";
import Commentsv2 from "app/services/comments/Commentsv2";
import OtherAgents from "app/main/apps/agent/agentprofile/content/OthersAgents";
import AgentJoiners from "./AgentJoiners";
import { updateLikes, updateDisLikes } from "../store/FriendsActivitySlice";
import { useDispatch } from "react-redux";
import AgentJwtService from 'app/services/agent/AgentJwtService';
import JoinButtonSvs from "app/services/JoinButtonSvs";
import LikeButton from "../components/LikeButton";

function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}
function stringAvatar(name) {
    var username = name.split(", "),
        firstName = username[0].split(" ")[0][0],
        lastName = username[username.length - 1].split(" ")[0][0];
    if (firstName == lastName) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${firstName}`,
        };
    } else {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },

            children: `${lastName}${firstName}`,
        };
    }
}
const TextFieldStyled = styled(TextField)(({ theme }) => ({
    "& .MuiFilledInput-root": {
        fontFamily: "Nunito, sans-serif !important",
        paddingTop: "10px !important",
        paddingBottom: "10px !important",
        backgroundColor: "unset",
        border: "1px solid #E9E9E9",
        fontSize: '14px',
        borderRadius: '18px',
        [theme.breakpoints.up("lg")]: {
            height: 'unset',
            fontSize: '14px',
        },
    },
    "& .MuiInputLabel-root": {
        fontSize: '14px',
        [theme.breakpoints.up("lg")]: {
            fontSize: '14px',
        },
    },
    "& .MuiFilledInput-root:hover": {
        backgroundColor: "#FFFBE9",
    },
    "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#ffff",
        border: "1px solid #141F2C"
    },
    "& .MuiFilledInput-root.Mui-error": {
        border: "1px solid #FF4848"
    },

}));
const JoinedContentV2 = ({ data, reloadActivities }) => {
    console.log("JoinedContentV2", data);
    const params = new URLSearchParams(location.search);
    const token = AgentJwtService.getDecodedAccessToken();
    const navigate = useNavigate();
    const [isExpandedrep, setIsExpandedrep] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);

    const toggleExpand = () => {
        setIsExpandedrep(!isExpandedrep);
    };

    const dispatch = useDispatch();

    const agentId = params.get("agentId") || token.id;

    return (
        <>
            <CardHeader
                className="p-0"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                    "& .MuiAvatar-root": { border: '1px solid #2EB7D7' },
                }}
                avatar={
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                        badgeContent={
                            <span
                            >
                                <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                            </span>
                        }
                    >
                        <Link
                            style={{ textDecoration: 'none' }
                            }
                            to={`/agentprofile?agentId=${data.pinMission?.agent?.userId}`}
                        >
                            <AttachmentSvs module={"at-6"} path={data.pinMission?.agent?.profilePicPath} data={{ name: data.pinMission?.agent?.name }} />
                        </Link>

                    </Badge>
                }
                title={
                    <Typography className=" font-extrabold text-18 flex gap-6">
                        <Link className=" line-clamp-1"
                            style={{ textDecoration: 'none' }
                            }
                            to={`/agentprofile?agentId=${data.pinMission?.agent?.userId}`}
                        >
                            {data.pinMission?.agent?.flName}
                        </Link>
                        <Typography className=" text-16 text-pi-grey mt-2">
                            Joined this mission
                        </Typography>
                    </Typography>
                }
                subheader={
                    <Typography className=" line-clamp-1 text-16 text-pi-black">
                        {<AgentJoiners item={data} />}
                    </Typography>
                }
            // action={
            //     <IconButton><Icon>more_vert</Icon></IconButton>
            // }
            />
            <Card elevation={0} className=" flex flex-row flex-wrap gap-8 bg-[#fff8df] rounded-none -mx-16 mt-8 p-12">
                <div className=" min-w-216 flex-grow ">
                    <Link style={{ textDecoration: 'none' }} to={`/public/mission-details/${data.mission.id}`}>
                        <PhotoGridView fileId={data.mission.filesId} single={true} isDisabledModal={true} />
                    </Link>
                </div>
                <div className="flex-1 flex-grow-[3]" >
                    <CardHeader
                        className="p-0"
                        sx={{
                            "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                            "& .MuiAvatar-root": { border: '1px solid #FBB633' },
                        }}
                        avatar={
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                                badgeContent={
                                    <span
                                    >
                                        <PinnedIcon style={{ fill: '#FBB633' }} size={'small'} />
                                    </span>
                                }
                            >

                                <AttachmentSvs module={"at-6"} path={data.mission?.agent.profilePicPath} data={data.mission} />
                            </Badge>
                        }
                        title={
                            <Typography className=" font-extrabold text-18 flex gap-6">
                                {data.mission?.title}
                            </Typography>
                        }
                        subheader={
                            <Typography className=" line-clamp-1 text-16">
                                by {data.mission?.agent?.flName}
                            </Typography>
                        }

                    />
                    <CardContent style={{ cursor: 'pointer' }}
                        // onClick={() => navigate('/public/mission-details/' + item.missionId)}
                        className="p-0 !pb-0">
                        <Typography
                            className="text-16  transition duration-300 line-clamp-2 pt-4 !pb-0"
                        >
                            {data.mission?.description}
                        </Typography>
                        <Stack direction="row" gap={'18px'} justifyContent={'start'} className="w-full mt-6">
                            <Typography className=" gap-1 flex items-center  text-pi-grey">
                                <div className="-mt-2">
                                    <SeenIcon />
                                </div>
                                <span className="mt-1 text-16">
                                    {data.mission.views ? data.mission.views : '0'}

                                </span>
                            </Typography>
                            <Typography className=" gap-1 flex items-center text-pi-grey">
                                <LikesIcon />
                                <span className="mt-1 text-16">
                                    {data.markersMission.likes ? data.markersMission.likes : '0'}
                                </span>
                            </Typography>
                            <Typography className=" gap-1 flex items-center text-pi-grey">
                                <div className="-mt-2"><PinIcon /> </div>
                                <span className="mt-1 text-16">
                                    {data.mission.pinned ? data.mission.pinned : '0'}
                                </span>
                            </Typography>
                            <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2">
                                <SharerSvs link={`/public/mission-details/${data.mission.id}`} title={data.mission.title} description={data.mission.description} />
                            </Button>
                            {/* <Button variant="text" className="text-16 text-pi-grey !p-0 rounded-2"><Icon className="mr-6 text-18">share</Icon><span className="sm:flex hidden">share</span></Button> */}

                            <div className=" flex-1 text-right">
                                {token.id !== agentId ? (
                                    <>
                                        <JoinButtonSvs agent_id={agentId} mission_id={data.mission.id} module={"at-4"} />
                                    </>
                                ) :

                                    <>
                                        <Button
                                            variant="contained"
                                            className="!text-[1.3rem] whitespace-nowrap bg-pi-blue-1 hover:bg-pi-blue-1 !px-8 !py-2 rounded-4 text-white"
                                            onClick={() => navigate('/public/mission-details/' + data.mission.id)}
                                        >
                                            View mission
                                        </Button>
                                    </>
                                }
                                {/* <JoinButtonSvs agent_id={agentId} mission_id={data.mission.id} module={"at-4"} /> */}
                            </div>
                        </Stack>
                    </CardContent>
                </div>
            </Card>
            <CardHeader
                className="p-0 mt-8"
                sx={{
                    "& .MuiCardHeader-avatar": { mr: "12px", ml: '0px' },
                    "& .MuiAvatar-root": { border: '1px solid #2EB7D7' },
                }}
                avatar={
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}

                        badgeContent={
                            <span
                            >
                                <PinnedIconBlue style={{ fill: '#FBB633' }} size={'small'} />
                            </span>
                        }
                    >
                        {/* <Avatar alt={"data?.name"} {...stringAvatar("Adesna, Christian")}
                            className=" uppercase text-16" style={{ height: '46px', width: '46px', textTransform: 'capitalize' }} /> */}
                        <Avatar style={{ height: '46px', width: '46px' }}></Avatar>

                    </Badge>
                }
                title={
                    <Typography
                        component="div"
                        className="font-extrabold text-18 -mb-2 text-pi-black"
                    >
                        {/* {item.title} */}
                        {data.pinMission.title}
                    </Typography>
                }
                subheader={
                    <Typography className=" line-clamp-1 text-16 text-pi-grey">
                        {/* {item.agent.flName} */}
                        {data.pinMission?.agent?.flName} joined  <DateTimeDisplay dateTime={data.pinMission?.dateCreated} />.
                    </Typography>
                }

            />
            <CardContent style={{ cursor: 'pointer' }} className="py-0 px-60 !cursor-default">

                <Box className="flex items-end justify-start mb-0 toggleReadmore relative">
                    <Typography
                        onClick={() => navigate('/public/mission-details/' + data.missionId)}
                        className="text-16  transition duration-300 line-clamp-3 mb-3 paragraph contents cursor-pointer"
                    >
                        {/* {item.description} */}
                        {data.pinMission.description}
                    </Typography>
                </Box>
                <PhotoGridView
                    fileId={data.mission.filesId} single={true} isDisabledModal={true}
                // fileId={item.filesId}
                />
                <Stack className="mt-6 -ml-5" direction={'row'} gap={3} alignItems={'center'}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <LikeButton
                            missionId={data.missionId}
                            userId={data.pinMission?.agent?.userId}
                            flName={data.pinMission?.agent?.flName}
                            initialAction={data.pinMission.action}
                            reloadActivities={reloadActivities}
                            likers={data.pinMission.likers}
                            link={'/public/mission-details/' + data.missionId}
                        />

                        {/* <IconButton
                            size="small"
                            onClick={() => handleClick(data.missionId, data.pinMission?.agent?.userId , )}
                            classN ame="-mt-2"
                        >
                            {data.pinMission.action ? (
                                <FavoriteIcon fontSize="inherit" sx={{ color: 'red' }} />
                            ) : (
                                <FavoriteBorder fontSize="inherit" sx={{ color: 'red' }} />
                            )}
                        </IconButton> */}
                    </Stack>
                    <Button
                        onClick={toggleExpand}
                        size="small" variant="text" className="h-fit text-14 !bg-none hover:!bg-none text-black !p-o rounded-md">
                        {data.pinMission.countOnCommentsById + ' ' + 'Replies'}
                        <Icon>{isExpandedrep ? 'expand_more' : 'expand_less'}</Icon>
                        {/* <Icon>expand_less</Icon> kapag naghide */}
                    </Button>
                </Stack>
                {isExpandedrep && (
                    <Commentsv2 moduleType="mission-joiners" moduleId={data.pinMission.id} reloadActivities={reloadActivities} mainMID={data.mission.id} />
                )}
                {/* <div className=" border-1 rounded-md border-grey-300 p-16 mt-8 mb-14 text-right">
                    <TextareaAutosize
                        maxRows={3}
                        minRows={1}
                        className="text-16 leading-tight w-full resize-none "
                        placeholder="Please write your thoughts here..."
                    />
                    <Button variant='contained' color="primary" className=" rounded-md">Add Reply</Button>
                </div> */}

            </CardContent>

        </>
    );
};

export default JoinedContentV2;